<template>
  <portal :to="BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME">
    <AppBadge :hide-badge="!unreadNotifications" class="ian-Badge">
      <AppButton
        v-tippy="{
          content: unreadNotifications
            ? $t('in_app_notifications.button.tooltip')
            : $t('in_app_notifications.title'),
          placement: 'top'
        }"
        :disable="disabled"
        class="ian-Trigger"
        data-testid="modal-trigger"
        height="24"
        icon="notifications"
        remove-padding
        size="sm"
        type="ghost-next"
        width="24"
        @click="openNotifications"
      />
    </AppBadge>
  </portal>

  <portal to="modal-windows">
    <div class="ian-Modal">
      <Modal
        :scrollable-wrapper="false"
        :show="opened"
        class="ian-Modal"
        data-testid="notifications-modal"
        manual-close
        size="640"
        @close="updateNotificationsStatuses"
      >
        <template #header>
          <AppTitle :level="3" class="ian-ModalTitle" disable-margin>
            {{ $t('in_app_notifications.title') }}
          </AppTitle>
        </template>
        <template #modal-body>
          <NotificationsList
            v-if="opened"
            ref="listReference"
            v-model:readedNotifications="readedNotifications"
            :items="itemsForDisplay"
            :loading="loading"
            @load-more="loadMore"
            @update-notifications-statuses="updateNotificationsStatuses(false)"
          />
        </template>
      </Modal>
    </div>
  </portal>
</template>

<script setup>
import dayjs from 'dayjs'
import { groupBy, isEmpty, uniqBy } from 'lodash'
import { onMounted, ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import NotificationsApiHandler from '@/api/notifications'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { BOTTOM_NAV_NOTIFICATIONS_PORTAL_NAME } from '@/utils/app-menu'
import { handleError } from '@/utils/error-handling'
import {
  LIMIT,
  NOTIFICATION_ATTRIBUTES,
  NOTIFICATIONS_STATUSES
} from '@/utils/in-app-notifications'
import { OPENED_NOTIFICATIONS_KEY } from '@/utils/query-parameters'
import { deleteQueryParameter, updateQueryParameter } from '@/utils/router'
import { getTimeAgo } from '@/utils/time-ago'

import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import NotificationsList from '@/components/ui/InAppNotifications/NotificationsList'
import Modal from '@/components/ui/Modal/Modal'

defineOptions({
  name: 'InAppNotifications'
})

defineProps({
  disabled: {
    type: Boolean
  }
})

const START_AT = 0
const DAYS_UNIT = 'day'
const SECOND_UNIT = 'second'

const opened = ref(false)

const items = ref([])

const unreadNotifications = ref(0)

const readedNotifications = ref([])

const api = new NotificationsApiHandler()

const router = useRouter()
const route = useRoute()

watch(opened, value => {
  if (value) {
    updateQueryParameter(router, route, OPENED_NOTIFICATIONS_KEY, true)
  } else {
    deleteQueryParameter(router, route, OPENED_NOTIFICATIONS_KEY)
  }
})

const updateNotificationsStatuses = async (closeModal = true) => {
  if (closeModal) {
    opened.value = false
  }
  if (!isEmpty(readedNotifications.value)) {
    try {
      await api.updateNotificationsStatus({
        notificationIds: readedNotifications.value
      })
    } catch (error) {
      handleError({ error })
    }

    if (closeModal) {
      readedNotifications.value = []
    }
  }

  if (closeModal) {
    await getInitialNotifications()
  }
}

const startAt = ref(START_AT)
const loading = ref(false)

const listReference = ref(null)

const getNotifications = async () => {
  loading.value = true
  let result = []

  try {
    result = await api.getNotifications({ startAt: startAt.value, limit: LIMIT })
    if (result.notifications.length === LIMIT) {
      startAt.value += LIMIT
    } else {
      listReference.value?.destroyObserver()
    }
  } catch (error) {
    handleError({ error })
    listReference.value?.destroyObserver()
  } finally {
    loading.value = false
  }
  return result
}

const loadMore = async () => {
  const { notifications } = await getNotifications()
  items.value = [...items.value, ...prepareNotifications(notifications)]
}

const getInitialNotifications = async () => {
  if (!isEmpty(items.value)) {
    items.value = []
  }
  if (startAt.value !== START_AT) {
    startAt.value = START_AT
  }
  const { notifications, unread } = await getNotifications()
  unreadNotifications.value = unread
  items.value = prepareNotifications(notifications)

  if (route.query[OPENED_NOTIFICATIONS_KEY]) {
    opened.value = true
  }
}

defineExpose({
  getInitialNotifications
})

onMounted(() => {
  getInitialNotifications()
})

const GROUPS = {
  TODAY: 'today',
  OLDER: 'older'
}

const prepareNotifications = notifications => {
  if (!isEmpty(notifications)) {
    const today = dayjs()
    return notifications
      .map(({ createDate, attributes, statusId, childNotifications, ...rest }) => {
        const isFutureDate = dayjs(createDate).isAfter(today, SECOND_UNIT)
        const category =
          dayjs(createDate).isSame(today, DAYS_UNIT) || isFutureDate ? GROUPS.TODAY : GROUPS.OLDER
        return {
          category,
          timing: isFutureDate ? null : getTimeAgo(createDate),
          isRead: statusId === NOTIFICATIONS_STATUSES.DELIVERED,
          normalizedAttributes: attributes.reduce((acc, val) => {
            const { typeId, value } = val
            return {
              ...acc,
              [typeId]: typeId === NOTIFICATION_ATTRIBUTES.ELEMENT_TYPE_ID ? Number(value) : value
            }
          }, {}),
          childNotifications: prepareNotifications(childNotifications),
          attributes,
          ...rest,
          createDate,
          statusId
        }
      })
      .sort((a, b) => {
        return dayjs(b.createDate).diff(dayjs(a.createDate))
      })
  } else {
    return []
  }
}

const itemsForDisplay = computed(() => {
  const { today, ...rest } = groupBy(uniqBy(items.value, 'id'), 'category')
  let result = { ...rest }
  // next lines for show today notifications in the top of the list if it exists
  if (today) {
    result = {
      [GROUPS.TODAY]: today,
      ...result
    }
  }
  return result
})

const openNotifications = () => {
  opened.value = true
  tracker.logEvent('notifications opened', {
    category: EVENT_CATEGORIES.NOTIFICATIONS
  })
}
</script>

<style lang="scss" scoped>
.ian-Badge {
  --size: 6px;
  // --top: 0;
  --top: 8px;
  // --right: 0;
  --right: unset;
  --left: 26px; // 26 where 8px padding + 24px button width - 6px badge width
  width: 100%;
  --color: #{$grade-low-color-next};
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.ian-Trigger {
  position: relative;
  width: 100%;
  justify-content: flex-start;
  min-height: 40px;
  padding: 8px;
  gap: 8px;
  border-radius: $border-radius-md-next;

  color: $dark-2;

  &:hover {
    color: $primary-color-next;
  }
  &.ab-Button-disabled {
    color: $grey-1-next;
  }

  // @media (any-hover: hover) {
  //   &:hover {
  //     &:deep(.svg-icon) {
  //       animation: draw 2s;
  //     }
  //   }
  // }

  // &-animated {
  //   &:deep(.svg-icon) {
  //     animation: bell 4s infinite;
  //   }
  // }
}

.ian-Modal {
  &:deep(.uim-ModalHeader) {
    padding: 40px 30px 28px;
  }
}

.ian-ModalTitle {
  color: $dark-1;
  font-weight: fw('semi-bold');
  font-family: $system-ui;
}

// @keyframes bell {
//   0% {
//     transform: rotate(0);
//   }
//   4% {
//     transform: rotate(5deg);
//   }
//   8% {
//     transform: rotate(-5deg);
//   }
//   12% {
//     transform: rotate(4deg);
//   }
//   16% {
//     transform: rotate(-4deg);
//   }
//   20% {
//     transform: rotate(2deg);
//   }
//   24% {
//     transform: rotate(-2deg);
//   }
//   28% {
//     transform: rotate(1deg);
//   }
//   32% {
//     transform: rotate(0);
//   }
// }
</style>
