<template>
  <div class="rts-Wrapper">
    <div class="rts-Wrapper_Type">
      <LockMessage v-if="showLockMessage" class="rts-Wrapper_Message">
        <div>
          <slot name="lock-message">
            {{ $t('update.keyResult.result_type_lock_message') }}
            <slot name="lock-message-description" />
          </slot>
        </div>
      </LockMessage>
      <slot v-else name="left-side" />
    </div>
    <div class="rts-Wrapper_Options">
      <slot name="right-side" />
    </div>
  </div>
</template>

<script setup>
import { SETTINGS_COUNT } from '@/components/form/key-result/result-type-settings'
import LockMessage from '@/components/form/LockMessage'

defineOptions({
  name: 'ResultTypeSettings'
})

defineProps({
  count: {
    type: Number,
    default: 1,
    validator: v => Number.isInteger(v) && Object.values(SETTINGS_COUNT).includes(v)
  },

  showLockMessage: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.rts-Wrapper {
  border-radius: $border-radius-md-next;
  box-shadow: inset 0 0 0 2px $grey-3-next;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    box-shadow: 0 6px 8px rgba($grey-2-next, 0.5), 0 2px 2px $grey-2-next;
  }
}

.rts-Wrapper_Message {
  margin: 0 auto;
  padding: 4px 0;
}

.rts-Wrapper_Type {
  &:not(&:empty) {
    padding: 16px 20px;
  }
}

.rts-Wrapper_Options {
  padding: 18px 20px 20px;

  background-color: $grey-3-next;
  border-radius: inherit;
  display: grid;
  grid-template-columns: repeat(v-bind(count), 1fr);
  gap: 20px;
}
</style>
