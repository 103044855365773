<template>
  <AppDialog
    :disable-confirm-button="!selectedObj"
    :show="show"
    :title="$t('create.objective.btn.add')"
    class="aom-Modal"
    data-auto-testid="add-objective-to-custom-dashboard-modal"
    data-testid="add-objective-to-custom-dashboard-modal"
    hide-hero
    @on-close="onClose"
    @on-confirm="onConfirm"
  >
    <FormFieldNext :label="$t('create.objective.btn.select')">
      <AppSelect
        ref="objectiveSelectRef"
        v-model="selectedObj"
        :hidden-items="hiddenItems"
        :is-highlighted="false"
        :loading="isObjectivesLoading"
        :max-height="300"
        :offset="[0, -40]"
        :options="options"
        :search-function="getObjectives"
        data-auto-testid="add-objective-select"
        data-testid="add-objective-select"
        dropdown-search
        item-value="id"
        skeleton-loader
        skeleton-loader-height="100%"
        skeleton-loader-width="100%"
        theme="no-shadow-next light"
        type="default-next"
        @update:options="options = $event"
      >
        <template #option-label="{ option }">
          <ObjectiveSelectItem
            v-if="option"
            :objective="option"
            :show-interval="true"
            :show-workspace="false"
          />
        </template>
        <template #button-content="{ option }">
          <ObjectiveSelectItem
            v-if="option"
            :objective="option"
            :show-interval="true"
            :show-workspace="false"
          />
        </template>
      </AppSelect>
    </FormFieldNext>

    <template #footer-prepend>
      <AppCheckbox
        v-if="!hideAddMoreCheckbox"
        v-model="isAddMore"
        data-auto-testid="add-more-switch"
        data-testid="add-more-switch"
        size="s"
      >
        {{ $t('dashboard.add_more') }}
      </AppCheckbox>
    </template>
  </AppDialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import CustomDashboardApiHandler from '@/api/custom-dashboard'
import { MAX_OBJECTIVES } from '@/utils/custom-dashboard-helper'
import { handleError } from '@/utils/error-handling'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AddObjectiveModal'
})

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  selectedObjectives: {
    type: Array,
    default: () => []
  }
})

const selectedObj = ref(null)
const options = ref([])

const emit = defineEmits({
  'on-close': null,
  'on-select': null
})

const onClose = () => {
  isAddMore.value = false
  selectedObj.value = null
  emit('on-close')
}

const isObjectivesLoading = ref(false)

const getObjectives = async (searchString = null) => {
  isObjectivesLoading.value = true
  let result = []

  const payload = {
    searchString,
    excludeElementIds: hiddenItems.value
  }

  try {
    const customDashboardApi = new CustomDashboardApiHandler()
    result = await customDashboardApi.getDashboardElements(payload)
  } catch (error) {
    handleError({ error })
  } finally {
    isObjectivesLoading.value = false
  }

  return result
}

const fetchObjectives = async () => {
  options.value = await getObjectives()
}

watch(
  () => props.show,
  async value => {
    if (value) {
      await fetchObjectives()
      objectiveSelectRef.value?.focus()
    }
  }
)

const onConfirm = async () => {
  emit('on-select', selectedObj.value)
  if (isAddMore.value && !hideAddMoreCheckbox.value) {
    selectedObj.value = null
    await fetchObjectives()
    objectiveSelectRef.value?.focus()
  } else {
    onClose()
  }
}
const objectiveSelectRef = ref(null)
const hiddenItems = computed(() => props.selectedObjectives.map(i => i.id))

const isAddMore = ref(false)
const hideAddMoreCheckbox = computed(() => {
  return props.selectedObjectives.length >= MAX_OBJECTIVES - 1
})
</script>

<style lang="scss" scoped>
.aom-Modal {
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
</style>
