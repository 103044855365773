<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="show"
    :title="getModalTitle"
    class="lm-LevelModal"
    data-auto-testid="level-modal"
    data-testid="level-modal"
    hide-hero
    @on-close="onLevelModalClose"
  >
    <FormFieldNext :label="$t('levels.name')">
      <div class="lm-FieldWithError">
        <AppInput
          ref="nameInput"
          v-model.trim="formModel.name"
          :is-error="isNameEmpty"
          :max-length="20"
          data-auto-testid="level-name-input"
          data-testid="level-name-input"
          size="xlg"
          style-type="primary"
          @update:model-value="validateName"
        />
        <AppFieldError v-if="isNameEmpty" :show="isNameEmpty" class="lm-FieldError">
          {{ $t('field.required') }}
        </AppFieldError>
      </div>
    </FormFieldNext>

    <AppIconCreator class="lm-IconCreator">
      <template #first-term>
        <FormFieldNext :label="$t('levels.prefix')" class="lm-Prefix">
          <div class="lm-FieldWithError">
            <AppInput
              v-model.trim="formModel.prefix"
              :is-error="showPrefixErrors"
              :max-length="2"
              data-auto-testid="level-prefix-input"
              data-testid="level-prefix-input"
              size="xlg"
              style-type="primary"
              @update:model-value="isPrefixEmpty = $event.length === 0"
            />

            <AppFieldError v-if="showPrefixErrors" :show="showPrefixErrors" class="lm-FieldError">
              <template v-if="isPrefixEmpty">
                {{ $t('field.required') }}
              </template>
              <template v-if="formErrors !== null && formErrors.prefix">
                <span v-for="error in formErrors.prefix" :key="error">
                  {{ error }}
                </span>
              </template>
            </AppFieldError>
          </div>
        </FormFieldNext>
      </template>

      <template #second-term>
        <AppPaletteColorPicker
          v-model:selected-color="formModel.color"
          :palette="CATEGORIZED_COLORS"
        />
      </template>

      <template #result>
        <OkrIcon
          v-if="showIcon"
          :objective="iconObjective"
          data-auto-testid="result-icon"
          data-testid="result-icon"
        />
      </template>
    </AppIconCreator>

    <!-- <div v-if="false" class="lm-Workspaces">
      <FormField :label="$t('levels.add_level_to_workspaces')">
        <AppSelect
          v-model="formModel.workspaceIds"
          dropdown-search
          multi
          :options="workspaces"
          :search-function="getWorkspaces"
          show-all-selected
          item-label="name"
          item-value="id"
          @update:options="workspaces = $event"
          @change="validateWorkspaces"
        />
        <AppSwitch
          v-model="formModel.allWorkspaces"
          :label="$t('levels.add_to_all_workspaces')"
          class="lm-Switch"
          @update:model-value="onAllWorkspacesChange"
        />
      </FormField>
      <AppFieldError :show="showWorkspacesErrors" class="lm-FieldError">
        <span v-show="isWorkspacesEmpty">
          {{ $t('field.required') }}
        </span>
      </AppFieldError>
    </div> -->
    <template #footer-actions>
      <AppButton
        data-auto-testid="cancel-button"
        data-testid="cancel-button"
        type="ghost-next"
        @click="close"
      >
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton
        :disabled="loading"
        :loading="loading"
        data-auto-testid="confirm-button"
        data-testid="confirm-button"
        type="primary-next"
        @click="save"
      >
        <template v-if="!isEdit">
          {{ $t('action.create') }}
        </template>
        <template v-else>
          {{ $t('action.update') }}
        </template>
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { defineComponent } from 'vue'

import LevelsApiHandler from '@/api/level'
import WorkspacesApiHandler from '@/api/workspaces'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { stringIsHex } from '@/utils/okr-levels'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'

const levelsApi = new LevelsApiHandler()
const workspacesApi = new WorkspacesApiHandler()

const CATEGORIZED_COLORS = {
  BLACK: ['#172B4D', '#42526E', '#6B778C', '#B5BBC5'],
  BLUE: ['#0B3E8C', '#0052CC', '#407DD9', '#ACCBE6'],
  GREEN: ['#2B836D', '#36B27E', '#68C59E', '#89BFBC'],
  VIOLET: ['#5F4AA1', '#A769F6', '#B792F5', '#B6B8F3'],
  RED: ['#9D3D5A', '#F64963', '#F8778A', '#F9AA9C'],
  MIX: ['#744F48', '#C56F44', '#CA9B86', '#FAC372']
}

const DEFAULT_FORM_MODEL = {
  name: '',
  prefix: '',
  color: CATEGORIZED_COLORS.VIOLET[1],
  workspaceIds: [],
  allWorkspaces: false
}

export default defineComponent({
  name: 'LevelModal',

  components: {
    AppIconCreator,
    AppPaletteColorPicker,
    FormFieldNext,
    AppModalWithConfirmation,
    AppButton,
    AppInput,
    AppFieldError,
    OkrIcon
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    modelValue: {
      type: Object,
      default: null
    }
  },

  emits: { 'update:show': null, create: null, edit: null },

  data() {
    return {
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      isNameEmpty: false,
      isWorkspacesEmpty: false,
      isPrefixEmpty: false,
      isPrefixNotLetter: false,
      loading: false,
      formErrors: null,
      workspaces: []
    }
  },

  computed: {
    CATEGORIZED_COLORS: () => CATEGORIZED_COLORS,

    isEdit() {
      return this.modelValue !== null
    },

    showIcon() {
      return this.formModel.prefix.trim().length > 0
    },

    getModalTitle() {
      return this.isEdit ? this.$t('levels.title.update') : this.$t('levels.title.create')
    },

    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    },

    // showWorkspacesErrors() {
    //   return this.isWorkspacesEmpty
    // },

    showPrefixErrors() {
      return this.isPrefixEmpty || (this.formErrors !== null && 'prefix' in this.formErrors)
    },

    iconObjective() {
      return {
        levelColor: this.formModel.color.substring(1),
        levelPrefix: this.formModel.prefix
      }
    }
  },

  watch: {
    show(bool) {
      if (!bool) {
        this.onLevelModalClose()
        return
      }

      if (this.isEdit) {
        let { color } = this.modelValue
        if (!stringIsHex(color)) {
          // color from API doesn't include '#'
          if (color.length === 3) {
            color = color.padStart(4, '#')
          } else {
            color = color.padStart(7, '#')
          }
        }

        this.formModel = {
          name: this.modelValue.name,
          prefix: this.modelValue.prefix,
          color,
          workspaceIds: this.modelValue.workspaces.map(workspace => workspace.id),
          allWorkspaces: this.modelValue.allWorkspaces
        }
      }
      this.localFormModel = { ...this.formModel }

      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.nameInput?.focus()
        }, 200)
      })
    }
  },

  async mounted() {
    this.workspaces = await this.getWorkspaces()
  },

  methods: {
    onLevelModalClose() {
      this.$emit('update:show', false)

      // wait for modal close
      setTimeout(() => {
        this.formModel = { ...DEFAULT_FORM_MODEL }
        this.localFormModel = { ...DEFAULT_FORM_MODEL }
        this.isNameEmpty = false
        this.isWorkspacesEmpty = false
        this.isPrefixEmpty = false
        this.isPrefixNotLetter = false
        this.formErrors = null
      }, 300)
    },

    validateName() {
      this.isNameEmpty = isStringEmpty(this.formModel.name)
    },

    validateWorkspaces() {
      this.isWorkspacesEmpty = this.formModel.workspaceIds === [] && this.allWorkspaces === false
    },

    async getWorkspaces(searchString) {
      let workspaces = []
      try {
        workspaces = await workspacesApi.getAllUserWorkspaces({ searchString })
      } catch (error) {
        handleError({ error })
      }
      return workspaces
    },

    async save() {
      this.validateName()
      this.isPrefixEmpty = isStringEmpty(this.formModel.prefix)
      if (this.isPrefixEmpty) {
        this.formModel.prefix = ''
      }
      if (this.isNameEmpty || this.isPrefixEmpty) {
        return
      }

      this.validateWorkspaces()
      if (this.isWorkspacesEmpty) {
        return
      }

      let isSuccessful = true
      this.loading = true

      let level = null

      const requestMethod = this.isEdit
        ? payload => levelsApi.updateLevel(payload)
        : payload => levelsApi.createLevel(payload)

      try {
        const response = await requestMethod({
          levelId: this.isEdit ? this.modelValue.id : undefined,
          ...this.formModel,
          color: this.formModel.color.substring(1), // remove '#' at the beginning
          orderValue: this.isEdit ? this.modelValue.orderValue : -1,
          workspaceIds: null // workspaces are temporary disabled
        })
        if (response.errors?.length > 0) {
          isSuccessful = false
          this.formErrors = {}
          response.errors.forEach(error => {
            if (!(error.fieldName in this.formErrors)) {
              this.formErrors[error.fieldName] = []
            }
            this.formErrors[error.fieldName].push(error.error)
          })
        } else {
          level = response.level
          if (!this.isEdit) {
            tracker.logEvent('Level created', {
              category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
              label: this.formModel.name
            })
          } else {
            tracker.logEvent('Level updated', {
              category: EVENT_CATEGORIES.LEVEL_MANAGEMENT,
              oldLabel: this.modelValue.name,
              label: this.formModel.name,
              prefix: this.formModel.prefix,
              color: this.formModel.color,
              oldPrefix: this.modelValue.prefix,
              oldColor: this.modelValue.color
            })
          }
        }
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      this.loading = false
      if (isSuccessful && !this.isEdit) {
        this.$emit('create', level)
      }
      if (isSuccessful && this.isEdit) {
        this.$emit('edit', level)
      }
    },

    // onAllWorkspacesChange(value) {
    //   if (value) {
    //     this.formModel.workspaceIds = this.workspaces.map(workspace => workspace.id)
    //   } else {
    //     this.formModel.workspaceIds = []
    //   }
    // },

    close() {
      this.$refs.appModalWithConfirmation.close()
    }
  }
})
</script>

<style lang="scss" scoped>
.lm-FieldWithError {
  position: relative;
}

.lm-FieldError {
  position: absolute;
  left: 0;
  bottom: -24px;
  white-space: nowrap;
}

.lm-Prefix {
  width: 80px;
}

.lm-IconCreator {
  margin-top: 28px;
}

// .lm-Switch {
//   margin-top: 8px;
// }
</style>

<style lang="scss">
.lm-LevelModal .o-modal-footer {
  padding-top: 8px;
}

.o-form-error.lm-FieldError {
  min-height: 20px;
}
</style>
