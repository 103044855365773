<template>
  <div class="al-Loader">
    <div v-for="item in ROWS" :key="item.id" class="al-Loader_Row">
      <div class="al-RowCell">
        <SkeletonItem :size="XS" width="150px" />
      </div>

      <div class="al-RowCell">
        <SkeletonItem :size="XS" :width="item.name" />
      </div>

      <div class="al-RowCell">
        <SkeletonItem
          v-for="line in item.description"
          :key="line.id"
          :size="XS"
          :width="line.width || '100%'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GroupsAuditLogLoader'
})
</script>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const { XS } = SKELETON_SIZES

const ROWS = [
  {
    id: 0,
    name: '112px',
    description: [
      {
        id: 0
      },
      {
        id: 1
      },
      {
        id: 2,
        width: '50%'
      }
    ]
  },
  {
    id: 1,
    name: '96px',
    description: [
      {
        id: 0
      },
      {
        id: 1,
        width: '70%'
      }
    ]
  },
  {
    id: 2,
    name: '130px',
    description: [
      {
        id: 0
      },
      {
        id: 1
      },
      {
        id: 2
      },
      {
        id: 3,
        width: '20%'
      }
    ]
  },
  {
    id: 3,
    name: '70px',
    description: [
      {
        id: 0
      }
    ]
  },
  {
    id: 4,
    name: '30px',
    description: [
      {
        id: 0
      },
      {
        id: 1
      }
    ]
  }
]
</script>

<style lang="scss" scoped>
.al-Loader_Row {
  display: flex;
  align-items: flex-start;
  min-height: 45px;
  padding: 12px $page-left-padding;
}

.al-RowCell {
  &:nth-child(1) {
    width: 220px;
  }

  &:nth-child(2) {
    width: 150px;
  }

  &:last-child {
    flex: 1 0 auto;
    display: grid;
    gap: 6px;
  }
}
</style>
