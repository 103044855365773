<template>
  <div :class="{ 'cdc-Body-readonly': isReadonly }" class="cdc-Body">
    <TaskIconForExport />
    <div class="cdc-ModalContent">
      <div ref="wrapperTitleRef" class="cdc-WrapperTitle">
        <!--    For PDF export    -->
        <div v-if="isReadonly" class="cdc-Title cdc-Title-readonly">
          {{ formModel.name }}
        </div>
        <OkrTitleFiled
          v-else
          ref="titleRef"
          :class="{ 'cdc-Title-readonly': isReadonly }"
          :is-error="isTitleEmpty"
          :max-length="150"
          :model-value="formModel.name"
          :placeholder="$t('dashboard.enter_the_main_goal')"
          :readonly="isReadonly"
          class="cdc-Title"
          data-auto-testid="name-field"
          data-testid="name-field"
          @blur="onBlur"
          @update:model-value="onUpdateTitle"
        />

        <div v-if="formModel.id && formModel.elements.length" class="cdc-GradeChart">
          <DashboardChart
            :element-id="formModel.id"
            :external-chart-data="formModel.chartData"
            :title="formModel.name"
            grade-color="primary-color-next"
            has-full-size-toggle
          />
        </div>
      </div>

      <IndicatorPanelWithProgress
        v-if="formModel.id"
        :indicator-width="getIndicatorProgress"
        background-color="rgba(var(--primary-color-rgb-next), 0.1)"
        class="cdc-IndicatorPanel"
        color="var(--primary-color-next)"
        style="--height: 32px; --border-radius: 16px; --border-radius-progress: 16px"
      >
        {{ $t('dashboard.avg_progress') }}: {{ getIndicatorProgress }}%
      </IndicatorPanelWithProgress>
    </div>
    <div
      ref="hoverTarget"
      :style="{
        '--autoplay-duration': `${TIMEOUT}ms`
      }"
    >
      <div v-if="formModel.id">
        <!--   v-show needed in export, because AnimatedNumber has replacing content and numbers don't show     -->
        <CustomDashboardVerticalView
          v-show="showVerticalView"
          :elements="formModel.elements"
          :is-readonly="isReadonly"
          @open-objective="onOpenObjectiveDetails"
        />

        <CustomDashboardSliderView
          v-show="showSliderView"
          v-model:selected-item="selectedItem"
          v-model:slideIndex="slideIndex"
          :elements="formModel.elements"
          :is-cursor-outside="isCursorOutside"
          :is-readonly="isReadonly"
          :is-run-carousel="isRunCarousel"
          :timeout="TIMEOUT"
          @show-add-objectives-modal="showAddObjectivesModal"
          @remove-objective="removeSelectedObjective"
          @select-item="selectItem"
          @open-objective="onOpenObjectiveDetails"
        />
      </div>
      <div v-if="isObjectiveExist && showSliderView" class="cdc-ModalContent">
        <CustomDashboardNestedItems
          :objective="selectedItem"
          @open-objective="onOpenObjectiveDetails"
        />
      </div>
    </div>
    <ObjectiveModal
      v-if="showObjectiveModal"
      ref="objectiveModal"
      :workspace-id="resolvedWorkspaceId"
      source="custom-dashboard"
      @close="onObjectiveModalClose"
      @closed="showObjectiveModal = false"
    />
  </div>
</template>

<script setup>
import { useMouseInElement } from '@vueuse/core'
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import LevelApiHandler from '@/api/level'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { HORIZONTAL, LAYOUT_VIEW_TYPES, VERTICAL } from '@/utils/custom-dashboard-helper'
import { handleError } from '@/utils/error-handling'
import { EXTERNAL_LINK_HANDLER_SOURCES, externalLinkHelper } from '@/utils/external-link-helper'
import { OKR_TYPE_TO_FORM_VIEW } from '@/utils/objectives'
import { getEditValueFromQuery } from '@/utils/okr-elements-forms-helpers'

import DashboardChart from '@/components/dashboard/custom-dashboard/chart/DashboardChart'
import CustomDashboardNestedItems from '@/components/dashboard/custom-dashboard/CustomDashboardNestedItems'
import CustomDashboardSliderView from '@/components/dashboard/custom-dashboard/CustomDashboardSliderView'
import CustomDashboardVerticalView from '@/components/dashboard/custom-dashboard/CustomDashboardVerticalView'
import OkrTitleFiled from '@/components/objectives/forms/OkrTitleFiled'
import ObjectiveModal from '@/components/objectives/ObjectiveModal'
import IndicatorPanelWithProgress from '@/components/ui/IndicatorPanel/IndicatorPanelWithProgress'
import TaskIconForExport from '@/components/ui/TaskIconForExport'

defineOptions({
  name: 'CustomDashboardContent'
})

const TIMEOUT = 60000

const props = defineProps({
  isReadonly: {
    type: Boolean
  },

  formModel: {
    type: Object,
    required: true
  },

  isRunCarousel: {
    type: Boolean
  },

  isExportProceed: {
    type: Boolean
  },

  isReadMode: {
    type: Boolean
  }
})

const selectedItem = ref(null)
const selectItem = (item, index) => {
  selectedItem.value = item
  slideIndex.value = index
}

const emit = defineEmits({
  'show-add-objectives-modal': null,
  'remove-selected-objective': null,
  'update:form-model': null,
  'blur:title-input': null,
  'update-data': null
})

const showAddObjectivesModal = () => {
  emit('show-add-objectives-modal')
}
const removeSelectedObjective = objective => {
  emit('remove-selected-objective', objective)
}

const onUpdateTitle = value => {
  onUpdateParams(value, 'name')
  nextTick(() => {
    validateTitle()
  })
}

const onUpdateParams = (value, key) => {
  emit('update:form-model', { ...props.formModel, [key]: value })
}

const getIndicatorProgress = computed(() => {
  return isNaN(props.formModel.progress) ? 0 : Math.round(props.formModel.progress * 10) / 10
})

const isHorizontalView = computed(() => {
  return (
    props.formModel.layoutTypeId === LAYOUT_VIEW_TYPES[HORIZONTAL] || !props.formModel.layoutTypeId
  )
})

const isVerticalView = computed(() => {
  return props.formModel.layoutTypeId === LAYOUT_VIEW_TYPES[VERTICAL]
})

const showVerticalView = computed(
  () => (props.isExportProceed ? true : isVerticalView.value && props.isReadonly) // isReadonly needed for export
)

const showSliderView = computed(
  () => (props.isExportProceed ? false : isHorizontalView.value || !props.isReadonly) // isReadonly not needed in edit mode
)

const isObjectiveExist = computed(() => {
  return props.formModel.id && selectedItem.value
})

const onBlur = () => {
  onUpdateParams(props.formModel.name.trim(), 'name')
  nextTick(() => {
    validateTitle()
    emit('blur:title-input')
  })
}
const objectiveModal = ref(null)
const showObjectiveModal = ref(false)
const resolvedWorkspaceId = ref(null)
const onObjectiveModalClose = () => {
  emit('update-data')
}

const store = useStore()

const getLevelsForFilter = async workspaceId => {
  const levelApi = new LevelApiHandler()
  try {
    const levels = await levelApi.getLevelsForFilter({
      workspaceId
    })
    await store.dispatch('objectives/setLevels', levels)
  } catch (error) {
    handleError({ error })
  }
}

const selectLastItem = () => {
  const index = props.formModel.elements?.length
  if (index) {
    selectItem(props.formModel.elements[index - 1], index - 1)
  } else {
    selectItem(null, 0)
  }
}
const selectFirstItem = () => {
  if (props.formModel.elements?.length) {
    selectItem(props.formModel.elements[0], 0)
  }
}

const route = useRoute()

onMounted(async () => {
  selectFirstItem()

  if (route && !props.isReadMode) {
    const { query } = route

    const editValue = getEditValueFromQuery({ query })

    if (editValue) {
      const flatten = elements => {
        return elements.reduce((acc, element) => {
          acc.push(element)
          if (element.childElements && Array.isArray(element.childElements)) {
            acc.push(...flatten(element.childElements))
          }
          return acc
        }, [])
      }

      const allFlattenElements = flatten(props.formModel.elements)
      const element = allFlattenElements.find(el => el.id === editValue.id)

      let childElement
      if (editValue.children) {
        childElement = allFlattenElements.find(el => el.id === editValue.children.id)
      }

      if (element) {
        onOpenObjectiveDetails(element, childElement)
      }
    }
  }
})
watch(
  () => props.formModel.elements,
  () => {
    selectLastItem()
  },
  { deep: true }
)

const appPlatform = inject('appPlatform')
const onOpenObjectiveDetails = async (objective, childElement) => {
  if (props.isReadMode) {
    externalLinkHelper({
      ...objective,
      source: EXTERNAL_LINK_HANDLER_SOURCES.CONFLUENCE_MACRO,
      appPlatform
    })
    return
  }
  showObjectiveModal.value = true
  const { id, workspaceId } = objective

  try {
    const api = new ObjectivesInfoApiHandler()
    const responses = await Promise.all([
      api.getObjectiveById({
        elementId: id,
        workspaceId
      }),
      getLevelsForFilter(workspaceId)
    ])
    const [element] = responses
    resolvedWorkspaceId.value = element.workspaceId

    await nextTick(() => {
      objectiveModal.value.openForm(OKR_TYPE_TO_FORM_VIEW[element.typeId], {
        ...element
      })
    })

    if (childElement) {
      const { id, workspaceId } = childElement
      const responses = await Promise.all([
        api.getObjectiveById({
          elementId: id,
          workspaceId
        }),
        getLevelsForFilter(workspaceId)
      ])
      const [element] = responses
      resolvedWorkspaceId.value = element.workspaceId
      await nextTick(() => {
        objectiveModal.value.openChildForm(OKR_TYPE_TO_FORM_VIEW[element.typeId], {
          ...element
        })
      })
    }
  } catch (error) {
    handleError({ error })
  }
}

const hoverTarget = ref(null)
const { isOutside: isCursorOutside } = useMouseInElement(hoverTarget)

const slideIndex = ref(0)

defineExpose({
  selectItem
})

const isTitleEmpty = ref(false)
const titleRef = ref(null)
const setFocusOnTitle = () => {
  // nextTick and setTimeout(both!) are needed to make input always focused on modal window
  // opening because modal window(o-modal) has transition of opacity with 0.2s
  nextTick(() => {
    setTimeout(() => {
      titleRef.value?.focus()
    }, 200)
  })
}
const validateTitle = () => {
  isTitleEmpty.value = !props.formModel.name
  if (isTitleEmpty.value) {
    setFocusOnTitle()
  }
}

const wrapperTitleRef = ref(null)
const titleFontSize = ref('56px')
const titleLineHeight = ref('64px')

const reduceTitleSize = () => {
  if (wrapperTitleRef.value) {
    const titleHeight = wrapperTitleRef.value.getBoundingClientRect().height
    const titleFontSizeValue = parseInt(titleFontSize.value, 10)
    const titleLineHeightValue = parseInt(titleLineHeight.value, 10)
    if (titleHeight > 165) {
      titleFontSize.value = `${titleFontSizeValue - 1}px`
      titleLineHeight.value = `${titleLineHeightValue - 1}px`
      nextTick(() => {
        reduceTitleSize()
      })
    }
  }
}

watch(
  () => [props.formModel.name, wrapperTitleRef.value],
  () => {
    if (props.isReadonly) {
      reduceTitleSize()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.cdc-WrapperTitle {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 20px 0;
  gap: 20px;
}
.cdc-Title {
  max-width: 876px;
  min-width: 530px;
  width: 100%;
  font-size: $fs-32;
  line-height: 34px;
  word-break: break-all;
  &:deep(.otf-Field_Input) {
    font-size: $fs-32;
    line-height: 34px;
  }
  &-readonly {
    font-size: v-bind(titleFontSize);
    line-height: v-bind(titleLineHeight);
    &:not(.otf-Field:focus-within):hover:after {
      display: none;
    }
    &:deep(:read-only) {
      color: $dark-1;
      background: transparent;
      &:hover {
        background: transparent;
      }
    }
  }
}
.cdc-IndicatorPanel {
  margin: 0 0 20px 0;
}

.cdc-GradeChart {
  width: 273px;
}
</style>
