<template>
  <TableSortable
    :columns="COLUMNS"
    :data="data"
    :hover-row="tableHoverRow"
    :loading="loading"
    offset-left="var(--page-left-padding)"
    offset-right="var(--page-right-padding)"
    sticky-header
    @drop="emit('drop', $event)"
    @update:data="$emit('update:data', $event)"
  >
    <template #header-cell="{ column }">
      <slot :column="column" name="header-cell">
        <template v-if="column.title"> {{ $t(column.title) }} </template>
      </slot>
    </template>

    <template #cell="{ columnKey, item, index }">
      <template v-if="columnKey === TABLE_COLUMNS_KEYS.CONTENT_AREA">
        <CustomFieldNameCell
          :data-testid="CUSTOM_FIELDS_TABLE_NAME_COLUMN_TEST_ID"
          :field="item"
          @on-name-click="emit('edit-custom-field', item)"
        />
      </template>

      <template v-if="columnKey === TABLE_COLUMNS_KEYS.FOR">
        <CustomFieldForCell :type-ids="item[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS]" />
      </template>

      <!--      <template v-if="columnKey === TABLE_COLUMNS_KEYS.REQUIRED">
        <div v-if="item.required" class="cft-RequiredCell">
          <AppIcon height="24" icon-name="info-next" width="24" />
        </div>
      </template>-->

      <template v-if="columnKey === TABLE_COLUMNS_KEYS.WORKSPACE">
        <WorkspacesListCell :limit="3" :workspaces="item[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACES]" />
      </template>

      <template v-if="columnKey === TABLE_COLUMNS_KEYS.STATUS">
        <ObjectiveStatus
          :loading="Boolean(statusCellsLoading[item.id])"
          :status="Number(item[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE])"
          type="default-next"
        >
          {{ item[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE] ? $t('filter.active') : $t('filter.inactive') }}
        </ObjectiveStatus>
      </template>

      <div v-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS" class="cft-ActionsCell">
        <DropdownMenu
          :data-testid="ROW_DROPDOWN_MENU_TEST_ID"
          :items="getDropdownMenuItems(item)"
          :offset="[0, 0]"
          preferred-position="bottom-end"
          type="default-next"
          @close="tableHoverRow = -1"
          @open="tableHoverRow = index"
          @item-click="onMenuActionsClick($event, item)"
        >
          <template #activator>
            <AppButton icon="more-next" size="sm" type="tertiary-next" />
          </template>
        </DropdownMenu>
      </div>
    </template>

    <template #loading>
      <CustomFieldsTableLoader />
    </template>

    <template #footer>
      <slot name="footer">
        <AppTableCreateButton
          v-if="showFooterButton"
          :data-testid="CUSTOM_FIELDS_TABLE_CREATE_BUTTON_TEST_ID"
          full-width
          icon-name="plus-next"
          @click="emit('create-custom-field')"
        >
          {{ $t('custom_fields.create_field') }}
        </AppTableCreateButton>
        <AppInfoMessage
          v-if="showLimitMessage"
          :data-testid="CUSTOM_FIELDS_TABLE_LIMIT_WARNING_TEST_ID"
          :type="INFO_MESSAGE_TYPES.WARNING"
          class="cft-Message"
        >
          {{
            $t('limitations.entity.limit_exceeded', {
              limit: customFieldsLimitations.limit,
              entity: $t('custom_fields.title')
            })
          }}
        </AppInfoMessage>
      </slot>
    </template>
  </TableSortable>
</template>

<script setup>
import { has, isObject } from 'lodash'
import { ref } from 'vue'

import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import {
  CUSTOM_FIELDS_TABLE_CREATE_BUTTON_TEST_ID,
  CUSTOM_FIELDS_TABLE_LIMIT_WARNING_TEST_ID,
  CUSTOM_FIELDS_TABLE_NAME_COLUMN_TEST_ID,
  ROW_DROPDOWN_MENU_TEST_ID
} from '@/utils/custom-fields/jest-helpers'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'

import CustomFieldForCell from '@/components/custom-fields/CustomFieldForCell'
import CustomFieldNameCell from '@/components/custom-fields/CustomFieldNameCell'
import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import CustomFieldsTableLoader from '@/components/ui/SkeletonLoaders/CustomFieldsTableLoader'
import TableSortable from '@/components/ui/Table/TableSortable/TableSortable'
import WorkspacesListCell from '@/components/ui/WorksapcesListCell/WorkspacesListCell'

const { customFieldsLimitations } = usePlansLimitations()

const COLUMNS = [
  {
    title: 'levels.name',
    key: TABLE_COLUMNS_KEYS.CONTENT_AREA,
    width: 'auto'
  },
  {
    title: 'custom_fields.for',
    key: TABLE_COLUMNS_KEYS.FOR,
    width: 124
  },
  {
    title: 'workspaces.workspaces',
    key: TABLE_COLUMNS_KEYS.WORKSPACE,
    width: 124 // 108 when required field will return
  },
  // {
  //   title: 'custom_fields.required',
  //   key: TABLE_COLUMNS_KEYS.REQUIRED,
  //   width: 135,
  //   textAlign: CELL_TEXT_ALIGNS.CENTER
  // },
  {
    title: 'field.status.title',
    key: TABLE_COLUMNS_KEYS.STATUS,
    width: 119
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTIONS,
    width: 24
  }
]

const { EDIT, DELETE, ACTIVATE, INACTIVATE } = ACTIONS_KEYS
const { REMOVING, EDITING } = MENU_ITEMS_GROUPS

defineOptions({
  name: 'CustomFieldsTable'
})

const emit = defineEmits({
  drop: null,
  'create-custom-field': null,
  'edit-custom-field': null,
  'update:data': null,
  'on-menu-actions-click': obj => {
    return (
      isObject(obj) &&
      has(obj, 'action') &&
      has(obj, 'item') &&
      isObject(obj.item) &&
      [
        ACTIONS_KEYS.EDIT,
        ACTIONS_KEYS.DELETE,
        ACTIONS_KEYS.ACTIVATE,
        ACTIONS_KEYS.INACTIVATE
      ].includes(obj.action)
    )
  }
})

defineProps({
  loading: {
    type: Boolean
  },

  data: {
    type: Array,
    default: () => []
  },

  showFooterButton: {
    type: Boolean
  },

  showLimitMessage: {
    type: Boolean
  },

  statusCellsLoading: {
    type: Object,
    default: () => ({})
  }
})

const tableHoverRow = ref(-1)

const getDropdownMenuItems = item => {
  const activateAction = {
    name: ACTIVATE,
    icon: 'round-checkbox-checked',
    title: 'action.activate'
  }

  const inactivateAction = {
    name: INACTIVATE,
    icon: 'round-checkbox-empty',
    title: 'action.inactivate'
  }

  const resolvedAction = item[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE] ? inactivateAction : activateAction

  return [
    {
      name: EDIT,
      icon: 'edit-next',
      title: 'action.edit',
      group: EDITING
    },
    {
      ...resolvedAction,
      group: EDITING
    },
    {
      name: DELETE,
      icon: 'delete-next',
      title: 'action.delete',
      color: 'var(--grade-low-color-next)',
      group: REMOVING
    }
  ]
}

const onMenuActionsClick = (action, item) => {
  emit('on-menu-actions-click', { action, item })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

// .cft-RequiredCell {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: $dark-2;
// }

.cft-ActionsCell {
  @extend %sortable-table-hidden-items;
}

.cft-Message {
  margin-top: 10px;
  padding-left: $page-left-padding;
}
</style>
