<template>
  <AppSelect
    v-model="localModelValue"
    :offset="[0, 0]"
    :options="OPTIONS"
    split-first-option
    theme="no-shadow-next light"
    type="modern"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import {
  BULK_FIELD_OPERATION_TYPE_IDS,
  createKeepAsIsOption,
  getOkrBulkFieldModelValueGetter,
  getOkrBulkFiledModelValueEmitPayload
} from '@/utils/okr-elements-table-bulk-actions'

import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ContributeField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()

const OPTIONS = [
  {
    ...createKeepAsIsOption()
  },
  {
    value: true,
    [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.REPLACE,
    label: t('bulk_actions.enable_contribution')
  },
  {
    value: false,
    [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.REPLACE,
    label: t('bulk_actions.disable_contribution')
  }
]

const emit = defineEmits({
  'update:model-value': null
})

const localModelValue = computed({
  get: () => {
    return getOkrBulkFieldModelValueGetter({
      modelValue: props.modelValue
    })
  },
  set: newValue => {
    const payload = getOkrBulkFiledModelValueEmitPayload({
      newValue,
      options: OPTIONS
    })

    emit('update:model-value', payload)
  }
})
</script>

<style lang="scss" scoped></style>
