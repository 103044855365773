<template>
  <!--  TipTap component, do not remove and change it    -->
  <NodeViewWrapper :as="getTagName" :colspan="node.attrs.colspan" :rowspan="node.attrs.rowspan">
    <!--  TipTap component, do not remove and change it    -->
    <NodeViewContent class="content" />
    <div class="cct-Menu">
      <AppDroplist
        v-if="isActive"
        :offset="[0, 0]"
        append-to=".df-DescriptionField"
        placement="bottom"
        theme="no-shadow-next light"
        trigger="click"
      >
        <template #button>
          <AppButton class="cct-Menu-Button" size="sm" type="simple">
            <!--      Do not remove this svg tag and pointer-events: none; In other cases have a bug with closing droplist      -->
            <svg
              class="cct-Button-Icon"
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M7.80198 10.1886L4.74005 7.12663C4.48232 6.8689 4.48232 6.45103 4.74005 6.1933C4.99779 5.93557 5.41565 5.93557 5.67339 6.1933L8.27339 8.7933L10.8734 6.1933C11.1311 5.93557 11.549 5.93557 11.8067 6.1933C12.0645 6.45103 12.0645 6.8689 11.8067 7.12663L8.74479 10.1886C8.48444 10.4489 8.06233 10.4489 7.80198 10.1886Z"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </AppButton>
        </template>
        <CustomTableListActions
          :cell-actions="cellActions"
          :editor="editor"
          @exec-action="execAction"
        />
      </AppDroplist>
    </div>
  </NodeViewWrapper>
</template>

<script setup>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { onMounted, computed, getCurrentInstance } from 'vue'

import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { DATA_OBOARD_UID, uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import CustomTableListActions from '@/components/objectives/forms/CustomTableListActions'
import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'CustomTableCellTiptap'
})

const instance = getCurrentInstance()

const isActive = computed(
  () =>
    // instance.parent.parent.data.active нужен для того чтобы не было колизий с активными элементами
    // вощникает баг с функцией isActive когда сам редакктор не активный, но функция isActive возвращает true
    // Все аттрибуты статические и не имеют реактивности
    // Поэтому приходится обращаться к родительскому компоненту DescriptionField и проверять активность редактора
    // Обращения через двух parent происходит из-за того что типтап оборачивает этот компонент в свой и вложеность вырастает
    instance.parent.parent.data.active &&
    // Для того чтобы проверить активная ли текущая ячейка необходимо зарегистрировать кастомынй аттрибут data-oboard-uid на верхнем уровне в файле DescriptionField
    // На маунтекаждой ячейки вызываем типтаповскую функцию которая создает уникальный id
    // У типтапа есть команда которая проверяет активный плагин, в данном случае это ячейка таблицы (tableCell, tableHeaderCell)
    // TableHeader - это ячейка которая является заголовком таблицы (заголовки у нас могут быть по горизонтали и по вертикали.
    // Это зарезервированные имена типтапа, их нельзя изменять
    // функция isActive может принимать в себя аттрибуты для проверки какой именно элемент из плагина активен
    // В данном случае мы проверяем активна ли ячейка таблицы, и если да, то проверяем совпадает ли id ячейки с id ячейки которая зарегистрирована в data-oboard-uid
    // props.extension.config.name - это имя плагина (tableCell, tableHeaderCell)
    props.editor.commandManager.editor.isActive(props.extension.config.name, {
      [DATA_OBOARD_UID]: props.node.attrs[DATA_OBOARD_UID]
    })
)
const getTagName = computed(() => (props.extension.config.name === 'tableHeader' ? 'th' : 'td'))

const { TABLE_ACTIONS, HEADER_ACTIONS, REMOVING } = MENU_ITEMS_GROUPS

const cellActions = computed(() => [
  {
    title: 'add_column_after',
    action: 'addColumnAfter',
    icon: 'insert-column-right',
    type: 'default',
    group: TABLE_ACTIONS
  },
  {
    title: 'add_column_before',
    action: 'addColumnBefore',
    icon: 'insert-column-left',
    type: 'default',
    group: TABLE_ACTIONS
  },
  {
    title: 'add_row_before',
    action: 'addRowBefore',
    icon: 'insert-row-top',
    type: 'default',
    group: TABLE_ACTIONS
  },
  {
    title: 'add_row_after',
    action: 'addRowAfter',
    icon: 'insert-row-bottom',
    type: 'default',
    group: TABLE_ACTIONS
  },
  {
    title: 'toggle_header_cell',
    action: 'toggleHeaderCell',
    icon: 'layout-grid-fill',
    type: 'default',
    group: HEADER_ACTIONS
  },
  {
    title: 'merge_or_split',
    action: 'mergeOrSplit',
    icon: 'split-cells-horizontal',
    type: 'default',
    group: HEADER_ACTIONS
  },
  {
    title: 'delete_row',
    action: 'deleteRow',
    icon: 'delete-row',
    color: 'var(--grade-low-color-next)',
    group: REMOVING
  },
  {
    title: 'delete_column',
    action: 'deleteColumn',
    icon: 'delete-column',
    color: 'var(--grade-low-color-next)',
    group: REMOVING
  }
])
const props = defineProps(nodeViewProps)

const execAction = action => {
  props.editor.chain().focus()[action]().run()
}
onMounted(() => {
  const id = props.node.attrs[DATA_OBOARD_UID] || uid()
  props.updateAttributes({
    [DATA_OBOARD_UID]: id
  })
})
</script>

<style lang="scss" scoped>
.content {
  // Tiptap class
  padding: 0 15px 0 0;
}
.cct-Menu {
  position: absolute;
  right: 5px;
  top: 2px;

  &:deep(.cct-Menu-Button) {
    background-color: $grey-2-next;
    min-width: 24px;
    max-width: 24px;

    .cct-Button-Icon {
      pointer-events: none;
      transition: transform $transition-fast ease-in-out;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: $grey-2;
    }

    &:active {
      color: $dark-grey;
    }
  }

  &:deep(.o-droplist--expanded) {
    .cct-Menu-Button {
      background-color: $dark-grey;
      color: $white;

      .cct-Button-Icon {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: $dark-grey;
        color: $white;
      }
    }
  }
}
</style>
