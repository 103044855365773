<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="isShow"
    :title="$t('intervals.new_interval')"
    data-auto-testid="create-interval-modal"
    hide-hero
    size="sm-next"
    @on-close="onModalClose"
  >
    <div class="icm-Body">
      <FormFieldNext :label="$t('intervals.name')" class="icm-NameField">
        <AppInput
          ref="nameInput"
          v-model.trim="formModel.title"
          :is-error="isTitleEmpty || isNameReserved"
          max-length="20"
          size="xlg"
          style-type="primary"
          @focus="
            () => {
              isTitleEmpty = false
              isNameReserved = false
            }
          "
        />

        <AppFieldError :show="isTitleEmpty || isNameReserved" class="icm-NameFieldError">
          <template v-if="isTitleEmpty">
            {{ $t('field.required') }}
          </template>
          <template v-else-if="isNameReserved">
            {{ $t('field.name_reserved') }}
          </template>
        </AppFieldError>
      </FormFieldNext>

      <div class="icm-Dates">
        <FormFieldNext :label="$t('interval.label.time_period')">
          <AppSelect
            v-model="formModel.period"
            :offset="[0, 0]"
            :options="cyclesOptions"
            theme="no-shadow-next light"
            type="default-next"
            @change="onCycleChange"
          />
        </FormFieldNext>

        <FormFieldNext :label="$t('filter.start_date')">
          <DatePicker
            v-model="formModel.start"
            :clearable="false"
            :editable="false"
            :format="DATE_FORMAT"
            :lang="getDatepickerOptions"
            class="icm-Datepicker"
            popup-class="dp-Datepicker"
            @change="onSetCustomCycle"
          >
            <template #icon-calendar>
              <AppIcon height="24" icon-name="calendar-next" width="24" />
            </template>
          </DatePicker>
        </FormFieldNext>

        <FormFieldNext :label="$t('interval.label.end_date')">
          <DatePicker
            v-model="formModel.end"
            :clearable="false"
            :disabled-date="notBeforeStart"
            :editable="false"
            :format="DATE_FORMAT"
            :lang="getDatepickerOptions"
            class="icm-Datepicker"
            popup-class="dp-Datepicker"
            @change="onSetCustomCycle"
          >
            <template #icon-calendar>
              <AppIcon height="24" icon-name="calendar-next" width="24" />
            </template>
          </DatePicker>
        </FormFieldNext>
      </div>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton :loading="loading" type="primary-next" @click="save">
        {{ $t('action.create') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { defineComponent } from 'vue'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/index.css'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import { localDateToUtc, datepickerLang, getValidDueOrStartDate } from '@/utils/date'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { isIntervalNameReserved, predefinedCycles } from '@/utils/interval'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

const DEFAULT_FORM_MODEL = {
  title: '',
  period: '',
  start: null,
  end: null
}

const CUSTOM_CYCLE_VALUE = 'custom'

const DATE_FORMAT = 'D MMM YYYY'

export default defineComponent({
  name: 'IntervalCreateModal',
  components: {
    FormFieldNext,
    DatePicker,
    AppModalWithConfirmation,
    AppButton,
    AppInput,
    AppFieldError,
    AppSelect,
    AppIcon
  },

  props: {
    isShow: {
      type: Boolean,
      required: true
    },

    workspaceId: {
      type: [String, Number],
      required: true
    }
  },

  emits: { 'on-created': null, 'on-close': null },

  data() {
    return {
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      isTitleEmpty: false,
      isNameReserved: false,
      loading: false
    }
  },

  computed: {
    DATE_FORMAT: () => DATE_FORMAT,

    isReservedNames() {
      return isIntervalNameReserved(this.formModel.title.trim())
    },

    getDatepickerOptions() {
      return datepickerLang()
    },

    cyclesOptions() {
      const custom = {
        value: CUSTOM_CYCLE_VALUE,
        label: this.$t('date.custom_date'),
        start: null,
        end: null
      }
      return [...predefinedCycles(true, 5), custom]
    },

    areDataChanged() {
      return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    }
  },

  watch: {
    isShow(bool) {
      if (!bool) {
        this.localFormModel = { ...DEFAULT_FORM_MODEL }
        return
      }
      this.setDefaultPeriod()
      this.localFormModel = { ...this.formModel }
      this.$nextTick(() => {
        this.$refs.nameInput.focus()
      })
    },

    'formModel.start': {
      handler(start) {
        const { end } = this.formModel
        if (start >= end) {
          const newEnd = new Date(start).setMonth(start.getMonth() + 3)
          this.formModel.end = new Date(newEnd)
        }
      }
    }
  },

  methods: {
    notBeforeStart(date) {
      if (this.formModel.start) {
        return date.getTime() < this.formModel.start.getTime()
      }
      return false
    },

    async save() {
      if (isStringEmpty(this.formModel.title)) {
        this.isTitleEmpty = true
        return
      }

      if (this.isReservedNames) {
        this.isNameReserved = true
        return
      }

      let isSuccessful = true

      this.loading = true
      const payload = {
        name: this.formModel.title,
        startDate: localDateToUtc(
          getValidDueOrStartDate(this.formModel.start, OKR_DATES_SELECT_DATE_PROPS.START_DATE)
        ).getTime(),

        endDate: localDateToUtc(getValidDueOrStartDate(this.formModel.end)).getTime(),
        open: 1,
        workspaceId: this.workspaceId
      }

      const intervalsInfoApi = new IntervalsInfoApiHandler()

      try {
        const createdCycle = await intervalsInfoApi.createInterval(payload)
        this.$emit('on-created', createdCycle)
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      setTimeout(() => {
        this.loading = false
      }, 300)

      if (isSuccessful) {
        this.onModalClose()

        setTimeout(() => {
          this.formModel.title = ''
          this.formModel.start = null
          this.formModel.end = null

          this.setDefaultPeriod()
        }, 300)
      }
    },

    onModalClose() {
      this.formModel.title = DEFAULT_FORM_MODEL.title
      this.localFormModel.title = DEFAULT_FORM_MODEL.title
      this.isTitleEmpty = false
      this.isNameReserved = false
      this.$emit('on-close')
    },

    setDefaultPeriod() {
      this.formModel.period = this.cyclesOptions[0].value
      this.onCycleChange()
    },

    onSetCustomCycle() {
      if (this.formModel.period !== CUSTOM_CYCLE_VALUE) {
        this.formModel.period = CUSTOM_CYCLE_VALUE
      }
    },

    onCycleChange() {
      const { start, end } = this.cyclesOptions.find(c => c.value === this.formModel.period)
      this.formModel.start = start ? new Date(start) : this.formModel.start
      this.formModel.end = end ? new Date(end) : this.formModel.end
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/interval-modal';

.icm-Datepicker {
  @extend %interval-datepicker-styles;
}

.icm-Body {
  @extend %interval-modal-body-styles;
}

.icm-NameField {
  @extend %interval-modal-name-field-styles;
}

.icm-NameFieldError {
  @extend %interval-modal-name-error-styles;
}

.icm-Dates {
  @extend %interval-modal-dates-wrapper-styles;
  grid-template-columns: repeat(3, 1fr);
}
</style>
