<template>
  <div class="wss-Wrapper">
    <FormFieldNext :label="t('workspace.label.name')">
      <AppInput
        v-model.trim="modelValue[WORKSPACE_ENTITY_KEYS.NAME]"
        :is-error="showNameErrors"
        class="wss-Wrapper_Name"
        max-length="30"
        size="xlg"
        @blur="onNameInputFocusOut"
      />

      <AppFieldError v-if="showNameErrors" :show="showNameErrors">
        <span v-show="isNameEmpty || nameLengthNotValid">
          {{ $t(isNameEmpty ? 'field.required' : 'field.workspace_name') }}
        </span>
        <template v-if="!isEmpty(nameErrors)">
          <span v-for="error in nameErrors" :key="error">
            {{ error }}
          </span>
        </template>
      </AppFieldError>
    </FormFieldNext>

    <FormFieldNext :label="$t('workspace.label.description')">
      <AppTextarea
        v-model="modelValue[WORKSPACE_ENTITY_KEYS.DESCRIPTION]"
        :max-length="DESCRIPTION_MAX_LENGTH"
        :placeholder="$t('workspace.label.add_description')"
        :rows="1"
        style="--input-padding: 8px"
        @blur="onDescriptionInputFocusOut"
      />
    </FormFieldNext>

    <div>
      <AppIconCreator class="wss-IconCreator" hide-result>
        <template #first-term>
          <FormFieldNext :label="t('workspace.label.key')">
            <AppInput
              v-model.trim.uppercase="localKey"
              :is-error="showKeyErrors"
              class="wss-IconCreator_Key"
              max-length="3"
              size="xlg"
              @blur="onKeyInputFocusOut"
            >
              <template #append-after>
                <TooltipButtonInfo
                  class="wss-TooltipButton"
                  content="workspaces.key.tooltip"
                  placement="top"
                />
              </template>
            </AppInput>
          </FormFieldNext>
        </template>

        <template #second-term>
          <AppPaletteColorPicker
            :palette="WORKSPACE_CATEGORIZED_COLORS"
            :selected-color="modelValue[WORKSPACE_ENTITY_KEYS.COLOR]"
            @update:selected-color="updateWorkspace(WS_COLOR_FIELD_NAME, $event)"
          />
        </template>
      </AppIconCreator>
      <AppFieldError v-if="showKeyErrors" :show="showKeyErrors">
        <span v-show="keyLengthIsNotValid">
          {{ $t('field.workspace_key_length') }}
        </span>
        <template v-if="!isEmpty(keyErrors)">
          <span v-for="error in keyErrors" :key="error">
            {{ error }}
          </span>
        </template>
      </AppFieldError>
    </div>

    <FormFieldNext :label="t('workspace.timezone')" class="wss-Wrapper_Timezone">
      <AppSelect
        :model-value="modelValue[WORKSPACE_ENTITY_KEYS.TIMEZONE]"
        :offset="[0, 0]"
        :options="timezonesOptions"
        :search-function="value => localSearch({ value, options: timezonesOptions })"
        dropdown-search
        item-label="label"
        item-value="value"
        position="bottom-end"
        show-all-selected
        theme="no-shadow-next light"
        type="default-next"
        @update:model-value="onTimezoneUpdate"
      />
    </FormFieldNext>

    <AppVisibilitySwitch
      :model-value="modelValue.public"
      name="ws-visibility"
      @update:model-value="updateWorkspace(WS_VISIBILITY_FIELD_NAME, $event)"
    />
  </div>
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual, isUndefined } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WorkspacesApiHandler from '@/api/workspaces'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { WORKSPACE_CATEGORIZED_COLORS } from '@/utils/components-configurations/workspace-configuration-modal'
import { WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy, isStringEmpty } from '@/utils/general'
import { DESCRIPTION_MAX_LENGTH } from '@/utils/global-groups'
import { showNotify } from '@/utils/notify'
import { localSearch } from '@/utils/objectives'
import { timezonesOptions } from '@/utils/timezones'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import TooltipButtonInfo from '@/components/TooltipButtonInfo'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTextarea from '@/components/ui/AppTextarea/AppTextarea'
import AppVisibilitySwitch from '@/components/workspaces/AppVisibilitySwitch'

defineOptions({
  name: 'WorkspaceSettings'
})

const props = defineProps({
  workspaceData: {
    type: Object,
    required: true
  },

  wsKey: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  'update:ws-key': null,
  'update:current-workspace-name': null,
  'update:areDataChanged': null,
  'saving-started': null,
  'saving-finished': null
})

const modelValue = ref({})

const setLocalModelValue = () => {
  modelValue.value = cloneDeep(props.workspaceData)
}

watch(
  () => props.workspaceData,
  () => {
    setLocalModelValue()
  },
  { deep: true, immediate: true }
)

const keyErrors = ref([])
const keyLengthIsNotValid = ref(false)

const localKey = computed({
  get: () => props.wsKey,
  set: value => {
    keyLengthIsNotValid.value = false
    keyErrors.value = []
    emit('update:ws-key', value)
  }
})

const nameErrors = ref([])

const showKeyErrors = computed(() => {
  return !isEmpty(keyErrors.value) || keyLengthIsNotValid.value
})

const showNameErrors = computed(() => {
  return !isEmpty(nameErrors.value) || isNameEmpty.value || nameLengthNotValid.value
})

const store = useStore()

const WS_KEY_FIELD_NAME = WORKSPACE_ENTITY_KEYS.KEY
const WS_NAME_FIELD_NAME = WORKSPACE_ENTITY_KEYS.NAME
const WS_DESCRIPTION_FIELD_NAME = WORKSPACE_ENTITY_KEYS.DESCRIPTION
const WS_VISIBILITY_FIELD_NAME = 'isPublic'
const WS_TIMEZONE_FIELD_NAME = WORKSPACE_ENTITY_KEYS.TIMEZONE
const WS_COLOR_FIELD_NAME = WORKSPACE_ENTITY_KEYS.COLOR

const savingStatuses = ref({
  [WS_KEY_FIELD_NAME]: false,
  [WS_NAME_FIELD_NAME]: false,
  [WS_DESCRIPTION_FIELD_NAME]: false,
  [WS_VISIBILITY_FIELD_NAME]: false,
  [WS_TIMEZONE_FIELD_NAME]: false,
  [WS_COLOR_FIELD_NAME]: false
})

const isSavingProceed = computed(() => {
  return checkSomeValueIsTruthy(...Object.values(savingStatuses.value))
})

const localAreDataChanged = computed(() => {
  const { name, public: isPublic, key, timezone, color } = props.workspaceData
  const workspaceData = {
    name,
    [WORKSPACE_ENTITY_KEYS.PUBLIC]: isPublic,
    key,
    timezone,
    color
  }
  const localData = {
    [WORKSPACE_ENTITY_KEYS.NAME]: modelValue.value[WORKSPACE_ENTITY_KEYS.NAME],
    [WORKSPACE_ENTITY_KEYS.PUBLIC]: modelValue.value[WORKSPACE_ENTITY_KEYS.PUBLIC],
    [WORKSPACE_ENTITY_KEYS.KEY]: localKey.value,
    [WORKSPACE_ENTITY_KEYS.TIMEZONE]: modelValue.value[WORKSPACE_ENTITY_KEYS.TIMEZONE],
    [WORKSPACE_ENTITY_KEYS.COLOR]: modelValue.value[WORKSPACE_ENTITY_KEYS.COLOR]
  }

  return !isEqual(workspaceData, localData)
})

watch(
  localAreDataChanged,
  value => {
    emit('update:areDataChanged', value)
  },
  { immediate: true }
)

watch(isSavingProceed, value => {
  if (value) {
    emit('saving-started')
  } else {
    emit('saving-finished')
  }
})

const TRANSLATION_KEYS = {
  [WS_KEY_FIELD_NAME]: 'workspace.label.key',
  [WS_NAME_FIELD_NAME]: 'workspace.label.name',
  [WS_VISIBILITY_FIELD_NAME]: 'workspaces.visibility',
  [WS_TIMEZONE_FIELD_NAME]: 'workspace.timezone',
  [WS_COLOR_FIELD_NAME]: 'levels.color',
  [WS_DESCRIPTION_FIELD_NAME]: 'workspace.label.description'
}

const { t } = useI18n()

const updateWorkspace = async (fieldName, value) => {
  if (!fieldName || isUndefined(value)) {
    throw new Error('Field name and Value is required')
  }

  savingStatuses.value[fieldName] = true
  try {
    const workspacesApi = new WorkspacesApiHandler()
    const { id, name, public: isPublic, key, timezone, color } = props.workspaceData
    const payload = {
      id,
      name,
      isPublic,
      key,
      timezone,
      color,
      [fieldName]: value
    }

    const response = await workspacesApi.updateWorkspace(payload)

    if (!isEmpty(response.errors)) {
      response.errors.forEach(errorItem => {
        const { fieldName, error } = errorItem
        if (fieldName === WS_KEY_FIELD_NAME) {
          keyErrors.value.push(error)
        }
        if (fieldName === WS_NAME_FIELD_NAME) {
          nameErrors.value.push(error)
        }
      })
    } else {
      await store.dispatch('workspaces/updateWorkspace', response.workspace)
      showNotify({
        title: t('workspaces.workspace_field_updated', {
          fieldName: t(TRANSLATION_KEYS[fieldName])
        })
      })

      const trackingData = {
        category: EVENT_CATEGORIES.WORKSPACE_MANAGEMENT,
        subCategory: t(TRANSLATION_KEYS[fieldName], 1, { locale: 'en' }),
        value
      }

      if (fieldName === WS_TIMEZONE_FIELD_NAME) {
        const newTimezone = timezonesOptions.find(option => option.value === value)
        trackingData.value = newTimezone?.label || value
      }

      tracker.logEvent('Updated workspace', trackingData)
    }
  } catch (error) {
    handleError({ error })
  } finally {
    savingStatuses.value[fieldName] = false
  }
}

const onKeyInputFocusOut = () => {
  keyErrors.value = []
  keyLengthIsNotValid.value = !localKey.value.length || localKey.value.length > 3
  if (
    !keyLengthIsNotValid.value &&
    localKey.value !== props.workspaceData[WORKSPACE_ENTITY_KEYS.KEY]
  ) {
    updateWorkspace(WS_KEY_FIELD_NAME, localKey.value)
  }
}

const isNameEmpty = ref(false)
const nameLengthNotValid = ref(false)
const onNameInputFocusOut = () => {
  nameErrors.value = []

  isNameEmpty.value = isStringEmpty(modelValue.value[WORKSPACE_ENTITY_KEYS.NAME])
  nameLengthNotValid.value = modelValue.value[WORKSPACE_ENTITY_KEYS.NAME].length < 2
  if (
    !nameLengthNotValid.value &&
    !isNameEmpty.value &&
    modelValue.value.name !== props.workspaceData[WORKSPACE_ENTITY_KEYS.NAME]
  ) {
    updateWorkspace(WS_NAME_FIELD_NAME, modelValue.value[WORKSPACE_ENTITY_KEYS.NAME])
  }
}
const onDescriptionInputFocusOut = () => {
  if (modelValue.value.description !== props.workspaceData[WORKSPACE_ENTITY_KEYS.DESCRIPTION]) {
    updateWorkspace(WS_DESCRIPTION_FIELD_NAME, modelValue.value[WORKSPACE_ENTITY_KEYS.DESCRIPTION])
  }
}

const onTimezoneUpdate = value => {
  modelValue.value[WORKSPACE_ENTITY_KEYS.TIMEZONE] = value
  updateWorkspace(WS_TIMEZONE_FIELD_NAME, value)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.wss-Wrapper {
  padding: 28px $page-right-padding-next 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: calc(400px + #{$page-right-padding-next} + #{$page-left-padding-next});
}

.wss-IconCreator_Key {
  width: 180px;
}

.wss-Wrapper_Name {
  flex: 1 0;

  &:deep(.o-input-field) {
    width: 100%;
  }
}

.wss-TooltipButton {
  margin-right: 6px;
}

.wss-IconCreator {
  --select-width: 180px;
  --symbol-width: 40px;
}
</style>
