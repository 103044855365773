<template>
  <div
    v-if="isShow"
    :class="{ 'as-Snackbar-inline': inline, 'as-Snackbar-sticky': sticky }"
    class="as-Snackbar"
  >
    <AppCheckbox
      :model-value="isShow"
      class="as-Snackbar_Close"
      data-testid="snackbar-checkbox"
      size="s"
      @update:model-value="close"
    >
      <span class="as-Snackbar_CheckboxLabel"> {{ count }} {{ $t('selected') }} </span>
    </AppCheckbox>

    <AppDivider v-if="!isEmpty(actions)" :no-margin="!inline" class="as-Divider" vertical />

    <AppButton
      v-for="action in actions"
      :key="`${action.name}-next`"
      v-tippy="{
        content: action.tooltipContent,
        placement: 'top',
        theme: 'translucent-next text-center'
      }"
      :class="{
        'as-Snackbar_Button-default': !Boolean(action.buttonType)
      }"
      :data-testid="`snackbar-action-${action.name}`"
      :disable="action.disable"
      :height="24"
      :icon="action.icon ? action.icon : ''"
      :loading="action.loading"
      :loading-circle-color="action.color || 'var(--white)'"
      :remove-padding="action.removePadding || false"
      :style="{ '--text-color': action.color }"
      :type="action.buttonType || buttonType"
      :width="24"
      class="as-Snackbar_Button"
      @click="onSnackbarActionClick(action.name)"
    >
      {{ $t(action.title) }}
    </AppButton>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import { isEscape } from '@/utils/key-codes'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppDivider from '@/components/ui/AppDivider'

export default defineComponent({
  name: 'AppSnackbar',

  components: {
    AppDivider,
    AppCheckbox,
    AppButton
  },

  props: {
    count: {
      type: Number,
      required: true
    },

    actions: {
      type: Array,
      default: () => []
    },

    inline: {
      type: Boolean
    },

    sticky: {
      type: Boolean
    }
  },

  emits: { close: null, 'action-click': null },

  computed: {
    isShow() {
      return this.count > 0
    },

    buttonType() {
      return this.inline ? 'ghost-next' : 'none'
    }
  },

  watch: {
    isShow: {
      handler(value) {
        if (value) {
          document.body.addEventListener('keyup', this.onKeyUp)
        } else {
          document.body.removeEventListener('keyup', this.onKeyUp)
        }
      }
    }
  },

  methods: {
    isEmpty,
    onKeyUp(event) {
      if (isEscape(event.keyCode)) {
        // esc
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        this.close()
      }
    },

    close() {
      this.$emit('close')
    },

    onSnackbarActionClick(name) {
      this.$emit('action-click', name)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.as-Snackbar {
  display: flex;
  align-items: center;

  &:not(&-inline) {
    position: fixed;
    z-index: var(--snackbar-z-index, 70);
    left: var(--snackbar-left, 50%);
    bottom: 32px;
    transform: var(--snackbar-transform, translateX(-50%));
    // box-shadow: 0 12px 24px rgba($grey-semi-medium, 0.6);

    box-shadow: 0 3px 5px rgba($regular-text, 0.2), 0 0 1px rgba($regular-text, 0.31),
      0 0 60px 0 rgba($black, 0.07);

    background: $dark-2;
    color: $white;
    // border-radius: 32px;
    border-radius: $border-radius-md-next;
    // padding: 12px 16px;
    padding: 24px 20px;
    gap: 20px;
  }

  &-inline {
    background: $white;
    padding: 16px var(--padding-right, 0px) 16px var(--padding-left, 0px);
    gap: 8px;
    &:before {
      content: '';
      position: absolute;
      height: 2px;
      top: 0;
      left: var(--padding-left, 0);
      background: $grey-2-next;
      width: calc(100% - var(--padding-left, 0px) - var(--padding-right, 0px));
      pointer-events: none;
    }
  }

  /* eslint-disable-next-line vue-scoped-css/no-unused-selector */
  &-sticky#{&}-inline {
    position: sticky;
    bottom: 0;
    z-index: var(--z-index, 1);
  }
}

.as-Divider {
  .as-Snackbar-inline & {
    margin-inline: 8px;
  }

  &:not(.as-Snackbar-inline &) {
    --divider-color: #{$dark-3};
  }
}

.as-Snackbar_CheckboxLabel {
  font-weight: fw('bold');
  font-size: $fs-12;
}

.as-Snackbar_Close {
  &:not(.as-Snackbar-inline &) {
    --checkmark-color: #{$white};
    --label-color: #{$grey-2-next};
    --checkmark-image: url(~@/assets/images/icon-checkbox-dark.svg);
  }
}

// .as-Snackbar_Close {
//   border-radius: 50%;
//   width: 24px;
//   height: 24px;
//
//   @media (any-hover: hover) {
//     &:hover {
//       background-color: rgba($white, 0.08);
//     }
//   }
// }

// .as-Snackbar_Text {
//   margin-right: 16px;
// }

.as-Snackbar_Button {
  &:not(.as-Snackbar-inline &) {
    color: var(--text-color, #{$dark-2});
  }

  .as-Snackbar-inline & {
    color: var(--text-color, #{$dark-1});
  }
}

.as-Snackbar_Button-default {
  &:not(.as-Snackbar-inline &) {
    font-size: $fs-14;
    font-weight: fw('medium');
    white-space: nowrap;
    background-color: $white;
    transition: background-color 0.1s;

    @include hoverState($grey-3-next, 0%);

    @include activeState($grey-2-next, 0%);

    // &:deep(.svg-icon) {
    //   transform: translateX(-4px);
    // }
  }

  .as-Snackbar-inline & {
    --button-low-padding: 4px;
    --button-high-padding: 8px;
    font-size: $fs-12;
    line-height: 16px;
    background-color: $grey-3-next;

    @include hoverState($grey-2-next, 2%);

    @include activeState($grey-3-next, 10%);
  }
}
</style>
