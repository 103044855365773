<template>
  <div class="wnc-Cell">
    <WorkspaceIcon :option="workspace" :with-lock-icon="withLockIcon" />

    <span
      v-tippy="{
        ...TOOLTIP_CONFIGURATION,
        content: workspace.name,
        placement: 'top-start'
      }"
      :class="{ 'wnc-Name-link': nameAsLink }"
      class="wnc-Name"
      @click="onNameClick"
    >
      <slot>
        {{ workspace.name }}
      </slot>
    </span>

    <AppButton
      class="wnc-Link"
      height="24"
      icon="shortcut-next"
      remove-padding
      size="sm"
      type="ghost-next"
      width="24"
      @click="goToWorkspace"
    />
  </div>
</template>

<script setup>
import { inject } from 'vue'

import { EXTERNAL_LINK_HANDLER_SOURCES, externalLinkHelper } from '@/utils/external-link-helper'
import { TOOLTIP_CONFIGURATION } from '@/utils/tippy-config'

import AppButton from '@/components/ui/AppButton/AppButton'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

defineOptions({
  name: 'WorkspaceNameCell'
})

const props = defineProps({
  workspace: {
    required: true,
    type: Object
  },

  withLockIcon: {
    type: Boolean
  },

  nameAsLink: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['on-name-click'])

const onNameClick = () => {
  if (props.nameAsLink) {
    emit('on-name-click')
  }
}

const appPlatform = inject('appPlatform')
const goToWorkspace = () => {
  externalLinkHelper({
    workspaceId: props.workspace.id,
    source: EXTERNAL_LINK_HANDLER_SOURCES.WORKSPACE_NAME_CELL,
    withEmptyFilters: true,
    appPlatform
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.wnc-Cell {
  display: flex;
  align-items: center;
  padding-right: 4px;
}

.wnc-Name {
  display: inline-block;
  font-style: normal;
  font-size: $fs-14;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: fw('semi-bold');
  margin: 0 4px 0 8px;

  &:not(&-link) {
    color: $dark-1;
  }

  &-link {
    cursor: pointer;

    color: $primary-color-next;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wnc-Link {
  @extend %app-table-hidden-items;
}
</style>
