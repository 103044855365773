import { has } from 'lodash'

import { CONFLUENCE_DEV_ROUTES } from '@/routes/confluence-dev-routes'
import { CORE_SETTINGS_ROUTES } from '@/routes/core-settings-pages'
import { JIRA_APP_SETTINGS_ROUTES } from '@/routes/jira-app-settings-pages'
import { OUTDATED_ROUTE_PATHS, ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { WEB_APP_SETTINGS_ROUTES } from '@/routes/web-app-settings-pages'
import { getAppType } from '@/util'
import { routeNamesAllowedOnlyForOwner } from '@/utils/menu-items'
import {
  EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
  EDIT_WORKSPACE_QUERY_KEY,
  WORKSPACE_ID_KEY
} from '@/utils/query-parameters'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import MainPage from '@/views'
import { APP_PLATFORMS } from '@root/app-platforms'

import BasicLayout from '@/components/basic-layout'
import MainLayout from '@/views/_mainLayout'
import MissingPage from '@/views/MissingPage'
import ShareLinkHandler from '@/views/ShareLinkHandler'
import Debug from '@/views/support/debug'
import WorkspaceDetailLayout from '@/views/workspaces/_workspaceId'
import HomePage from '@/views/workspaces/_workspaceId/homePage'
import ObjectivesLayout from '@/views/workspaces/_workspaceId/objectives'
import OkrElementsView from '@/views/workspaces/_workspaceId/objectives/_okrElementsView'
import OkrElementsRoadmap from '@/views/workspaces/_workspaceId/objectives/_okrElementsView/roadmap'
import OkrElementsTable from '@/views/workspaces/_workspaceId/objectives/_okrElementsView/table'
import OkrElementsTree from '@/views/workspaces/_workspaceId/objectives/_okrElementsView/tree'
import SettingsLayout from '@/views/workspaces/settings'

import OBJECTIVES_DASHBOARD_ROUTES from './objectives-dashboard'

const createWorkspaceRedirect = ({ to, tabQuery }) => {
  const name = ROUTE_NAMES.SETTINGS_WORKSPACES
  if (has(to.params, WORKSPACE_ID_KEY)) {
    return {
      name,
      query: {
        [EDIT_WORKSPACE_QUERY_KEY]: to.params.workspaceId,
        [EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]: tabQuery
      }
    }
  }
  return { name }
}

export const getResolvedRoutes = ({ appPlatform = APP_PLATFORMS.JIRA, isOwner = false } = {}) => {
  if (!Object.values(APP_PLATFORMS).includes(appPlatform)) {
    throw new Error(`appPlatform should be one of ${Object.values(APP_PLATFORMS).join(', ')}`)
  }

  const { isWebApp, isJiraApp } = getAppType({ appPlatform })

  let SETTINGS_CHILDREN_ROUTES = CORE_SETTINGS_ROUTES

  if (isJiraApp) {
    SETTINGS_CHILDREN_ROUTES = [...SETTINGS_CHILDREN_ROUTES, ...JIRA_APP_SETTINGS_ROUTES]
  }

  if (isWebApp) {
    const resolvedWebAppSettingsRoutes = isOwner
      ? WEB_APP_SETTINGS_ROUTES
      : WEB_APP_SETTINGS_ROUTES.filter(route => !routeNamesAllowedOnlyForOwner.includes(route.name))
    SETTINGS_CHILDREN_ROUTES = [...SETTINGS_CHILDREN_ROUTES, ...resolvedWebAppSettingsRoutes]
  }

  const SETTINGS_ROUTES = {
    path: ROUTE_PATHS.SETTINGS,
    name: ROUTE_NAMES.SETTINGS,
    component: SettingsLayout,
    redirect: { name: ROUTE_NAMES.SETTINGS_WORKSPACES },
    meta: {
      hideWorkspaceSelectInAppMenu: true
    },
    children: SETTINGS_CHILDREN_ROUTES
  }

  const WORKSPACES_ROUTES = {
    path: ROUTE_PATHS.WORKSPACES,
    name: ROUTE_NAMES.WORKSPACES,
    redirect: { name: ROUTE_NAMES.SETTINGS_WORKSPACES },
    component: BasicLayout,
    children: [
      {
        path: ROUTE_PATHS.WORKSPACE_ID,
        name: ROUTE_NAMES.WORKSPACE_ID,
        component: WorkspaceDetailLayout,
        redirect: { name: ROUTE_NAMES.HOME_PAGE },
        children: [
          {
            path: '',
            name: ROUTE_NAMES.HOME_PAGE,
            component: HomePage
          },
          {
            path: ROUTE_PATHS.OBJECTIVES,
            component: ObjectivesLayout,
            redirect: { name: ROUTE_NAMES.ALIGNMENT },
            children: [
              {
                path: ROUTE_PATHS.OKR_EXPLORER,
                name: ROUTE_NAMES.OKR_EXPLORER,
                component: OkrElementsView,
                redirect: { name: ROUTE_NAMES.OKR_ELEMENTS_EXPLORER },
                children: [
                  {
                    path: ROUTE_PATHS.OKR_ELEMENTS_EXPLORER,
                    name: ROUTE_NAMES.OKR_ELEMENTS_EXPLORER,
                    component: OkrElementsTable
                  }
                ]
              },
              {
                path: ROUTE_PATHS.ALIGNMENT,
                name: ROUTE_NAMES.ALIGNMENT,
                component: OkrElementsView,
                redirect: { name: ROUTE_NAMES.OKR_ELEMENTS_HIERARCHY_TABLE },
                children: [
                  {
                    path: ROUTE_PATHS.OKR_ELEMENTS_MIND_MAP,
                    name: ROUTE_NAMES.OKR_ELEMENTS_MIND_MAP,
                    component: OkrElementsTree
                  },
                  {
                    path: ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE,
                    name: ROUTE_NAMES.OKR_ELEMENTS_HIERARCHY_TABLE,
                    component: OkrElementsTable
                  },
                  {
                    path: ROUTE_PATHS.OKR_ELEMENTS_ROADMAP,
                    name: ROUTE_NAMES.OKR_ELEMENTS_ROADMAP,
                    component: OkrElementsRoadmap
                  }
                ]
              }
            ]
          },
          {
            path: OUTDATED_ROUTE_PATHS.MEMBERS,
            redirect: to =>
              createWorkspaceRedirect({
                to,
                tabQuery: TABLE_COLUMNS_KEYS.GROUPS
              }),
            children: [
              {
                path: OUTDATED_ROUTE_PATHS.MEMBERS_GROUPS,
                redirect: to =>
                  createWorkspaceRedirect({
                    to,
                    tabQuery: TABLE_COLUMNS_KEYS.GROUPS
                  })
              },
              {
                path: OUTDATED_ROUTE_PATHS.MEMBERS_USERS,
                redirect: to =>
                  createWorkspaceRedirect({
                    to,
                    tabQuery: TABLE_COLUMNS_KEYS.USERS
                  })
              }
            ]
          },
          {
            path: OUTDATED_ROUTE_PATHS.INTERVALS,
            redirect: to =>
              createWorkspaceRedirect({
                to,
                tabQuery: TABLE_COLUMNS_KEYS.INTERVALS
              })
          },
          OBJECTIVES_DASHBOARD_ROUTES
        ]
      },
      SETTINGS_ROUTES
    ]
  }

  const ROUTES = [
    {
      path: '',
      component: MainPage
    },
    // redirects from old but now unexisting pages(if someone has in bookmarks etc,
    // also for unknown reason if user open first time the jira plugin, company page is
    // opened, so keep it!)
    {
      path: OUTDATED_ROUTE_PATHS.OKR_BOARD_COMPANY,
      redirect: '/'
    },
    {
      path: OUTDATED_ROUTE_PATHS.COMPANY,
      redirect: '/'
    },
    {
      path: OUTDATED_ROUTE_PATHS.TEAM,
      redirect: '/'
    },
    {
      path: OUTDATED_ROUTE_PATHS.PERSONAL,
      redirect: '/'
    },
    {
      path: `/${ROUTE_PATHS.SUPPORT}`,
      children: [
        {
          path: ROUTE_PATHS.DEBUG,
          name: ROUTE_NAMES.DEBUG,
          component: Debug
        }
      ]
    },
    {
      path: `/${ROUTE_PATHS.SHARE}/:token?`,
      name: ROUTE_NAMES.SHARE,
      component: ShareLinkHandler,
      meta: {
        hideWorkspaceSelectInAppMenu: true
      }
    },
    WORKSPACES_ROUTES
  ]

  return [
    {
      path: '/',
      component: MainLayout,
      children: ROUTES
    },

    ...CONFLUENCE_DEV_ROUTES,

    {
      path: ROUTE_PATHS.MISSING_PAGE,
      name: ROUTE_NAMES.MISSING_PAGE,
      component: MissingPage,
      meta: {
        hideWorkspaceSelectInAppMenu: true
      }
    }
  ]
}
