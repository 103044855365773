<template>
  <Dropdown />
  <Backdrop />
  <DefaultDatesTimeline />

  <Timeline>
    <TimelineCorners />
    <TimelineResizers />
    <TimelineContent />
    <InvalidDatesIcon />
  </Timeline>

  <ExpandButton />

  <GhostTimeline />
</template>

<script setup>
import dayjs from 'dayjs'
import { isBoolean } from 'lodash'
import { PortalTarget } from 'portal-vue'
import {
  computed,
  ref,
  inject,
  nextTick,
  onBeforeUnmount,
  watch,
  onBeforeUpdate,
  h,
  getCurrentInstance,
  withModifiers,
  withDirectives,
  resolveDirective
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { getValidDueOrStartDate, localDateToUtc, utcDateToLocal } from '@/utils/date'
import { handleError } from '@/utils/error-handling'
import { memoizeFormatDate } from '@/utils/memoizations'
import { showNotify } from '@/utils/notify'
import {
  currentUserCanReadObjective,
  currentUserCanUpdateObjective,
  getObjectiveTypeName,
  isOkrElementClosed,
  objectiveIsJiraTask
} from '@/utils/objectives'
import { OKR_DATES_SELECT_DATE_PROPS, PERIOD_MODES } from '@/utils/okr-element-dates'
import { getScopeId } from '@/utils/render-function-helpers'
import { roadmapBus, TIMELINES_VIEWS } from '@/utils/roadmap'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import OkrDatesDropdown from '@/components/objectives/OkrDatesDropdown'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'OkrTimeline',
  inheritAttrs: false
})

const { t } = useI18n()

const props = defineProps({
  objective: {
    type: Object,
    required: true
  },

  oneDayWidth: {
    type: Number,
    required: true
  },

  minMaxDate: {
    type: Object,
    required: true
  },

  activeView: {
    type: String,
    required: true
  },

  showGhostTimeline: {
    type: Boolean
  },

  childListHeight: {
    type: Number,
    default: 0
  },

  isAutoPeriodMode: {
    type: Boolean
  },

  expanded: {
    type: Boolean
  }
})

const RESIZERS = {
  LEFT: 'left',
  RIGHT: 'right'
}

const DAYS_UNIT = 'days'

const TIMELINE_MIN_WIDTH = props.activeView === TIMELINES_VIEWS.WEEKS ? 18 : 12
const SCROLL_TRIGGER_OFFSET = 100
const SCROLL_SIZE = 20
const TIMELINE_SCROLL_OFFSET = 150
const GHOST_TIMELINE_DATE_MIN_WIDTH = 60

const ELEMENTS = {
  DATES_DROPDOWN: 'datesDropdown',
  BACKDROP: 'backdrop',
  TIMELINE_WITH_DEFAULT_DATES: 'timelineWithDefaultDates',
  RESIZERS: 'resizers',
  GHOST_TIMELINE: 'ghostTimeline'
}

const DAYS_COUNT_BY_VIEW = {
  [TIMELINES_VIEWS.WEEKS]: 7,
  [TIMELINES_VIEWS.MONTHS]: 14,
  [TIMELINES_VIEWS.QUARTERS]: 30
}

const { START_DATE: START_DATE_PROP } = OKR_DATES_SELECT_DATE_PROPS

const createDefaultPayload = objective => {
  const { id: elementId, parentId, intervalId } = objective

  return {
    elementId,
    parentId,
    intervalId
  }
}

const isCursorInViewPort = (canvas, clientX) => {
  return clientX > canvas.offsetLeft && clientX < window.innerWidth
}

const isTimelineOutOfView = (canvas, timeline) => {
  const canvasWidth = canvas.offsetWidth
  const canvasLeftPosition = canvas.scrollLeft
  const canvasRightPosition = canvasLeftPosition + canvasWidth
  const timelineLeftPosition = timeline.offsetLeft
  const timelineRightPosition = timelineLeftPosition + timeline.offsetWidth

  const outOnLeft = timelineRightPosition < canvasLeftPosition
  const outOnRight = timelineLeftPosition > canvasRightPosition
  return {
    left: outOnLeft,
    right: outOnRight
  }
}

const scrollToPoint = (canvas, point) => {
  canvas.scrollTo({
    left: point
    // behavior: 'smooth'
  })
}

const showDatesDropdown = ref(false)
const datesDropdownLoading = ref(false)
const isTimelineMoved = ref(false)
const resizeContinues = ref(false)
const moveContinues = ref(false)
const mouseX = ref(0)
const timelineDimension = ref(0)
const scrollDelta = ref(0)
const currentResizer = ref(null)
const transitionEnabled = ref(false)
const newTimelineLeftPosition = ref(null)
const newTimelineRightPosition = ref(null)
const previousTimelineLeftPosition = ref(null)
const previousTimelineRightPosition = ref(null)
const newTimelineDueDate = ref(null)
const newTimelineStartDate = ref(null)
const ghostTimelineLeftPosition = ref(0)
const ghostTimelineDueDate = ref(null)
const ghostTimelineStartDate = ref(null)
const ghostTimelineLoading = ref(false)
const ghostTimelineDueDateOutOfView = ref(false)
const ghostTimelineStartDateOutOfView = ref(false)
const resizeObserver = ref(null)
const timelineWidth = ref(0)

const roadmapState = inject('roadmapState')

onBeforeUpdate(() => {
  roadmapBus.emit('set-scroll-target')
})

const emit = defineEmits([
  'on-okr-element-updated',
  'on-timeline-interacted',
  'edit-okr-element',
  'update:show-nav-buttons',
  'update-depended-elements',
  'update-changed-elements'
])

const timelineHeight = computed(() => {
  const minHeight = 28
  const maxHeight = 32
  const { childCount } = props.objective
  const [height] = [
    isJiraIssue.value && minHeight,
    childCount === 0 && minHeight,
    maxHeight
  ].filter(Boolean)

  return `${height}px`
})

const isJiraIssue = computed(() => {
  return objectiveIsJiraTask(props.objective)
})

const classes = computed(() => {
  return {
    'ot-Timeline': true,
    'ot-Timeline-withTransition': transitionEnabled.value,
    'ot-Timeline-moving': moveContinues.value,
    'ot-Timeline-autoPeriodMode': props.isAutoPeriodMode,
    'ot-Timeline-invalidDates': isInvalidDates.value,
    'ot-Timeline-withExplanation': showExplanationAutoDates.value && !isInteracted.value,
    'ot-Timeline-withChildren': props.objective.childCount > 0
  }
})

const isInteracted = computed(() => {
  return moveContinues.value || resizeContinues.value
})

const isInvalidDates = computed(() => {
  return localDates.value.dueDate < localDates.value.startDate
})

const showExplanationAutoDates = computed(() => {
  return props.objective.childCount > 0 && !isJiraIssue.value && props.expanded
})

const localDates = computed(() => {
  const { dueDate, elementStartDate, automaticElementStartDate, automaticDueDate } = props.objective
  const startDate = elementStartDate || automaticElementStartDate
  const endDate = dueDate || automaticDueDate

  return {
    startDate: utcDateToLocal(new Date(startDate)),
    dueDate: utcDateToLocal(new Date(endDate))
  }
})

const showElements = computed(() => {
  return {
    [ELEMENTS.DATES_DROPDOWN]: showTimeline.value && userCanUpdateObjective.value,
    [ELEMENTS.BACKDROP]: showTimeline.value && isInteracted.value,
    [ELEMENTS.TIMELINE_WITH_DEFAULT_DATES]:
      !showTimeline.value && objectiveWithDefaultDates.value && !props.isAutoPeriodMode,

    [ELEMENTS.RESIZERS]:
      (userCanUpdateObjective.value && !isInvalidDates.value && rowMouseOvered.value) ||
      isInteracted.value,
    [ELEMENTS.GHOST_TIMELINE]:
      props.showGhostTimeline && moveContinues.value && userCanUpdateObjective.value
  }
})

const showTimeline = computed(() => {
  if (props.isAutoPeriodMode) {
    return true
  }
  return Boolean(props.objective.dueDate || props.objective.elementStartDate)
})

const store = useStore()

const hasAccessToJira = computed(() => store.state.system.userData.hasAccessToJira)
const isTask = computed(() => objectiveIsJiraTask(props.objective))

const userCanUpdateObjective = computed(() => {
  if (isOkrElementClosed(props.objective)) {
    return false
  }

  const baseCondition = currentUserCanUpdateObjective(props.objective)

  if (isTask.value) {
    return baseCondition && hasAccessToJira.value
  }

  return baseCondition
})

const objectiveWithDefaultDates = computed(() => {
  return !props.objective.dueDate && !props.objective.elementStartDate
})

const explanationHeight = computed(() => {
  if (showExplanationAutoDates.value) {
    return `${props.childListHeight}px`
  }
  return 0
})

const iconName = computed(() => {
  return props.isAutoPeriodMode ? PERIOD_MODES.AUTO.icon : PERIOD_MODES.MANUAL.icon
})

const displayDates = computed(() => {
  const startDate = newTimelineStartDate.value || localDates.value.startDate
  const dueDate = newTimelineDueDate.value || localDates.value.dueDate
  return {
    startDate: memoizeFormatDate(startDate),
    dueDate: memoizeFormatDate(dueDate)
  }
})

const ghostTimelineDates = computed(() => {
  const startDate = memoizeFormatDate(ghostTimelineStartDate.value)
  const dueDate = memoizeFormatDate(ghostTimelineDueDate.value)
  const datesRange = `${startDate} - ${dueDate}`

  if (ghostTimelineDueDateOutOfView.value) {
    return {
      startDate: datesRange,
      dueDate: null
    }
  } else if (ghostTimelineStartDateOutOfView.value) {
    return {
      startDate: null,
      dueDate: datesRange
    }
  } else {
    return {
      startDate,
      dueDate
    }
  }
})

const initialRight = computed(() => {
  const { maxDate } = props.minMaxDate

  const diff = maxDate.diff(localDates.value.dueDate, DAYS_UNIT)

  return `${diff * props.oneDayWidth}px`
})
const initialLeft = computed(() => {
  const { minDate } = props.minMaxDate

  // removed .utc()
  // because of https://oboard.atlassian.net/jira/software/c/projects/OK/boards/14?assignee=557058%3A989b8931-c8bf-4350-8b05-2ff823c62976&selectedIssue=OK-2859
  // in negative timezones we have a bug with wrong dates and calendar shifting
  const diff = dayjs(localDates.value.startDate).diff(minDate, DAYS_UNIT)

  return `${diff * props.oneDayWidth}px`
})
const resolvedRight = computed(() => {
  return newTimelineRightPosition.value === null
    ? initialRight.value
    : `${newTimelineRightPosition.value}px`
})
const resolvedLeft = computed(() => {
  return newTimelineLeftPosition.value === null
    ? initialLeft.value
    : `${newTimelineLeftPosition.value}px`
})

const ghostTimelineWidth = computed(() => {
  return props.oneDayWidth * daysCountByView.value
})

const ghostTimelineDateMinWidth = computed(() => {
  return `${GHOST_TIMELINE_DATE_MIN_WIDTH}px`
})

const daysCountByView = computed(() => {
  return DAYS_COUNT_BY_VIEW[props.activeView]
})

const previousTimelineLeftPositionValue = computed(() => {
  return parseFloat(previousTimelineLeftPosition.value)
})

const previousTimelineRightPositionValue = computed(() => {
  return parseFloat(previousTimelineRightPosition.value)
})

const isLeftResizer = computed(() => {
  return currentResizer.value === RESIZERS.LEFT
})

const triggersForTransition = computed(() => {
  return [parseFloat(resolvedLeft.value), parseFloat(resolvedRight.value)]
})

const backdropReference = ref(null)

const mouseMoveHandler = e => {
  const { clientX } = e
  const { canvas } = roadmapState
  // How far the mouse has been moved
  const dx = clientX - mouseX.value
  const oldTimelineWidth = backdropReference.value.offsetWidth
  const shiftByDays = (dx + scrollDelta.value) / props.oneDayWidth

  if (isCursorInViewPort(canvas, clientX)) {
    if (isLeftResizer.value) {
      const maxAllowedLeftPosition =
        oldTimelineWidth + previousTimelineLeftPositionValue.value - TIMELINE_MIN_WIDTH

      scrollRoadMapAndCalculateScrollDelta({
        canvas,
        clientX,
        additionalRightCondition: newTimelineLeftPosition.value <= maxAllowedLeftPosition
      })

      // Adjust the dimension of element
      const newPosition = timelineDimension.value + dx + scrollDelta.value

      if (newPosition >= maxAllowedLeftPosition) {
        newTimelineLeftPosition.value = maxAllowedLeftPosition
      } else {
        newTimelineLeftPosition.value = newPosition
        const newStartDate = dayjs(localDates.value.startDate).add(shiftByDays, DAYS_UNIT)
        newTimelineStartDate.value = getValidDueOrStartDate(newStartDate, START_DATE_PROP)
      }
    } else {
      const minAllowedRightPosition =
        oldTimelineWidth + previousTimelineRightPositionValue.value - TIMELINE_MIN_WIDTH

      scrollRoadMapAndCalculateScrollDelta({
        canvas,
        clientX,
        additionalLeftCondition: newTimelineRightPosition.value <= minAllowedRightPosition
      })

      // Adjust the dimension of element
      const newPosition = timelineDimension.value - dx - scrollDelta.value

      if (newPosition >= minAllowedRightPosition) {
        newTimelineRightPosition.value = minAllowedRightPosition
      } else {
        newTimelineRightPosition.value = newPosition
        const newDueDate = dayjs(localDates.value.dueDate).add(shiftByDays, DAYS_UNIT)
        newTimelineDueDate.value = getValidDueOrStartDate(newDueDate)
      }
    }
  }
}

const ghostTimelineMouseMoveHandler = e => {
  const { minDate } = props.minMaxDate
  const mousePosition = e.offsetX
  ghostTimelineLeftPosition.value = `${mousePosition}px`
  const shiftByDays = mousePosition / props.oneDayWidth
  ghostTimelineStartDate.value = minDate.add(shiftByDays, DAYS_UNIT)
  ghostTimelineDueDate.value = minDate.add(shiftByDays + daysCountByView.value - 1, DAYS_UNIT)
}

const fullTimelineMouseMoveHandler = e => {
  const { clientX } = e
  const { canvas } = roadmapState

  if (isCursorInViewPort(canvas, clientX)) {
    scrollRoadMapAndCalculateScrollDelta({ canvas, clientX })
    const dx = clientX - mouseX.value
    if (showDatesDropdown.value) {
      showDatesDropdown.value = false
    }
    if (!isTimelineMoved.value) {
      isTimelineMoved.value = true
    }

    newTimelineLeftPosition.value =
      parseFloat(previousTimelineLeftPosition.value) + dx + scrollDelta.value
    newTimelineRightPosition.value =
      parseFloat(previousTimelineRightPosition.value) - dx - scrollDelta.value
    const shiftByDays = (dx + scrollDelta.value) / props.oneDayWidth
    newTimelineStartDate.value = dayjs(localDates.value.startDate).add(shiftByDays, DAYS_UNIT)
    newTimelineDueDate.value = dayjs(localDates.value.dueDate).add(shiftByDays, DAYS_UNIT)
  }
}

const onResizerMouseDown = async (e, side) => {
  if (!props.isAutoPeriodMode) {
    currentResizer.value = side
    resizeContinues.value = true
    // Get the current mouse position
    mouseX.value = e.clientX
    // Calculate the dimension of element
    previousTimelineLeftPosition.value = resolvedLeft.value
    previousTimelineRightPosition.value = resolvedRight.value
    const dimensionParam = isLeftResizer.value ? resolvedLeft.value : resolvedRight.value
    timelineDimension.value = parseFloat(dimensionParam)
    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }
}

const onTimelineMouseDown = e => {
  if (!props.isAutoPeriodMode && !isInvalidDates.value && userCanUpdateObjective.value) {
    moveContinues.value = true
    mouseX.value = e.clientX
    previousTimelineLeftPosition.value = resolvedLeft.value
    previousTimelineRightPosition.value = resolvedRight.value
    document.addEventListener('mousemove', fullTimelineMouseMoveHandler)
  }

  document.addEventListener('mouseup', fullTimelineMouseUpHandler)
}

const timelineReference = ref(null)

const scrollToSide = (isLeftSide = true) => {
  const { canvas, timelinesWidth } = roadmapState
  const { x } = timelineReference.value.getBoundingClientRect()
  if (isLeftSide) {
    const delta = canvas.offsetLeft - x + TIMELINE_SCROLL_OFFSET
    scrollToPoint(canvas, canvas.scrollLeft - delta)
  } else {
    const delta =
      timelineWidth.value - (canvas.offsetLeft + timelinesWidth - x) + TIMELINE_SCROLL_OFFSET

    scrollToPoint(canvas, canvas.scrollLeft + delta)
  }
}

const scrollRoadMapAndCalculateScrollDelta = ({
  canvas,
  clientX,
  additionalLeftCondition = null,
  additionalRightCondition = null
}) => {
  const baseLeftScrollCondition = clientX <= canvas.offsetLeft + SCROLL_TRIGGER_OFFSET
  const baseRightScrollCondition = clientX >= window.innerWidth - SCROLL_TRIGGER_OFFSET

  const scrollLeftCondition = isBoolean(additionalLeftCondition)
    ? baseLeftScrollCondition && additionalLeftCondition
    : baseLeftScrollCondition
  const scrollRightCondition = isBoolean(additionalRightCondition)
    ? baseRightScrollCondition && additionalRightCondition
    : baseRightScrollCondition

  if (scrollLeftCondition) {
    canvas.scrollLeft -= SCROLL_SIZE
    if (canvas.scrollLeft > 0) {
      scrollDelta.value -= SCROLL_SIZE
    }
  }

  if (scrollRightCondition) {
    canvas.scrollLeft += SCROLL_SIZE
    if (canvas.scrollLeft < canvas.scrollWidth - roadmapState.timelinesWidth) {
      scrollDelta.value += SCROLL_SIZE
    }
  }
}

const mouseUpHandler = () => {
  updateObjective()
  currentResizer.value = null
  resizeContinues.value = false
  mouseX.value = 0
  timelineDimension.value = 0
  scrollDelta.value = 0

  document.removeEventListener('mousemove', mouseMoveHandler)
  document.removeEventListener('mouseup', mouseUpHandler)
}

const fullTimelineMouseUpHandler = () => {
  if (!isTimelineMoved.value) {
    showDatesDropdown.value = !showDatesDropdown.value
  }

  isTimelineMoved.value = false
  updateObjective()
  moveContinues.value = false
  mouseX.value = 0
  scrollDelta.value = 0

  document.removeEventListener('mousemove', fullTimelineMouseMoveHandler)
  document.removeEventListener('mouseup', fullTimelineMouseUpHandler)
}

const updateObjectiveFromGhostTimeline = async () => {
  ghostTimelineLoading.value = true
  const api = new ObjectivesInfoApiHandler()

  const payload = {
    ...createDefaultPayload(props.objective),
    elementStartDate: localDateToUtc(
      getValidDueOrStartDate(ghostTimelineStartDate.value, START_DATE_PROP)
    ),
    dueDate: localDateToUtc(getValidDueOrStartDate(ghostTimelineDueDate.value))
  }

  try {
    const { changedElementDates, updatedElementParameters } = await api.updateOkrElement(payload)
    updateDependedElements({ changedElementDates, updatedElementParameters, payload })
    moveContinues.value = false
    ghostTimelineLeftPosition.value = 0
    showNotify({
      title: t('roadmap.updated_timeline_success_message', {
        dateProp: t('roadmap.start_and_due_dates')
      })
    })
    logUpdateTimelineEvent()
  } catch (error) {
    document.addEventListener('mousemove', ghostTimelineMouseMoveHandler)
    moveContinues.value = true
    handleError({ error })
  }

  ghostTimelineLoading.value = false
}

const updateDependedElements = ({ changedElementDates, updatedElementParameters, payload }) => {
  const onlyDependedElements = changedElementDates.filter(
    item => item.elementId !== props.objective.id
  )

  const changedElementDatesForCurrentElement = changedElementDates.find(
    item => item.elementId === props.objective.id
  )

  const { automaticDueDate, automaticElementStartDate } = changedElementDatesForCurrentElement

  const onlyAnotherElements = updatedElementParameters.filter(
    item => item.elementId !== props.objective.id
  )

  const updatedParametersForCurrentElement = updatedElementParameters.find(
    item => item.elementId === props.objective.id
  )

  emit(
    'on-okr-element-updated',

    {
      ...payload,
      ...updatedParametersForCurrentElement,
      automaticElementStartDate,
      automaticDueDate
    }
  )

  emit('update-depended-elements', onlyDependedElements)
  emit('update-changed-elements', onlyAnotherElements)
}

const logEventLabel = getObjectiveTypeName(props.objective.typeId)

const logUpdateTimelineEvent = () => {
  tracker.logEvent('timeline updated', {
    category: EVENT_CATEGORIES.ROADMAP,
    label: logEventLabel
  })
}

const updateObjective = async () => {
  const api = new ObjectivesInfoApiHandler()
  transitionEnabled.value = true
  const { objective } = props

  const { elementStartDate, dueDate } = objective

  const payload = {
    ...createDefaultPayload(objective),
    elementStartDate,
    dueDate
  }

  let dateProp = null

  let dataAreChanged = false

  if (currentResizer.value) {
    dateProp = isLeftResizer.value
      ? t('objectives.table_header_startDate')
      : t('objectives.table_header_duedate')
  } else {
    dateProp = t('roadmap.start_and_due_dates')
  }

  if (newTimelineStartDate.value) {
    const newStartDate = localDateToUtc(
      getValidDueOrStartDate(newTimelineStartDate.value, START_DATE_PROP)
    )
    if (newStartDate.getTime() !== new Date(props.objective.elementStartDate).getTime()) {
      payload.elementStartDate = newStartDate
      dataAreChanged = true
    }
  }
  if (newTimelineDueDate.value) {
    const newDueDate = localDateToUtc(getValidDueOrStartDate(newTimelineDueDate.value))
    if (newDueDate.getTime() !== new Date(props.objective.dueDate).getTime()) {
      payload.dueDate = newDueDate
      dataAreChanged = true
    }
  }

  if (dataAreChanged) {
    try {
      const { changedElementDates, updatedElementParameters } = await api.updateOkrElement(payload)
      updateDependedElements({ changedElementDates, updatedElementParameters, payload })
      showNotify({
        title: t('roadmap.updated_timeline_success_message', { dateProp })
      })
      logUpdateTimelineEvent()
    } catch (error) {
      handleError({ error })
    }
  }
  newTimelineLeftPosition.value = null
  newTimelineRightPosition.value = null
  newTimelineDueDate.value = null
  newTimelineStartDate.value = null

  timelineReference.value.addEventListener(
    'transitionend',
    () => {
      transitionEnabled.value = false
    },
    { once: true }
  )
}

const updateElementDate = async dates => {
  const { dateProp } = dates
  datesDropdownLoading.value = true
  const api = new ObjectivesInfoApiHandler()

  const payload = {
    ...createDefaultPayload(props.objective),
    ...dates
  }

  try {
    const { changedElementDates, updatedElementParameters } = await api.updateOkrElement(payload)
    updateDependedElements({ changedElementDates, updatedElementParameters, payload })
    showNotify()

    const eventName = dateProp === START_DATE_PROP ? 'set start date' : 'set due date'
    tracker.logEvent(eventName, {
      category: EVENT_CATEGORIES.ROADMAP,
      label: logEventLabel
    })

    if (!payload.elementStartDate && !payload.dueDate) {
      tracker.logEvent('set default timeline', {
        category: EVENT_CATEGORIES.ROADMAP,
        label: logEventLabel
      })
    }
  } catch (error) {
    handleError({ error })
  }
  datesDropdownLoading.value = false
}

const updatePeriodMode = async mode => {
  datesDropdownLoading.value = true
  const api = new ObjectivesInfoApiHandler()

  const payload = {
    ...createDefaultPayload(props.objective),
    startDateManual: mode,
    dueDateManual: mode
  }

  if (mode) {
    payload.elementStartDate = props.objective.automaticElementStartDate
    payload.dueDate = props.objective.automaticDueDate
  } else {
    payload.elementStartDate = null
    payload.dueDate = null
  }

  try {
    const { changedElementDates, updatedElementParameters } = await api.updateOkrElement(payload)
    updateDependedElements({ changedElementDates, updatedElementParameters, payload })
    showNotify()

    const eventName = mode ? 'set manual period' : 'set auto bottom-up'
    tracker.logEvent(eventName, {
      category: EVENT_CATEGORIES.ROADMAP,
      label: logEventLabel
    })
  } catch (error) {
    handleError({ error })
  }
  datesDropdownLoading.value = false
}

const initResizeObserver = () => {
  resizeObserver.value = new ResizeObserver(() => {
    timelineWidth.value = timelineReference.value.offsetWidth
  })
  resizeObserver.value.observe(timelineReference.value)
}

const disconnectResizeObserver = () => {
  timelineWidth.value = null
  if (resizeObserver.value) {
    resizeObserver.value.disconnect()
    resizeObserver.value = null
  }
}

const refreshPositions = () => {
  newTimelineDueDate.value = null
  newTimelineStartDate.value = null
  newTimelineLeftPosition.value = null
  newTimelineRightPosition.value = null
}

const ghostTimelineStartDateReference = ref(null)
const ghostTimelineDueDateReference = ref(null)
const ghostTimelineObserver = ref(null)
const rowMouseOvered = ref(false)

const onRowMouseOver = async () => {
  const { canvas } = roadmapState

  if (props.showGhostTimeline) {
    ghostTimelineStartDateOutOfView.value = false
    ghostTimelineDueDateOutOfView.value = false
    moveContinues.value = true
    document.addEventListener('mousemove', ghostTimelineMouseMoveHandler)
    await nextTick()
    // 3 times cause due date or start date is jumping to another side by intersecting
    // but their containers still exist for intersection observer correct work
    const fullWidth = ghostTimelineWidth.value + GHOST_TIMELINE_DATE_MIN_WIDTH * 3
    if (fullWidth < roadmapState.timelinesWidth) {
      ghostTimelineObserver.value = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.target === ghostTimelineStartDateReference.value) {
              ghostTimelineStartDateOutOfView.value = !entry.isIntersecting
            }
            if (entry.target === ghostTimelineDueDateReference.value) {
              ghostTimelineDueDateOutOfView.value = !entry.isIntersecting
            }
          })
        },
        { threshold: 1, root: canvas, rootMargin: '100% 0% 100% 0%' } // only horizontal observing
      )

      if (ghostTimelineStartDateReference.value) {
        ghostTimelineObserver.value.observe(ghostTimelineStartDateReference.value)
      }
      if (ghostTimelineDueDateReference.value) {
        ghostTimelineObserver.value.observe(ghostTimelineDueDateReference.value)
      }
    }
  } else {
    if (timelineReference.value) {
      rowMouseOvered.value = true
      emit('update:show-nav-buttons', isTimelineOutOfView(canvas, timelineReference.value))
    }
    if (ghostTimelineObserver.value) {
      ghostTimelineObserver.value.disconnect()
      ghostTimelineObserver.value = null
    }
  }
}

const onRowMouseOut = () => {
  if (rowMouseOvered.value) {
    rowMouseOvered.value = false
  }

  if (props.showGhostTimeline) {
    moveContinues.value = false
    ghostTimelineLeftPosition.value = 0
    document.removeEventListener('mousemove', ghostTimelineMouseMoveHandler)
  }
}

const onRowClick = e => {
  if (userCanUpdateObjective.value) {
    document.removeEventListener('mousemove', ghostTimelineMouseMoveHandler)
    updateObjectiveFromGhostTimeline(e.clientX)
  }
}

defineExpose({
  onRowMouseOver,
  onRowMouseOut,
  onRowClick,
  refreshPositions,
  scrollToSide
})

onBeforeUnmount(() => {
  disconnectResizeObserver()
})

watch(isInteracted, newValue => {
  if (showTimeline.value) {
    emit('on-timeline-interacted', newValue)
  }
})

watch(
  triggersForTransition,
  (newValue, oldValue) => {
    if (transitionEnabled.value) {
      const [newResolvedLeft, newResolvedRight] = newValue
      const [oldResolvedLeft, oldResolvedRight] = oldValue

      const differenceLeft = Math.abs(newResolvedLeft - oldResolvedLeft)
      const differenceRight = Math.abs(newResolvedRight - oldResolvedRight)

      const someDifferenceTooBig = [differenceLeft, differenceRight].some(
        difference => difference > 50
      )

      if (someDifferenceTooBig) {
        transitionEnabled.value = false
      }
    }
  },
  { deep: true }
)

watch(
  () => props.activeView,
  () => {
    newTimelineLeftPosition.value = null
    newTimelineRightPosition.value = null
  }
)

watch(
  showTimeline,
  newValue => {
    if (newValue) {
      nextTick(() => {
        initResizeObserver()
      })
    } else {
      disconnectResizeObserver()
    }
  },
  { immediate: true }
)

// somth wrong here need to fix and replace watcher ↑ to watchEffefct
/*
watchEffect(
  () => {
    if (showTimeline.value) {
      initResizeObserver()
    } else {
      disconnectResizeObserver()
    }
  },
  {
    flush: 'post'
  }
)
 */

/*
return () =>
      h(
        'div',
        {
          onClick: () => {
            emit('click')
          },
          class: {
            'odt-Trigger': true,
            'odt-Trigger-active': props.active,
            'odt-Trigger-disabled': props.disabled
          }
        },
        text.value
      )
 */

const Dropdown = () => {
  if (showElements.value[ELEMENTS.DATES_DROPDOWN]) {
    return h(OkrDatesDropdown, {
      loading: datesDropdownLoading.value,
      objective: props.objective,
      showDatesDropdown: showDatesDropdown.value,
      isAutoPeriodMode: props.isAutoPeriodMode,
      showPeriodModeSwitch: !isJiraIssue.value,
      showHead: true,
      appendTo: '.otl-Timelines',
      onHideDatesDropdown: () => (showDatesDropdown.value = false),
      onEditObjective: () => emit('edit-okr-element'),
      onUpdatePeriodMode: mode => updatePeriodMode(mode),
      onUpdateElementDate: dates => updateElementDate(dates)
    })
  }
}

const Backdrop = () => {
  if (showElements.value[ELEMENTS.BACKDROP]) {
    return h('div', {
      class: 'ot-Backdrop',
      style: {
        '--height': timelineHeight.value,
        '--left': previousTimelineLeftPosition.value,
        '--right': previousTimelineRightPosition.value
      },
      ref: backdropReference
    })
  }
}

const DefaultDatesTimeline = () => {
  if (showElements.value[ELEMENTS.TIMELINE_WITH_DEFAULT_DATES]) {
    return h('div', {
      class: ['ot-Timeline', 'ot-Timeline-defaultDates'],
      style: {
        '--height': timelineHeight.value,
        '--left': resolvedLeft.value,
        '--right': resolvedRight.value
      }
    })
  }
}

const GhostTimeline = () => {
  if (showElements.value[ELEMENTS.GHOST_TIMELINE]) {
    const scopeId = getScopeId(getCurrentInstance())
    return h(
      'div',
      {
        class: 'ot-GhostTimeline',
        style: {
          '--left': ghostTimelineLeftPosition.value,
          '--width': `${ghostTimelineWidth.value}px`,
          '--height': timelineHeight.value
        }
      },
      [
        h(
          'div',
          {
            ...scopeId,
            class: 'ot-GhostTimeline_StartDate',
            ref: ghostTimelineStartDateReference,
            style: {
              '--min-width': ghostTimelineDateMinWidth.value
            }
          },
          ghostTimelineDates.value.startDate
        ),

        ghostTimelineLoading.value
          ? h(SkeletonItem, {
              width: '100%',
              height: '100%',
              color: 'var(--background-color)',
              style: {
                opacity: '0.5'
              }
            })
          : null,

        h(
          'div',
          {
            ...scopeId,
            class: 'ot-GhostTimeline_DueDate',
            ref: ghostTimelineDueDateReference,
            style: {
              '--min-width': ghostTimelineDateMinWidth.value
            }
          },
          ghostTimelineDates.value.dueDate
        )
      ]
    )
  }
}

const ExpandButton = () => {
  if (showTimeline.value && !isJiraIssue.value) {
    return h(
      'div',
      {
        class: {
          'ot-TimelineExpandButton': true,
          'ot-TimelineExpandButton-expanded': props.expanded,
          'ot-TimelineExpandButton-invalidDates': isInvalidDates.value
        },
        style: {
          '--left': isInvalidDates.value ? null : resolvedLeft.value,
          '--right': isInvalidDates.value ? resolvedRight.value : null
        }
      },
      h(PortalTarget, {
        name: `timeline-expand-${props.objective.id}`
      })
    )
  }
}

const Timeline = (_, { slots }) => {
  if (showTimeline.value) {
    return h(
      'div',
      {
        id: `timeline-${props.objective.id}`,
        ref: timelineReference,
        class: classes.value,
        style: {
          '--height': timelineHeight.value,
          '--left': resolvedLeft.value,
          '--right': resolvedRight.value,
          '--explanation-height': explanationHeight.value
        },
        'data-testid': 'timeline',
        onMousedown: withModifiers(
          e => {
            onTimelineMouseDown(e)
          },
          ['self']
        )
      },
      h(slots.default)
    )
  }
}

const TimelineCorners = () => {
  if (showExplanationAutoDates.value && !isInteracted.value) {
    const scopeId = getScopeId(getCurrentInstance())
    return h(
      'div',
      {
        class: 'ot-TimelineCorners'
      },
      [
        h(AppIcon, {
          ...scopeId,
          iconName: 'timeline-corner',
          width: '6',
          height: '6',
          class: 'ot-Corner'
        }),
        h(AppIcon, {
          ...scopeId,
          iconName: 'timeline-corner-right',
          width: '6',
          height: '6',
          class: ['ot-Corner']
        })
      ]
    )
  }
}

const TimelineResizers = () => {
  if (showElements.value[ELEMENTS.RESIZERS]) {
    const scopeId = getScopeId(getCurrentInstance())

    return Object.values(RESIZERS).map(resizer => {
      const isLeftResizer = resizer === RESIZERS.LEFT
      const displayDate = isLeftResizer ? displayDates.value.startDate : displayDates.value.dueDate

      const children = [
        displayDate,
        isJiraIssue.value
          ? null
          : h(AppIcon, {
              iconName: iconName.value,
              height: 24,
              width: 24,
              style: {
                color: '#B5BBC5'
              }
            })
      ]

      return h(
        'div',
        {
          ...scopeId,
          class: {
            'ot-Timeline_Resizer': true,
            [`ot-Timeline_Resizer-${resizer}`]: true,
            'ot-Timeline_Resizer-disabled': props.isAutoPeriodMode
          },
          onMousedown: e => onResizerMouseDown(e, resizer)
        },
        h(
          'div',
          {
            ...scopeId,
            class: 'ot-TimelineResizer_Date',
            style: {
              '--min-width': ghostTimelineDateMinWidth.value
            }
          },
          isLeftResizer ? children : children.reverse()
        )
      )
    })
  }
}

const TimelineContent = () => {
  const scopeId = getScopeId(getCurrentInstance())
  const hasReadAccess = currentUserCanReadObjective(props.objective)
  const { name } = props.objective
  const displayName = hasReadAccess ? name : '∗∗∗∗∗∗∗∗∗∗∗'
  return h(
    'div',
    {
      class: 'ot-Timeline_Content'
    },
    [
      h(OkrIcon, {
        objective: props.objective,
        withFill: false,
        withLockIcon: false,
        class: 'ot-ContentIcon',
        ...scopeId
      }),
      h(
        'div',
        {
          ...scopeId,
          class: 'ot-ContentTitle'
        },
        [displayName, showElements.value[ELEMENTS.RESIZERS]]
      )
    ]
  )
}

const InvalidDatesIcon = () => {
  if (isInvalidDates.value) {
    const tippy = resolveDirective('tippy')
    return withDirectives(
      h(AppIcon, {
        iconName: 'warning',
        height: '20',
        width: '20',
        class: 'ot-WarningIcon',
        'data-testid': 'invalid-icon'
      }),
      [[tippy, { content: t('roadmap.invalid_dates_warning'), placement: 'top' }]]
    )
  }
}
</script>

<style lang="scss" scoped>
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
/* eslint-disable-next-line */
%common-timeline-styles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: var(--height);
  border-radius: $border-radius-sm-next;
  will-change: height, left, right, background-color;
}

/* eslint-disable-next-line */
%date-styles {
  pointer-events: none;
  min-width: var(--min-width);
  min-height: 1px; // necessary for intersection Observer
  font-weight: fw('regular');
  font-size: $fs-14;
  color: $dark-3;
  position: absolute;
  top: 50%;
  white-space: nowrap;
  user-select: none;
}

.ot-Timeline {
  left: var(--left);
  right: var(--right);
  display: flex;
  align-items: center;
  z-index: 3;
  background-color: var(--background-color);
  cursor: move;
  user-select: none;
  padding: 0 4px;
  @extend %common-timeline-styles;

  + .ot-TimelineExpandButton {
    left: var(--left);
  }

  &-autoPeriodMode {
    cursor: pointer;
  }

  &-disabled {
    cursor: pointer;
  }

  &-withTransition {
    transition: left, right, $transition-fast;
  }

  &-invalidDates {
    left: unset;
    width: 0;
    cursor: pointer;

    + .ot-TimelineExpandButton {
      left: calc(var(--left) - 12px);
      // transform: translate3d(calc(-100% - 12px), 0, 0);
      //
      // &-expanded {
      //   transform: translate3d(calc(-100% - 12px), 0, 0) rotate(90deg);
      // }
    }
  }

  &-withExplanation {
    border-radius: $border-radius-sm-next $border-radius-sm-next 0 0;

    &:before {
      content: '';
      width: 100%;
      height: var(--explanation-height);
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate3d(0, 100%, 0);
      pointer-events: none;
      background-color: var(--explanation-bg);
      border: 1px dashed var(--background-color);
      border-top: 0;
      border-radius: 0 0 $border-radius-sm-next $border-radius-sm-next;
      will-change: background-color, height;
    }

    &.ot-Timeline-autoPeriodMode {
      &:before {
        border-style: solid;
        border-color: transparent;
      }
    }
  }

  &:not(&-invalidDates) {
    @media (any-hover: hover) {
      &:hover,
      &:active {
        .ot-Timeline_Resizer {
          pointer-events: all;
          opacity: 1;
        }

        + .ot-TimelineExpandButton {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &-defaultDates {
    background-color: rgba(var(--rgb-background-color), 0.1);
    // border: 1px dashed var(--background-color);
    pointer-events: none;
  }
}

.ot-TimelineExpandButton {
  position: absolute;
  left: var(--left, unset);
  top: calc(50% - 12px);
  will-change: left;
  transform: translateX(-100%);
  transition: transform $transition-fast ease;

  &-expanded {
    transform: translateX(-100%) rotate(90deg);
  }

  &-expanded#{&}-invalidDates {
    transform: rotate(90deg);
  }

  &:deep(.ot-IconExpand) {
    color: var(--background-color);
  }

  &-invalidDates {
    transform: none;
    // 12px is timeline with invalid dates width padding left + padding right (6px)
    right: calc(var(--right) + 12px);
  }
}

.ot-TimelineCorners {
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  color: var(--background-color);
}

.ot-Backdrop {
  left: var(--left);
  right: var(--right);
  background-color: rgba(var(--rgb-background-color), 0.5);
  @extend %common-timeline-styles;
  pointer-events: none;
}

.ot-Timeline_Content {
  display: flex;
  align-items: center;
  gap: 9px;
  // position: sticky;
  // left: 6px;
  user-select: none;
  pointer-events: none;
  // max-width: calc(100% - 12px);
  overflow: hidden;
}

.ot-ContentTitle {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: $fs-20;
  color: $white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ot-ContentIcon {
  position: relative;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    height: 16px;
    width: 1px;
    background-color: rgba($white, 0.5);
    top: 50%;
    right: -3px;
    transform: translate3d(0, -50%, 0);
  }
}

.ot-Timeline_Resizer {
  cursor: ew-resize;
  height: 100%;
  opacity: 0;
  width: 12px;
  pointer-events: none;
  transition: opacity $transition-fast;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:active {
    pointer-events: all;
    opacity: 1;
  }

  &:after {
    cursor: ew-resize;
    content: '';
    display: block;
    position: relative;
    background-color: $white;
    width: 2px;
    height: 12px;
    border-radius: $border-radius-xs;
  }

  .is-safari & {
    &:after {
      box-shadow: 0 0 0 2px var(--background-color);
    }
  }

  &:not(.is-safari &) {
    &:after {
      outline: 2px solid var(--background-color);
    }
  }

  &:before {
    content: '';
    cursor: ew-resize;
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }

  .ot-TimelineResizer_Date {
    @extend %date-styles;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &-left {
    left: -10px;

    &:before {
      border-right: 4px solid var(--background-color);
    }

    .ot-TimelineResizer_Date {
      left: -12px;
      text-align: right;
      justify-content: flex-end;
      transform: translate3d(-100%, -50%, 0);
    }
  }

  &-right {
    right: -10px;
    flex-direction: row-reverse;
    &:before {
      border-left: 4px solid var(--background-color);
    }

    .ot-TimelineResizer_Date {
      right: -12px;
      transform: translate3d(100%, -50%, 0);
    }
  }

  &-disabled {
    cursor: no-drop;
    &:after,
    &:before {
      cursor: no-drop;
    }
  }

  .ot-Timeline-moving & {
    &:after {
      background-color: transparent;
    }
  }
}

.ot-GhostTimeline {
  @extend %common-timeline-styles;
  pointer-events: none;
  background: rgba(var(--rgb-background-color), 0.3);
  width: var(--width);
  left: var(--left);
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 4;
}

.ot-GhostTimeline_StartDate {
  @extend %date-styles;
  left: -9px;
  text-align: right;
  transform: translate3d(-100%, -50%, 0);
}

.ot-GhostTimeline_DueDate {
  @extend %date-styles;
  right: -9px;
  transform: translate3d(100%, -50%, 0);
}

.ot-WarningIcon {
  position: absolute;
  right: -5px;
  transform: translate3d(100%, 0, 0);
  cursor: initial;
}
/* eslint-enable vue-scoped-css/no-unused-selector */
</style>
