<template>
  <AppSelect
    v-model="localModelValue"
    :hidden-items="archivedIntervals"
    :loading="isLoading"
    :max-height="
      getMaxDropdownHeight({
        optionsToShow: 4
      })
    "
    :offset="[0, 0]"
    :options="options"
    item-label="name"
    item-value="id"
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    split-first-option
    theme="no-shadow-next light"
    type="modern"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import { INTERVAL_ENTITY_KEYS, OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { getArchivedIntervals } from '@/utils/interval'
import {
  BULK_FIELD_OPERATION_TYPE_IDS,
  createKeepAsIsOption,
  getMaxDropdownHeight,
  getOkrBulkFieldModelValueGetter,
  getOkrBulkFiledModelValueEmitPayload
} from '@/utils/okr-elements-table-bulk-actions'

import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'IntervalField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  }
})

const options = computed(() => {
  return [
    {
      ...createKeepAsIsOption({
        [OKR_BULK_UPDATE_ENTITY_KEYS.VALUE_KEY]: INTERVAL_ENTITY_KEYS.ID,
        [OKR_BULK_UPDATE_ENTITY_KEYS.LABEL_KEY]: INTERVAL_ENTITY_KEYS.NAME
      })
    },
    ...intervals.value.map(interval => ({
      ...interval,
      [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: BULK_FIELD_OPERATION_TYPE_IDS.REPLACE
    }))
  ]
})

const emit = defineEmits({
  'update:model-value': null
})

const localModelValue = computed({
  get: () => {
    return getOkrBulkFieldModelValueGetter({
      modelValue: props.modelValue
    })
  },
  set: newValue => {
    const payload = getOkrBulkFiledModelValueEmitPayload({
      newValue,
      options: options.value,
      valueKey: INTERVAL_ENTITY_KEYS.ID
    })

    emit('update:model-value', payload)
  }
})

const isLoading = ref(false)

const intervals = ref([])

const getIntervals = async () => {
  const api = new IntervalsInfoApiHandler()
  isLoading.value = true

  try {
    const { intervals: result } = await api.getIntervalsInfo({
      workspaceId: props.workspaceId
    })
    intervals.value = result
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const archivedIntervals = computed(() => {
  return getArchivedIntervals(intervals.value).map(item => item.id)
})

onMounted(() => {
  getIntervals()
})
</script>

<style lang="scss" scoped></style>
