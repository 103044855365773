<template>
  <div class="wu-Wrapper" data-testid="workspace-users-tab">
    <div class="wu-Toolbar">
      <div class="wu-Filters">
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(formModel, GROUP_IDS)"
          :dropdown-min-width="300"
          :loading="loaders.groups"
          :model-value="formModel[GROUP_IDS]"
          :options="groups"
          :search-function="
            searchString =>
              fetchGroupsForFilter({
                workspaceIds: [filterParams[WORKSPACE_ID]],
                searchString
              })
          "
          class="wu-FilterSelect"
          data-testid="group-filter-select"
          has-only-this-button
          n-selected-label="filter.groups"
          prepend-icon="team-next"
          @update:model-value="changeSelectedValue($event, GROUP_IDS)"
          @update:options="groups = $event"
        >
          <template #option-label="{ option }">
            <GlobalGroupsSelectOption v-if="option" :group="option" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, GROUP_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
        <!--
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(formModel, ACCOUNT_IDS)"
          :dropdown-min-width="200"
          :loading="loaders.accounts"
          :model-value="formModel[ACCOUNT_IDS]"
          :options="accounts"
          :search-function="searchUsers"
          class="wu-FilterSelect"
          data-testid="user-filter-select"
          has-only-this-button
          item-value="accountId"
          n-selected-label="common.users"
          prepend-icon="user-next"
          @update:model-value="changeSelectedValue($event, ACCOUNT_IDS)"
          @update:options="accounts = $event"
        >
          <template #button-content="{ label }">
            <AppSelectItem
              v-if="label"
              v-tippy="{
                ...TOOLTIP_CONFIGURATION,
                content: label
              }"
              :show-delete-button="false"
              :show-selected-count-circle="false"
              type="secondary-next"
            >
              {{ label }}
            </AppSelectItem>
          </template>
          <template #option-label="{ option }">
            <OwnerFieldOption :option="option" label-key="name" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, ACCOUNT_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
        -->
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(formModel, ROLE_IDS)"
          :dropdown-min-width="200"
          :loading="loaders.roles"
          :model-value="formModel[ROLE_IDS]"
          :options="roles"
          :search="false"
          class="wu-FilterSelect"
          data-testid="role-filter-select"
          has-only-this-button
          item-label="label"
          item-value="value"
          n-selected-label="users.roles.title"
          prepend-icon="active"
          @update:model-value="changeSelectedValue($event, ROLE_IDS)"
        >
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, ROLE_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
        <AppSearch
          v-model="formModel[REQUEST_ENTITY_KEYS.SEARCH_STRING]"
          :loading="loaders.accounts"
          :placeholder="t('placeholder.search_user')"
          :width="160"
          data-auto-testid="users-search"
          data-testid="users-search"
        />

        <ResetFilterButton v-if="showResetFilters" @click="onResetFilters" />
      </div>
      <AppButton
        data-testid="add-user-toolbar-button"
        icon="plus-next"
        type="primary-next"
        @click="onAddUserClick"
      >
        {{ t('users.create') }}
      </AppButton>
    </div>

    <div class="wu-Wrapper_Table">
      <AppTable
        :columns="tableColumns"
        :data="tableUsersData"
        :disable-user-select="false"
        :hover-row="tableHoverRow"
        :loading="loaders.table"
        border-on-last-row
        data-testid="users-table-wrapper"
        no-border
        offset-left="40px"
        offset-right="40px"
        sticky-header
        type="primary-next"
      >
        <template #loading>
          <div class="wu-InnerTableContainer">
            <WorkspaceUsersTableLoader />
          </div>
        </template>
        <template #header-cell="{ column }">
          <template v-if="column.key === TABLE_COLUMNS_KEYS.CHECKBOX">
            <AppCheckbox
              v-model="selectAll"
              data-testid="select-all-checkbox"
              size="sm"
              type="default-next"
              @update:model-value="onToggleAllUsers"
            />
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.CHECKBOX">
            <AppCheckboxListItem
              :data-testid="`select-user-${index}`"
              :model-value="selectedUsers"
              :val="item.accountId"
              type="default-next"
              @update:model-value="onChangeCheckboxListItem"
            />
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
            <UserNameCell :item="item" item-label="name" name-as-link />
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.SYNC">
            <div v-if="item.syncedInAnyGroup" class="wu-SyncCell">
              <AppIcon height="24" icon-name="replace_jira" width="24" />
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.GROUPS">
            <div class="wu-GroupsCell">
              <LimitTagList
                v-if="!isEmpty(item.groups)"
                :items="item.groups"
                :limit="10"
                style="--list-gap: 4px; --count-offset: 0"
                tooltip-value="name"
              >
                <template #item="{ item: group }">
                  <GroupIcon :color="group.color" :icon-name="group.icon" class="wu-GroupIcon" />
                </template>
              </LimitTagList>
              <AppButton
                :class="{ 'wu-GroupsCell_AddGroup-withGroups': !isEmpty(item.groups) }"
                class="wu-GroupsCell_AddGroup"
                height="24"
                icon="plus-next"
                remove-padding
                size="sm"
                type="tertiary-next"
                width="24"
                @click="onAddUserToGroupClick(item)"
              />
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.ROLES_IN_THE_WORKSPACES">
            <UserRoleCell
              :account-id="item.accountId"
              :dropdown-width="`${ROLES_CELL_WIDTH}px`"
              :role-id="item.roleId"
              :roles="roles"
              :tracking-source="EVENT_SOURCES.WS_USERS_TAB"
              :user-workspaces-data="createUserWorkspacesData(item)"
              :workspace-id="workspaceData.id"
              append-to=".wu-Wrapper_Table"
              @update-role-id="onUpdateRole(item.accountId, $event)"
            />
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
            <DropdownMenu
              :items="menuItemActions(item)"
              :offset="[0, 0]"
              position="bottom-end"
              type="default-next"
              @close="tableHoverRow = -1"
              @open="tableHoverRow = index"
              @item-click="onMenuActionsClick($event, item)"
            >
              <template #activator="{ isOpened }">
                <AppButton
                  :class="{ 'wu-ActionsButton-active': isOpened }"
                  class="wu-ActionsButton"
                  icon="more-next"
                  size="sm"
                  type="tertiary-next"
                />
              </template>
            </DropdownMenu>
          </template>
        </template>
        <template #footer>
          <NoSearchResults
            v-if="!tableUsersData.length && !loaders.table"
            data-testid="users-empty-state"
            offset-left="40px"
            offset-right="40px"
          >
            {{ t(isSearchUsed ? 'global_groups.no_users_found' : 'users.no_matching_filters') }}
          </NoSearchResults>
          <AppTableCreateButton
            v-if="!selectedUsers.length"
            data-testid="add-user-table-button"
            full-width
            icon-name="plus-next"
            @click="onAddUserClick"
          >
            {{ t('users.create') }}
          </AppTableCreateButton>
          <div class="wu-InnerTableContainer">
            <AppPagination
              v-if="tableUsersData.length"
              :current-page="currentPage"
              :items-count="totalUsers"
              :items-on-page="itemsOnPage"
              :items-shown="tableUsersData.length"
              :total-page="totalPage"
              class="wu-Pagination"
              items-name="pagination.owners"
              @update:items-on-page="changeItemsOnPage"
              @update:current-page="changeCurrentPage"
            />
          </div>
          <AppSnackbar
            :actions="snackbarActions"
            :count="selectedUsers.length"
            data-testid="snackbar"
            inline
            sticky
            style="
              --padding-left: var(--page-left-padding-next);
              --padding-right: var(--page-right-padding-next);
              --text-color: var(--dark-2);
            "
            @close="clearCheckboxes"
            @action-click="onSnackbarActionClick"
          />
        </template>
      </AppTable>
    </div>

    <UserDeleteModal
      :show="showUserDeleteFromWsModal"
      :title="t('delete_user.title', { userName: deleteUserConfig.name })"
      @on-close="closeUserDeleteWsModal"
      @on-confirm="onDeleteUserFromWs(deleteUserConfig.accountId)"
    >
      {{ t('confirm.user.message', selectedUsers.length) }}
    </UserDeleteModal>
    <AddUserToWorkspaceWithGroupModal
      v-model:show="isShowAddUsersModal"
      :workspace-id="workspaceData.id"
      data-testid="add-user-modal"
      @reload-data="reloadTableData"
    />
    <AddUserToGroupModal
      v-model:show="isShowAddUserToGroupModal"
      :tracking-source="EVENT_SOURCES.WS_USERS_TAB"
      :user="userData"
      :workspace-ids="[workspaceData.id]"
      @reload-data="reloadTableData"
    />
    <ChangeUserRoleModal
      v-model:show="isShowChangeRoleModal"
      :roles="roles"
      :selected-users="selectedUsers"
      :workspace-id="workspaceData.id"
      @reload-data="reloadTableData"
    />
    <RemoveUserFromGroupModal
      v-model:show="showUserDeleteFromGroupModal"
      :tracking-source="EVENT_SOURCES.WS_USERS_TAB"
      :user="deleteUserConfig"
      @reload-data="reloadTableData"
    />
  </div>
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import UserApiHandler from '@/api/user-details'
import {
  clearSelection,
  getBottomFixedItemsClearSelection,
  isClearSelectionAction
} from '@/composables/bottom-fixed-items'
import { useGlobalGroups } from '@/composables/global-groups'
import { useGetPaginationData } from '@/composables/pagination'
import { useFetchRoles, useGetSelectedUsers } from '@/composables/plugin-users'
import { useFetchWorkspaceUsers } from '@/composables/workspace-users'
import {
  EVENT_SECTIONS,
  EVENT_SOURCES,
  FILTER_LABELS_FOR_TRACKING,
  MODE_NAMES_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackApplySettingsPagesFilterEvent,
  trackOpenAddUserModalEvent,
  trackRemoveUserEvent
} from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { showNotify } from '@/utils/notify'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import { getNewSelectWithSelectAllValue } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppCheckboxListItem from '@/components/form/AppCheckboxListItem'
import LimitTagList from '@/components/form/LimitTagList'
// import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupIcon from '@/components/global-groups/GroupIcon'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppPagination from '@/components/ui/AppPagination/AppPagination'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import ResetFilterButton from '@/components/ui/ResetFilterButton'
import AppSearch from '@/components/ui/Search/Search'
import WorkspaceUsersTableLoader from '@/components/ui/SkeletonLoaders/WorkspaceUsersTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import UserDeleteModal from '@/components/workspaces/UserDeleteModal'
import AddUserToGroupModal from '@/views/workspaces/settings/plugin-users/AddUserToGroupModal'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'
import UserRoleCell from '@/views/workspaces/settings/plugin-users/UserRoleCell'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'
import AddUserToWorkspaceWithGroupModal from '@/views/workspaces/settings/workspace-users/AddUserToWorkspaceWithGroupModal'
import ChangeUserRoleModal from '@/views/workspaces/settings/workspace-users/ChangeUserRoleModal'
import RemoveUserFromGroupModal from '@/views/workspaces/settings/workspace-users/RemoveUserFromGroupModal'

defineOptions({
  name: 'WorkspaceUsers'
})

const { t } = useI18n()

const props = defineProps({
  workspaceData: {
    type: Object,
    required: true
  }
})
const tableHoverRow = ref(-1)

const WORKSPACE_ID = 'workspaceId'
const { GROUP_IDS, ACCOUNT_IDS, ROLE_IDS } = USERS_QUERY_KEYS

const DEFAULT_FORM_MODEL = {
  [GROUP_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [ACCOUNT_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [ROLE_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [REQUEST_ENTITY_KEYS.SEARCH_STRING]: ''
}
const formModel = ref(cloneDeep(DEFAULT_FORM_MODEL))
const loaders = ref({
  table: false,
  groups: false,
  accounts: false,
  roles: false
})

const isSearchUsed = computed(
  () => !isStringEmpty(formModel.value[REQUEST_ENTITY_KEYS.SEARCH_STRING])
)

const showResetFilters = computed(() => {
  return !isEqual(formModel.value, DEFAULT_FORM_MODEL)
})

const onResetFilters = () => {
  formModel.value = cloneDeep(DEFAULT_FORM_MODEL)
}

const bottomFixedItemsHandle = (action, key) => {
  if (isClearSelectionAction(action)) {
    clearSelection(formModel.value, key)
  }
}

const groups = ref([
  {
    name: t('filter.all_groups'),
    id: 0
  }
])

// const accounts = ref([
//   {
//     name: t('filter.all_users'),
//     accountId: 0
//   }
// ])
const roles = ref([])

const filterParams = computed(() => {
  return {
    [WORKSPACE_ID]: props.workspaceData.id,
    [GROUP_IDS]: formModel.value[GROUP_IDS],
    [ACCOUNT_IDS]: formModel.value[ACCOUNT_IDS],
    [ROLE_IDS]: formModel.value[ROLE_IDS],
    [REQUEST_ENTITY_KEYS.SEARCH_STRING]: formModel.value[REQUEST_ENTITY_KEYS.SEARCH_STRING]
  }
})

const tableUsersData = ref([])
watch(
  () => formModel.value,
  async () => {
    currentPage.value = START_PAGE
    await reloadTableData()
  },
  { deep: true }
)
const changeSelectedValue = (newValue, key) => {
  formModel.value[key] = getNewSelectWithSelectAllValue(newValue, formModel.value[key])

  trackApplySettingsPagesFilterEvent({
    section: EVENT_SECTIONS.WORKSPACE,
    tab: TAB_NAMES_FOR_TRACKING.USERS,
    label: FILTER_LABELS_FOR_TRACKING[key]
  })
}

const { selectedUsers, selectAll, clearCheckboxes, onToggleAllUsers, onChangeCheckboxListItem } =
  useGetSelectedUsers(tableUsersData)

const reloadTableData = async (clearSelection = true) => {
  if (clearSelection) {
    clearCheckboxes()
  }
  try {
    loaders.value.table = true
    const { users, total } = await useFetchWorkspaceUsers(filterParams.value, {
      currentPage: currentPage.value,
      itemsOnPage: itemsOnPage.value
    })
    tableUsersData.value = users
    totalUsers.value = total
  } finally {
    loaders.value.table = false
  }
}

const { fetchGroupsForFilter } = useGlobalGroups()

// const searchUsers = async (searchString = null) => {
//   const { users } = await useFetchWorkspaceUsers({ ...filterParams.value, searchString })
//   return users || []
// }

const getInitData = async () => {
  roles.value = await useFetchRoles()
  loaders.value = {
    groups: true,
    accounts: true,
    table: true,
    roles: true
  }
  try {
    const [
      groupsData
      // accountsData
    ] = await Promise.all([
      fetchGroupsForFilter({
        workspaceIds: [filterParams.value[WORKSPACE_ID]]
      })
      // useFetchWorkspaceUsers({ ...filterParams.value })
    ])
    groups.value = [...groups.value, ...groupsData]
    // accounts.value = [...accounts.value, ...accountsData.users]
    await reloadTableData()
  } finally {
    loaders.value = {
      groups: false,
      accounts: false,
      table: false,
      roles: false
    }
  }
}
onMounted(() => {
  getInitData()
})

const {
  START_PAGE,
  currentPage,
  itemsOnPage,
  total: totalUsers,
  totalPage
} = useGetPaginationData()

const changeCurrentPage = page => {
  currentPage.value = page
  reloadTableData()
}
const changeItemsOnPage = value => {
  itemsOnPage.value = value
  currentPage.value = START_PAGE
  reloadTableData()
}

const { CHECKBOX, NAME, ROLES_IN_THE_WORKSPACES, GROUPS } = TABLE_COLUMNS_KEYS

const ROLES_CELL_WIDTH = 152

const tableColumns = [
  {
    key: CHECKBOX,
    width: 28
  },
  {
    title: t('users.table_header_name'),
    key: NAME,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.SYNC,
    width: 32
  },
  {
    title: t('field.groups.title'),
    key: GROUPS,
    width: 346
  },
  {
    title: t('workspaces.role'),
    key: ROLES_IN_THE_WORKSPACES,
    width: ROLES_CELL_WIDTH
  },

  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const menuItemActions = item => {
  const result = []

  if (isEmpty(item.groups)) {
    result.push({
      name: ACTIONS_KEYS.REMOVE,
      title: 'action.remove_from_ws',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: MENU_ITEMS_GROUPS.REMOVING,
      disabled: !item.canBeDeleted,
      tooltipContent: item.canBeDeleted
        ? null
        : t('user_details.delete_user_from_workspace', {
            workspaceName: item.name,
            platform: t('app.platform.jira')
          })
    })
  } else {
    result.push({
      name: ACTIONS_KEYS.REMOVE,
      title: 'action.remove_from_group',
      icon: 'delete-next',
      color: 'var(--grade-low-color-next)',
      group: MENU_ITEMS_GROUPS.REMOVING
    })
  }
  return result
}

const onMenuActionsClick = (name, item) => {
  if (name === ACTIONS_KEYS.REMOVE) {
    if (!isEmpty(item.groups)) {
      onRemoveFromGroup(item)
    } else {
      onRemoveFromWorkspaceClick(item)
    }
  }
}

const onRemoveFromGroup = item => {
  deleteUserConfig.value = item
  showUserDeleteFromGroupModal.value = true
}

const onRemoveFromWorkspaceClick = item => {
  deleteUserConfig.value = item
  showUserDeleteFromWsModal.value = true
}

const showUserDeleteFromWsModal = ref(false)
const showUserDeleteFromGroupModal = ref(false)
const deleteUserConfig = ref({})
const closeUserDeleteModal = () => {
  showUserDeleteFromGroupModal.value = false
  deleteUserConfig.value = {}
}

const closeUserDeleteWsModal = () => {
  showUserDeleteFromWsModal.value = false
  deleteUserConfig.value = {}
}

const isLastPage = computed(() => currentPage.value === totalPage.value)

const onDeleteUserFromWs = async accountId => {
  try {
    const userApi = new UserApiHandler()

    await userApi.removeUserFromWorkspace({
      workspaceId: props.workspaceData.id,
      accountId
    })

    showNotify({ title: t('notifications.user_deleted') })

    trackRemoveUserEvent({
      mode: MODE_NAMES_FOR_TRACKING.WORKSPACE,
      source: EVENT_SOURCES.WS_USERS_TAB
    })

    if (isLastPage.value) {
      currentPage.value = START_PAGE
    }
    showUserDeleteFromWsModal.value = false
    clearCheckboxes()
    closeUserDeleteModal()
    await reloadTableData()
  } catch (error) {
    handleError({ error })
  }
}

const snackbarActions = computed(() => {
  return [
    {
      name: ACTIONS_KEYS.CHANGE_ROLE,
      icon: 'user-next',
      title: 'users.change_role'
    }
  ]
})
const onSnackbarActionClick = name => {
  if (name === ACTIONS_KEYS.CHANGE_ROLE) {
    isShowChangeRoleModal.value = true
  }
}

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const isShowAddUsersModal = ref(false)
const isShowChangeRoleModal = ref(false)
const onAddUserClick = () => {
  isShowAddUsersModal.value = true

  trackOpenAddUserModalEvent({
    source: EVENT_SOURCES.WS_USERS_TAB,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
    tab: TAB_NAMES_FOR_TRACKING.SINGLE,
    role: userRoleForTracking.value
  })
}

const createUserWorkspacesData = item => {
  return [
    {
      workspaceId: props.workspaceData.id,
      defaultGroupId: item.defaultGroupId
    }
  ]
}

const onUpdateRole = (accountId, roleId) => {
  const userToUpdate = tableUsersData.value.find(user => user.accountId === accountId)

  if (userToUpdate) {
    userToUpdate.roleId = roleId
  }
}

const userData = ref(null)
const isShowAddUserToGroupModal = ref(false)

const onAddUserToGroupClick = payload => {
  userData.value = payload
  isShowAddUserToGroupModal.value = true
  trackOpenAddUserModalEvent({
    role: userRoleForTracking.value,
    source: EVENT_SOURCES.WS_USERS_TAB,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
    tab: TAB_NAMES_FOR_TRACKING.SINGLE
  })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/workspace-configuration-modal';
@import '~@/assets/styles/app-table-helpers';
@import '@/assets/styles/_mixins';

.wu-Wrapper {
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
  --head-padding-top: #{$modal-tab-table-padding-top};
  --sticky-top: var(--wcm-tab-table-sticky-top, 0);
}
.wu-Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @extend %tab-toolbar-styles;
}
.wu-Filters {
  display: flex;
  align-items: center;
  gap: 8px;
}
.wu-FilterSelect {
  max-width: 158px;
}
.wu-ActionsButton {
  &-active {
    background-color: $dark-2;
    color: $white;
  }
  @extend %app-table-hidden-items;
}
.wu-InnerTableContainer {
  max-width: $page-width-md-next;
  margin-left: 40px;
  margin-right: 40px;
}
.wu-SyncCell {
  display: flex;
  align-items: center;
}
.wu-Wrapper_Table {
  padding: 0 40px;
}
.wu-Pagination {
  margin: 16px 0;
}

.wu-GroupsCell {
  display: flex;
  padding-right: 8px;
  gap: 4px;
}

.wu-GroupsCell_AddGroup {
  color: $primary-color-next;
  &-withGroups {
    @extend %app-table-hidden-items;
  }
}
</style>
