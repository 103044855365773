<template>
  <Modal
    :class="{ 'wcm-Modal-child-modal-opened': childModalOpened }"
    :scrollable-wrapper="true"
    :show="opened"
    class="wcm-Modal"
    data-auto-testid="manage-workspace-modal"
    manual-close
    size="lg-next"
    sticky-header
    @close="onClose"
  >
    <template #header>
      <div class="wcm-IconAndName">
        <WorkspaceIcon
          :option="workspaceIconData"
          :with-lock-icon="false"
          class="wcm-IconAndName_Icon"
          @click="openSettings"
        />
        <TitleWithAction
          v-tippy="{
            ...TOOLTIP_CONFIGURATION,
            content: workspaceData.name
          }"
          class="oboard-truncated-text"
        >
          <template #default>
            {{ workspaceData.name }}
          </template>
        </TitleWithAction>
      </div>
    </template>
    <template #before-close>
      <div class="wcm-BeforeClose">
        <SavingIndicator ref="savingIndicator" />

        <DropdownMenu
          :items="MENU_ACTIONS"
          :offset="[0, 0]"
          preferred-position="bottom-end"
          type="default-next"
          @item-click="onMenuActionsClick($event)"
        >
          <template #activator>
            <AppButton icon="more-next" size="sm" type="subtle" />
          </template>
        </DropdownMenu>
      </div>
    </template>
    <template #loader>
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </template>
    <template #modal-body>
      <div class="wcm-ModalBody">
        <ConfigurationBody
          ref="configurationBodyReference"
          v-model:are-data-changed="areDataChanged"
          v-model:ws-key="wsKey"
          :active-tab="modalOpenData.activeTab"
          :is-saving="isSaving"
          :workspace-data="workspaceData"
          @child-modal-opened="childModalOpened = true"
          @child-modal-closed="childModalOpened = false"
          @saving-started="onSavingStarted"
          @saving-finished="onSavingFinished"
          @restore-default-settings="restoreDefaultSettings"
        />
      </div>
    </template>
  </Modal>

  <portal to="modal-windows">
    <AppDialog
      :show="isConfirmCloseShow"
      :title="$t('confirm_modal.title')"
      :type="DIALOG_TYPES.WARNING"
      @on-close="hideConfirmCloseModal"
      @on-confirm="onConfirmClose"
    >
      {{ $t('confirm_modal.description') }}

      <template #confirm-btn-text>
        {{ $t('confirm.discard_btn') }}
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { has, isNumber, isString } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { WS_CONFIGURATION_TABS } from '@/utils/components-configurations/workspace-configuration-modal'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { TOOLTIP_CONFIGURATION } from '@/utils/tippy-config'

import AppDialog from '@/components/AppDialog'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import Modal from '@/components/ui/Modal/Modal'
import ConfigurationBody from '@/components/workspaces/configuration-modal/ConfigurationBody'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

defineOptions({
  name: 'WorkspaceConfigurationModal'
})

const props = defineProps({
  opened: {
    type: Boolean
  },

  modalOpenData: {
    type: Object,
    required: true,
    validator: v => {
      const WORKSPACE_ID = 'workspaceId'
      const ACTIVE_TAB = 'activeTab'

      const isIdValid =
        has(v, WORKSPACE_ID) && (isNumber(v[WORKSPACE_ID]) || isString(v[WORKSPACE_ID]))
      const isActiveTabValid =
        has(v, ACTIVE_TAB) && Object.values(WS_CONFIGURATION_TABS).includes(v[ACTIVE_TAB])

      const isValid = isIdValid && isActiveTabValid
      if (!isValid) {
        throw new Error(`${WORKSPACE_ID} key or ${ACTIVE_TAB} key is not valid`)
      }
      return isValid
    }
  }
})

const store = useStore()

const workspaceData = computed(() => {
  return store.getters['workspaces/getWorkspaceById'](props.modalOpenData.workspaceId)
})

const wsKey = ref('')

const emit = defineEmits({ 'on-close': null, 'on-menu-action-click': null })

const childModalOpened = ref(false)

watch(
  workspaceData,
  newValue => {
    if (newValue?.key) {
      wsKey.value = newValue.key
    }
  },
  {
    immediate: true,
    deep: true
  }
)

const workspaceIconData = computed(() => {
  return {
    ...workspaceData.value,
    key: wsKey.value
  }
})

const closeModalOnSavingFinished = ref(false)
const areDataChanged = ref(false)
const isConfirmCloseShow = ref(false)

const hideConfirmCloseModal = () => {
  isConfirmCloseShow.value = false
}

const onConfirmClose = () => {
  hideConfirmCloseModal()
  checkConfirmationAndCloseModal({ checkDataChange: false })
}

const checkConfirmationAndCloseModal = ({ checkDataChange = true } = {}) => {
  if (checkDataChange !== false && areDataChanged.value) {
    isConfirmCloseShow.value = true
  } else {
    emit('on-close')
  }
}
const onClose = () => {
  if (isSaving.value) {
    closeModalOnSavingFinished.value = true
  } else {
    checkConfirmationAndCloseModal()
  }
}

const isSaving = ref(false)
const savingIndicator = ref(null)
const savingIndicatorTop = ref(null)
const onSavingStarted = () => {
  isSaving.value = true
  savingIndicator.value.startSaving()
  savingIndicatorTop.value.startSaving()
}

const onSavingFinished = () => {
  isSaving.value = false
  savingIndicator.value.endSaving()
  savingIndicatorTop.value.endSaving()
  if (closeModalOnSavingFinished.value) {
    onClose()
  }
}

const MENU_ACTIONS = [
  {
    name: ACTIONS_KEYS.EXPORT,
    title: 'dropdown.export_csv',
    icon: 'file-blank',
    group: MENU_ITEMS_GROUPS.ACTIONS
  },
  {
    name: ACTIONS_KEYS.REMOVE,
    title: 'action.remove',
    icon: 'delete-next',
    color: 'var(--grade-low-color-next)',
    group: MENU_ITEMS_GROUPS.REMOVING
  }
]

const onMenuActionsClick = name => {
  emit('on-menu-action-click', name, workspaceData.value)
}

const configurationBodyReference = ref(null)

const openSettings = () => {
  if (configurationBodyReference.value) {
    configurationBodyReference.value.openSettings()
  }
}

const restoreDefaultSettings = () => {
  if (areDataChanged.value) {
    areDataChanged.value = false
  }

  if (wsKey.value !== workspaceData.value.key) {
    wsKey.value = workspaceData.value.key
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/workspace-configuration-modal';
@import '~@/assets/styles/okr-modal';

.wcm-Modal {
  @extend %wcm-variables;
  --modal-header-sticky-top: #{$header-sticky-top};

  --depth: 0;

  :deep(.o-modal-content) {
    height: auto;
    transition: $transition-fast;
  }

  &-child-modal-opened {
    :deep(.o-modal-content) {
      $shift: 120px;
      $rest-shift: calc(#{$shift} - #{$depth-shift});
      width: calc(#{$lg-next-modal-width} + #{$depth-shift} + #{$rest-shift});
      padding-right: $rest-shift;
    }
  }
}

.wcm-IconAndName {
  display: flex;
  align-items: center;
  gap: 18px;
  overflow: hidden;
}

.wcm-IconAndName_Icon {
  cursor: pointer;
}

.wcm-ModalBody {
  display: flex;
  gap: 32px;
  flex-direction: column;

  // height: calc(100% - 74px); // 74px is a height of modal header
}

.wcm-BeforeClose {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>

<style lang="scss">
@import '~@/assets/styles/okr-modal';

// .wcm-Modal {
//   &-child-modal-opened {
//     & > .o-modal-content-wrapper > .o-modal-content {
//       $shift: 120px;
//       $rest-shift: calc(#{$shift} - #{$depth-shift});
//       width: calc(#{$lg-next-modal-width} + #{$depth-shift} + #{$rest-shift});
//       padding-right: $rest-shift;
//     }
//   }
// }
</style>
