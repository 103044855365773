<template>
  <AppSelect
    :dropdown-min-width="200"
    :group-by="item => item.category"
    :groups="groups"
    :icon="userSelected ? 'user-next' : 'team-next'"
    :loading="loading"
    :max-height="maxHeight"
    :model-value="assigneeId"
    :offset="[0, 0]"
    :options="assignees"
    :search-function="searchAssignee"
    :search-max-length="50"
    append-icon-height="20"
    append-icon-width="20"
    class="chso-Select"
    dropdown-search
    has-fixed-parent
    is-highlighted
    item-label="name"
    item-value="id"
    n-selected-label="filter.owners"
    no-left-padding
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    theme="no-shadow-next light"
    type="secondary-next"
    @update:options="onAssigneesUpdate"
    @update:model-value="selectListFilterValue"
  >
    <template #option-label="{ option }">
      <OwnerFieldOption
        v-if="option.accountId"
        :option="option"
        label-key="name"
        no-avatar-icon-name="team-next"
      />
      <GlobalGroupsSelectOption v-else :group="option" />
    </template>

    <template #button-content="{ option }">
      <AppSelectItem
        v-if="option"
        :show-delete-button="false"
        :show-selected-count-circle="false"
        type="secondary-next"
      >
        {{ option.name }}
      </AppSelectItem>
    </template>
  </AppSelect>
</template>
<script setup>
import { isEmpty } from 'lodash'
import { ref, computed, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUsersAndGroups } from '@/composables/fetchingData'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'

const CATEGORIES = {
  USERS: 'users',
  GROUPS: 'groups'
}

const props = defineProps({
  workspaceId: {
    type: Number,
    default: null
  },
  assigneeId: {
    type: Number,
    default: null
  },
  maxHeight: {
    type: [String, Number],
    default: 250
  },
  category: {
    type: String,
    default: null
  }
})

const emit = defineEmits({
  'update:assigneeId': null,
  'update:category': null,
  'update:options': null,
  'update:userName': null
})
const assignees = ref([])
const loading = ref(false)

const getSelectedUserOrGroupCategory = id => {
  const idForChek = id || props.assigneeId
  return assignees.value.find(item => item.id === idForChek)?.category || props.category
}

const userSelected = computed(() => {
  return (
    !isEmpty(props.assigneeId) &&
    (getSelectedUserOrGroupCategory(props.assigneeId) === CATEGORIES.USERS ||
      props.category === CATEGORIES.USERS)
  )
})

const fetchAssignees = async (payload = {}) => {
  try {
    loading.value = true

    const preparedData = userSelected.value
      ? { pickedAccountId: props.assigneeId }
      : { pickedGroupId: props.assigneeId }

    const data = isEmpty(payload) ? preparedData : payload

    const { options } = await useUsersAndGroups(null, data, props.workspaceId)
    assignees.value = options.value
    if (!props.assigneeId) {
      await selectListFilterValue(options.value[0].id)
    } else {
      const getAssigneeId = options.value.find(item => item.accountId === props.assigneeId)
        ? props.assigneeId
        : options.value[0].id
      await selectListFilterValue(getAssigneeId)
    }
    emit('update:options')
  } finally {
    loading.value = false
  }
}
watch(
  () => props.workspaceId,
  () => {
    fetchAssignees()
  },
  { immediate: true }
)
const selectListFilterValue = async newValue => {
  await nextTick()
  emit('update:assigneeId', newValue)
  updateUserData(newValue)
}
const updateUserData = assigneeId => {
  // await nextTick()
  const { category, name } =
    assignees.value.find(assignee => assignee.id === assigneeId) || assignees.value[0]
  emit('update:category', category)
  emit('update:userName', name)
}

const searchAssignee = async searchString => {
  const { options } = await useUsersAndGroups(searchString, {}, props.workspaceId)
  return options
}

const onAssigneesUpdate = newValue => {
  // filter added in computed 'All selected' option
  assignees.value = newValue.filter(assignee => assignee.accountId !== 0)
}
const { t } = useI18n()
const groups = computed(() => {
  return {
    groups: {
      name: t('homepage.user_select.groups')
    },

    users: {
      name: t('homepage.user_select.users')
    }
  }
})

defineExpose({
  fetchAssignees
})
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfluenceHomeScreenOwners'
})
</script>

<style lang="scss" scoped>
.chso-Select {
  max-width: 220px;
}
</style>
