<template>
  <div class="gal-Wrapper">
    <AppTable
      :columns="COLUMNS"
      :data="auditLogData"
      :disable-user-select="false"
      :loading="listState.isLoading"
      border-on-last-row
      class="gal-Table"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      sticky-header
      type="primary-next"
    >
      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.DATE">
          {{ formatDateForAuditLog(item.createDate) }}
        </template>

        <template v-if="columnKey === TABLE_COLUMNS_KEYS.USER_NAME">
          <div class="oboard-truncated-text gal-NameCell">{{ item.userName }}</div>
        </template>
      </template>

      <template #loading>
        <GroupsAuditLogLoader />
      </template>

      <template #footer>
        <InfiniteScrollLoaderNext
          v-if="!listState.isAllItemsLoaded"
          :loading="listState.isLoading"
          @load-more="onLoadMore"
        />
      </template>
    </AppTable>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { AUDIT_LOG_TYPE_IDS, formatDateForAuditLog, useAuditLogs } from '@/utils/audit-logs'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import GroupsAuditLogLoader from '@/components/ui/SkeletonLoaders/GroupsAuditLogLoader'
import AppTable from '@/components/ui/Table/AppTable'

defineOptions({
  name: 'GroupsAuditLogPage'
})

const { listState, auditLogData, fetchAuditLog } = useAuditLogs()

const getAuditLog = async () => {
  await fetchAuditLog({
    auditLogTypeId: AUDIT_LOG_TYPE_IDS.GROUPS
  })
}

const { t } = useI18n()

const onLoadMore = async () => {
  if (!listState.value.isLoading) {
    await getAuditLog()
  }
}

const COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.DATE,
    title: t('audit_log.date'),
    width: 220
  },
  {
    title: t('roles.user'),
    key: TABLE_COLUMNS_KEYS.USER_NAME,
    width: 150
  },
  {
    title: t('workspaces.action'),
    key: TABLE_COLUMNS_KEYS.TEXT,
    width: 'auto'
  }
]

onMounted(() => {
  getAuditLog()
})
</script>

<style lang="scss" scoped>
.gal-Wrapper {
  color: $dark-1;
  --head-padding-bottom: 8px;
  --head-padding-top: 8px;
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  margin-top: 20px;

  &:deep(.tb-Row) {
    padding: 12px 0;
    align-items: flex-start;
  }
}

.gal-NameCell {
  padding-right: 8px;
}
</style>
