<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmation"
    :confirm-close="areDataChanged"
    :show="show"
    :title="getModalTitle"
    hide-hero
    @on-close="onWorkspaceModalClose"
  >
    <div class="wm-Fields">
      <FormFieldNext :label="$t('workspace.label.name')">
        <AppInput
          ref="name"
          v-model.trim="formModel[WORKSPACE_ENTITY_KEYS.NAME]"
          :is-error="showNameErrors"
          max-length="30"
          size="xlg"
          style-type="primary"
          @update:model-value="validateName"
        />

        <AppFieldError v-if="showNameErrors" :show="showNameErrors" class="wm-FieldError">
          <span v-show="isNameEmpty || isNameLengthNotValid">
            {{ $t(isNameEmpty ? 'field.required' : 'field.workspace_name') }}
          </span>
          <template v-if="formErrors !== null && formErrors[WORKSPACE_ENTITY_KEYS.NAME]">
            <span v-for="error in formErrors[WORKSPACE_ENTITY_KEYS.NAME]" :key="error">
              {{ error }}
            </span>
          </template>
        </AppFieldError>
      </FormFieldNext>

      <FormFieldNext :label="$t('workspace.label.description')">
        <AppTextarea
          v-model="formModel[WORKSPACE_ENTITY_KEYS.DESCRIPTION]"
          :max-length="DESCRIPTION_MAX_LENGTH"
          :placeholder="$t('workspace.label.add_description')"
          :rows="1"
          style="--input-padding: 8px"
        />
      </FormFieldNext>

      <div>
        <AppIconCreator class="wm-IconCreator">
          <template #first-term>
            <FormFieldNext :label="$t('workspace.label.key')">
              <AppInput
                v-model.trim.uppercase="formModel[WORKSPACE_ENTITY_KEYS.KEY]"
                :is-error="showKeyErrors"
                class="wm-Key"
                max-length="3"
                size="xlg"
                style-type="primary"
                @update:model-value="onUpdateInput"
              >
                <template #append-after>
                  <TooltipButtonInfo
                    class="wm-TooltipButton"
                    content="workspaces.key.tooltip"
                    placement="top"
                  />
                </template>
              </AppInput>
            </FormFieldNext>
          </template>

          <template #second-term>
            <AppPaletteColorPicker
              v-model:selected-color="formModel[WORKSPACE_ENTITY_KEYS.COLOR]"
              :palette="WORKSPACE_CATEGORIZED_COLORS"
            />
          </template>

          <template #result>
            <WorkspaceIcon :option="formModel" :with-lock-icon="false" />
          </template>
        </AppIconCreator>

        <AppFieldError v-if="showKeyErrors" :show="showKeyErrors" class="wm-FieldError">
          <span v-show="isKeyLengthNotValid">
            {{ $t('field.workspace_key_length') }}
          </span>
          <template v-if="formErrors !== null && formErrors[WORKSPACE_ENTITY_KEYS.KEY]">
            <span v-for="error in formErrors[WORKSPACE_ENTITY_KEYS.KEY]" :key="error">
              {{ error }}
            </span>
          </template>
        </AppFieldError>
      </div>

      <FormFieldNext :label="$t('workspace.timezone')">
        <AppSelect
          v-if="show"
          v-model="formModel[WORKSPACE_ENTITY_KEYS.TIMEZONE]"
          :offset="[0, 0]"
          :options="timezonesOptions"
          :search-function="value => localSearch({ value, options: timezonesOptions })"
          class="vm-TimezoneSelect"
          dropdown-search
          item-label="label"
          item-value="value"
          show-all-selected
          theme="no-shadow-next light"
          type="default-next"
        />
      </FormFieldNext>
    </div>
    <template #footer>
      <div class="wm-Footer">
        <AppVisibilitySwitch v-if="!isEdit" v-model="formModel[WORKSPACE_ENTITY_KEYS.PUBLIC]" />

        <div class="wm-Footer_Actions">
          <AppButton type="ghost-next" @click="close">
            {{ $t('action.cancel') }}
          </AppButton>

          <AppButton :disable="loading" :loading="loading" type="primary-next" @click="save">
            {{ isEdit ? $t('action.update') : $t('action.confirm') }}
          </AppButton>
        </div>
      </div>
    </template>
  </AppModalWithConfirmation>
</template>

<script>
import { has, isEqual, isNull } from 'lodash'
import { defineComponent } from 'vue'

import WorkspacesApiHandler from '@/api/workspaces'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { WORKSPACE_CATEGORIZED_COLORS } from '@/utils/components-configurations/workspace-configuration-modal'
import { WORKSPACE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { DESCRIPTION_MAX_LENGTH } from '@/utils/global-groups'
import { localSearch } from '@/utils/objectives'
import { deleteQueryParameter } from '@/utils/router'
import { timezonesOptions } from '@/utils/timezones'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import TooltipButtonInfo from '@/components/TooltipButtonInfo'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTextarea from '@/components/ui/AppTextarea/AppTextarea'
import AppVisibilitySwitch from '@/components/workspaces/AppVisibilitySwitch'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

const workspacesApi = new WorkspacesApiHandler()

const DEFAULT_FORM_MODEL = {
  [WORKSPACE_ENTITY_KEYS.NAME]: '',
  [WORKSPACE_ENTITY_KEYS.DESCRIPTION]: '',
  [WORKSPACE_ENTITY_KEYS.KEY]: '',
  [WORKSPACE_ENTITY_KEYS.PUBLIC]: false,
  [WORKSPACE_ENTITY_KEYS.TIMEZONE]: 0, // UTC timezone
  [WORKSPACE_ENTITY_KEYS.COLOR]: WORKSPACE_CATEGORIZED_COLORS.BLUE[0]
}

const NAME_ERRORS_KEY = WORKSPACE_ENTITY_KEYS.NAME
const KEY_ERRORS_KEY = WORKSPACE_ENTITY_KEYS.KEY

export default defineComponent({
  name: 'WorkspaceCreateFastEditModal',

  components: {
    AppTextarea,
    WorkspaceIcon,
    AppPaletteColorPicker,
    AppIconCreator,
    AppVisibilitySwitch,
    FormFieldNext,
    AppModalWithConfirmation,
    AppButton,
    AppInput,
    AppFieldError,
    TooltipButtonInfo,
    AppSelect
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    modelValue: {
      type: Object,
      default: null
    }
  },

  emits: { 'update:show': null, 'on-created': null, 'on-updated': null },

  data() {
    return {
      timezonesOptions,
      formModel: { ...DEFAULT_FORM_MODEL },
      localFormModel: { ...DEFAULT_FORM_MODEL },
      isNameEmpty: false,
      isNameLengthNotValid: false,
      isKeyLengthNotValid: false,
      loading: false,
      formErrors: null
    }
  },

  computed: {
    DESCRIPTION_MAX_LENGTH: () => DESCRIPTION_MAX_LENGTH,
    WORKSPACE_ENTITY_KEYS: () => WORKSPACE_ENTITY_KEYS,

    WORKSPACE_CATEGORIZED_COLORS: () => WORKSPACE_CATEGORIZED_COLORS,

    isEdit() {
      return !isNull(this.modelValue)
    },

    areDataChanged() {
      return !isEqual(this.localFormModel, this.formModel)
    },

    getModalTitle() {
      return this.isEdit
        ? this.$t('workspaces.edit_workspace')
        : this.$t('workspaces.new_workspace')
    },

    showNameErrors() {
      return (
        this.isNameEmpty ||
        this.isNameLengthNotValid ||
        (!isNull(this.formErrors) && has(this.formErrors, NAME_ERRORS_KEY))
      )
    },

    showKeyErrors() {
      return (
        this.isKeyLengthNotValid ||
        (!isNull(this.formErrors) && has(this.formErrors, KEY_ERRORS_KEY))
      )
    }
  },

  watch: {
    show(bool) {
      if (!bool) {
        this.onWorkspaceModalClose()
        return
      }

      if (this.isEdit) {
        Object.keys(DEFAULT_FORM_MODEL).forEach(key => {
          this.formModel[key] = this.modelValue[key]
        })
      }

      this.localFormModel = { ...this.formModel }

      this.setFocusOnName()
    }
  },

  methods: {
    localSearch,
    onUpdateInput(key) {
      this.validateKey(key)
    },

    onWorkspaceModalClose() {
      this.formModel = { ...DEFAULT_FORM_MODEL }
      this.localFormModel = { ...DEFAULT_FORM_MODEL }
      this.isNameEmpty = false
      this.isNameLengthNotValid = false
      this.isKeyLengthNotValid = false
      this.formErrors = null

      deleteQueryParameter(this.$router, this.$route, 'editWorkspace')
      this.$emit('update:show', false)
    },

    validateName() {
      this.isNameEmpty = isStringEmpty(this.formModel[WORKSPACE_ENTITY_KEYS.NAME])
      this.isNameLengthNotValid = this.formModel[WORKSPACE_ENTITY_KEYS.NAME].length < 2
    },

    validateKey() {
      this.formErrors = null
      this.isKeyLengthNotValid =
        this.formModel[WORKSPACE_ENTITY_KEYS.KEY].length === 0 ||
        this.formModel[WORKSPACE_ENTITY_KEYS.KEY].length > 3
    },

    async save() {
      this.validateName()
      if (this.isNameEmpty || this.isNameLengthNotValid) {
        return
      }

      this.validateKey()
      if (this.isKeyLengthNotValid) {
        return
      }

      let isSuccessful = true
      this.loading = true

      let workspace = null

      const requestMethod = this.isEdit
        ? payload => workspacesApi.updateWorkspace(payload)
        : payload => workspacesApi.createWorkspace(payload)

      const payload = {
        [WORKSPACE_ENTITY_KEYS.ID]: this.isEdit
          ? this.modelValue[WORKSPACE_ENTITY_KEYS.ID]
          : undefined,

        ...this.formModel,
        isPublic: this.formModel[WORKSPACE_ENTITY_KEYS.PUBLIC]
      }

      try {
        const response = await requestMethod(payload)
        if (response.errors.length > 0) {
          isSuccessful = false
          this.formErrors = {}
          response.errors.forEach(error => {
            if (!(error.fieldName in this.formErrors)) {
              this.formErrors[error.fieldName] = []
            }
            this.formErrors[error.fieldName].push(error.error)
          })
        } else {
          workspace = response.workspace

          if (!this.isEdit) {
            tracker.logEvent('Workspace created', {
              category: EVENT_CATEGORIES.WORKSPACE_MANAGEMENT
            })

            gtmTracker.logEvent(EVENT_NAMES.WORKSPACE_CREATED)
          }
        }
      } catch (error) {
        isSuccessful = false
        handleError({ error })
      }

      this.loading = false
      if (isSuccessful) {
        const emitMethod = this.isEdit ? 'on-updated' : 'on-created'
        this.$emit(emitMethod, workspace)
      }
    },

    close() {
      this.$refs.appModalWithConfirmation.close()
    },

    setFocusOnName() {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.name.focus()
        }, 100)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.wm-Fields {
  display: grid;
  gap: 20px;
}

.wm-TooltipButton {
  margin-right: 6px;
}
.wm-FieldError {
  min-height: 20px;
  display: flex;
  align-items: center;
}

.wm-Footer {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  width: 100%;
}

.wm-Footer_Actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: inherit;
}

.wm-IconCreator {
  --select-width: 148px;
}
</style>

<style lang="scss">
.wm-Key .o-input-field {
  width: 100%;
}
</style>
