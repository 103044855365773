import ApiHandler from '@/api/base'

class OkrFiltersApiHandler extends ApiHandler {
  async createFilterViewForUser({ accountId, filters, name }) {
    const { data } = await this.http.post('/createFilterViewForUser', {
      accountId,
      filters,
      name
    })
    return data
  }

  async getFiltersForUser({ accountId, workspaceId }) {
    const { data } = await this.http.post('/getFiltersForUser', {
      accountId,
      workspaceId
    })
    return data
  }

  async deleteFilterView(filterViewId) {
    const { data } = await this.http.post('/deleteFilterView', {
      filterViewId
    })
    return data
  }

  async updateFilterViewForUser({ accountId, filters, name, filterViewId }) {
    const { data } = await this.http.post('/updateFilterViewForUser', {
      accountId,
      filters,
      name,
      filterViewId
    })
    return data
  }

  async setShareLink({ link }) {
    const { data } = await this.http.post('/short-link', {
      link
    })
    return data
  }

  async getShareLink({ token }) {
    const { data } = await this.http.get('/short-link', {
      params: {
        token
      }
    })
    return data
  }
}

export default OkrFiltersApiHandler
