<template>
  <OboardLogoLoader />
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import OkrFiltersApiHandler from '@/api/okr-filters'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { handleError } from '@/utils/error-handling'

import OboardLogoLoader from '@/components/ui/SkeletonLoaders/OboardLogoLoader'

defineOptions({
  name: 'ShareLinkHandler'
})

const store = useStore()
const route = useRoute()
const router = useRouter()

const workspaceId = computed(() => `${store.state.workspaces.workspaceId}`)

const goHome = () => {
  router.push({ name: ROUTE_NAMES.HOME_PAGE, params: { workspaceId: workspaceId.value } })
}

onMounted(() => {
  const token = route.params.token
  if (!token) {
    goHome()
  } else {
    getShareLink(token)
  }
})

const getShareLink = async token => {
  try {
    const api = new OkrFiltersApiHandler()
    const data = await api.getShareLink({ token })
    await router.push(data.link)
  } catch (error) {
    handleError({ error })
    goHome()
  }
}
</script>

<style lang="scss" scoped></style>
