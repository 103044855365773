<template>
  <CellSelectItemsList
    v-if="!isTask"
    :id="`sh${uid}`"
    :active="isOpened"
    :items="selectedStakeholders"
    :plug-icon="PLUG_ICONS.ASSIGNEE"
    :user-has-read-access="readable"
    :user-has-update-access="updatable"
    data-testid="stakeholders-cell-value"
    item-value="displayName"
    @click="editInitialised = true"
  />
  <template v-if="!isTask">
    <AppSelect
      v-if="editInitialised"
      :inline-loader="false"
      :loading="areAssigneesLoading"
      :model-value="selectedStakeholdersIds"
      :offset="[0, 0]"
      :options="assignees"
      :search-function="getAssigneesInfo"
      :to-selector="`#sh${uid}`"
      append-to=".o-objective-table"
      boundary="scrollParent"
      dropdown-search
      dropdown-width="200px"
      has-only-this-button
      hide-selected-items-in-dropdown
      item-label="name"
      item-value="accountId"
      multi
      show-on-init
      show-selected-options-inside
      theme="no-shadow-next light"
      type="default-next"
      @hide="hideSelect"
      @open="areAssigneesLoading = true"
      @opened="getAssignees"
      @update:model-value="onStakeholdersUpdate"
    >
      <template #option-label="{ option }">
        <OwnerFieldOption :option="option" />
      </template>
    </AppSelect>
  </template>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { PLUG_ICONS } from '@/utils/cell-helper'
import { handleError } from '@/utils/error-handling'
import {
  createUsersList,
  getOwnerFromUsersList,
  getStakeholdersFromUsersList,
  objectiveIsJiraTask
} from '@/utils/objectives'
import { uid } from '@/utils/uid'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

export default defineComponent({
  name: 'StakeholdersCell',

  components: {
    OwnerFieldOption,
    AppSelect,
    CellSelectItemsList
  },

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    }
  },

  emits: { 'update-stakeholders': null },

  data() {
    return {
      uid: uid(),
      assignees: [],
      areAssigneesLoading: false,

      editInitialised: false,

      isOpened: false
    }
  },

  computed: {
    isTask() {
      return objectiveIsJiraTask(this.item)
    },

    PLUG_ICONS() {
      return PLUG_ICONS
    },

    selectedUser() {
      return getOwnerFromUsersList(this.item.users)?.accountId
    },

    selectedStakeholders() {
      return getStakeholdersFromUsersList(this.item.users)
    },

    selectedStakeholdersIds() {
      return this.selectedStakeholders.map(stakeholder => stakeholder.accountId)
    }
  },

  methods: {
    async getAssignees() {
      this.isOpened = true
      this.assignees = await this.getAssigneesInfo()
    },

    async getAssigneesInfo(searchString = null) {
      let result = []
      const api = new AssigneesInfoApiHandler()

      const requiredUserAccountIds = isEmpty(this.selectedStakeholdersIds)
        ? null
        : this.selectedStakeholdersIds

      const parameters = {
        requiredUserAccountIds,
        workspaceId: this.item.workspaceId,
        searchString
      }

      try {
        result = await api.getUsers(parameters)
      } catch (error) {
        handleError({ error })
      } finally {
        this.areAssigneesLoading = false
      }
      return result
    },

    async onStakeholdersUpdate(stakeholders) {
      const api = new ObjectivesInfoApiHandler()
      try {
        const result = await api.updateOkrElement({
          ...this.item,
          elementId: this.item.id,
          users: createUsersList({ ownerId: this.selectedUser, stakeholders })
        })
        const { hiddenByFilter } = result.element
        const updatedItem = {
          ...this.item,
          hiddenByFilter
        }

        this.$emit('update-stakeholders', { ...updatedItem, ...result })
      } catch (error) {
        handleError({ error })
      }
    },

    hideSelect() {
      this.isOpened = false
      this.assignees = []
    }
  }
})
</script>
