<template>
  <div :class="{ 'amws-WorkspaceSelect-hidden': menuHidden }" class="amws-WorkspaceSelect">
    <AppSelect
      v-if="workspacesLoaded"
      ref="select"
      :bottom-fixed-items="bottomFixedItems"
      :disabled="disabled"
      :dropdown-search="showDropdownSearch"
      :model-value="workspaceId"
      :offset="[0, 0]"
      :options="options"
      :search-function="getAllUserWorkspaces"
      append-to=".app-Aside"
      content-class="amws-WorkspaceSelectContent"
      item-label="name"
      item-value="id"
      no-left-padding
      theme="no-shadow-next light"
      type="default-next"
      @change="onDropdownMenuChange"
      @update:options="onDropdownMenuOptionsUpdate"
    >
      <template #button="{ option, active }">
        <div
          v-if="option"
          v-tippy="{
            ...TOOLTIP_CONFIGURATION,
            content: option.name
          }"
          :class="{ 'amws-WorkspaceSelect_Button-opened': active }"
          class="amws-WorkspaceSelect_Button"
        >
          <WorkspaceIcon :option="option" />

          <span class="amws-SelectedWsName">
            {{ option.name }}
          </span>

          <span class="amws-DroplistIcon">
            <!-- chevrons-up-down -->
            <AppIcon height="24" icon-name="arrow-down-black-next" width="24" />
          </span>
        </div>
      </template>
      <template #option-label="{ option, selected }">
        <AppMenuWorkspaceSelectOption
          v-if="option"
          :bottom-fixed-item="checkIsBottomFixedItem(option.id)"
          :data-auto-testid="option.autoTestId || null"
          :option="option"
          :selected="selected"
        />
      </template>
    </AppSelect>
    <SkeletonItem v-else size="xl" width="100%" />
  </div>
  <portal to="modal-windows">
    <WorkspaceCreateFastEditModal
      v-model:show="showWorkspaceModal"
      data-auto-testid="create-workspace-modal"
      @on-created="onWorkspaceCreated"
    />
  </portal>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import WorkspacesApiHandler from '@/api/workspaces'
import { useBackSettingsBackButton } from '@/composables/settings-back-button'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, trackWorkspaceFormOpenedEvent } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import {
  EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
  EDIT_WORKSPACE_QUERY_KEY
} from '@/utils/query-parameters'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { TOOLTIP_CONFIGURATION } from '@/utils/tippy-config'
import { saveLastSelectedWorkspace } from '@/utils/workspaces'

import AppMenuWorkspaceSelectOption from '@/components/AppMenu/AppMenuWorkspaceSelectOption'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'
import WorkspaceCreateFastEditModal from '@/components/workspaces/WorkspaceCreateFastEditModal'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

const BOTTOM_FIXED_ITEMS = {
  MANAGE_WORKSPACE: 'manage-workspace',
  CREATE_WORKSPACE: 'create-workspace'
}

export default defineComponent({
  name: 'AppMenuWorkspaceSelect',

  components: {
    SkeletonItem,
    WorkspaceCreateFastEditModal,
    WorkspaceIcon,
    AppIcon,
    AppMenuWorkspaceSelectOption,
    AppSelect
  },

  props: {
    menuShowed: {
      type: Boolean
    },

    menuPinned: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    }
  },

  setup() {
    const { saveSettingsReferer } = useBackSettingsBackButton()

    return { saveSettingsReferer }
  },

  data() {
    return {
      showWorkspaceModal: false
    }
  },

  computed: {
    ...mapState('workspaces', {
      workspaces: state => state.workspaces,
      workspaceId: state => state.workspaceId
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    ...mapState('system', {
      userData: state => state.userData
    }),

    ...mapGetters('system', {
      userRoleForTracking: 'userRoleForTracking'
    }),

    TOOLTIP_CONFIGURATION: () => TOOLTIP_CONFIGURATION,

    workspacesLoaded() {
      return !isEmpty(this.workspaces)
    },

    menuHidden() {
      return !this.menuPinned && !this.menuShowed
    },

    showDropdownSearch() {
      return this.workspaces.length > 10
    },

    options() {
      let bottomFixedItemsOptions = []

      if (this.isPluginAdmin) {
        bottomFixedItemsOptions.push({
          id: BOTTOM_FIXED_ITEMS.CREATE_WORKSPACE,
          name: this.$t('workspaces.create'),
          icon: 'plus-next',
          autoTestId: 'create-workspace'
        })
      }

      if (this.userData?.hasAccessToWorkspaceSettingsPage) {
        bottomFixedItemsOptions.push({
          id: BOTTOM_FIXED_ITEMS.MANAGE_WORKSPACE,
          name: this.$t('workspaces.manage'),
          icon: 'edit-next',
          autoTestId: 'manage-workspaces'
        })
      }

      return [...bottomFixedItemsOptions, ...this.workspaces]
    },

    bottomFixedItems() {
      const { CREATE_WORKSPACE, MANAGE_WORKSPACE } = BOTTOM_FIXED_ITEMS
      let items = []
      if (this.isPluginAdmin) {
        items.push(CREATE_WORKSPACE)
      }

      if (this.userData?.hasAccessToWorkspaceSettingsPage) {
        items.push(MANAGE_WORKSPACE)
      }

      return items
    }
  },

  watch: {
    menuPinned(newValue) {
      if (!newValue) {
        this.$refs.select?.hideDropdown()
      }
    },

    menuShowed(newValue) {
      if (!newValue) {
        this.$refs.select?.hideDropdown()
      }
    }
  },

  methods: {
    ...mapActions('workspaces', {
      setWorkspaceId: 'setWorkspaceId',
      setWorkspaces: 'setWorkspaces',
      addAndSelectWorkspace: 'addAndSelectWorkspace'
    }),

    async onWorkspaceCreated(createdWorkspace) {
      saveLastSelectedWorkspace(createdWorkspace.id)

      this.showWorkspaceModal = false
      showNotify({ title: this.$t('workspaces.workspace_created') })

      this.addAndSelectWorkspace(createdWorkspace)

      await this.$nextTick()

      trackWorkspaceFormOpenedEvent({
        isNewlyCreated: true,
        tab: this.$t('field.groups.title', 1, { locale: 'en' }),
        role: this.userRoleForTracking
      })

      await this.saveSettingsReferer()

      this.$router.push({
        name: ROUTE_NAMES.SETTINGS_WORKSPACES,
        query: {
          [EDIT_WORKSPACE_QUERY_KEY]: createdWorkspace.id,
          [EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]: TABLE_COLUMNS_KEYS.GROUPS
        }
      })
    },

    checkIsBottomFixedItem(optionId) {
      return Object.values(BOTTOM_FIXED_ITEMS).includes(optionId)
    },

    selectWorkspace(workspaceId) {
      saveLastSelectedWorkspace(workspaceId)

      this.setWorkspaceId(workspaceId)

      tracker.logEvent('Opened workspace', {
        category: EVENT_CATEGORIES.MAIN_NAVIGATION
      })
    },

    async getAllUserWorkspaces(searchString = null) {
      const api = new WorkspacesApiHandler()
      let result = []
      try {
        const workspaces = await api.getAllUserWorkspaces({ searchString })
        result = workspaces.filter(ws =>
          this.workspaces.findIndex(workspace => ws.id === workspace.id)
        )
      } catch (error) {
        handleError({ error })
      }

      return result
    },

    onDropdownMenuOptionsUpdate(options) {
      const result = [...options]
      this.setWorkspaces(result)
    },

    onDropdownMenuChange(item) {
      if (item === BOTTOM_FIXED_ITEMS.CREATE_WORKSPACE) {
        this.showWorkspaceModal = true
      } else if (item === BOTTOM_FIXED_ITEMS.MANAGE_WORKSPACE) {
        this.saveSettingsReferer()
        this.$router.push({
          name: ROUTE_NAMES.SETTINGS_WORKSPACES
        })
      } else {
        this.selectWorkspace(item)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.amws-WorkspaceSelect {
  padding: 12px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: $grey-2-next;
  transition: opacity $menu-transition;
  &-hidden {
    opacity: 0;
    pointer-events: none;
  }
}
.amws-WorkspaceSelect_Button {
  cursor: pointer;
  overflow: hidden;
  background: $white;
  // box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-sm-next;
  // padding: 12px 10px;
  padding: 8px;
  display: flex;
  align-items: center;

  &:not(&-opened) {
    color: $dark-2;
    @include activityStates($grey-medium);

    &:hover {
      // color: $primary-color-next;
      @include hoverState($grey-3-next, 0);
    }
  }

  &-opened {
    background: $dark-2;
    color: $white;
  }
}

.amws-DroplistIcon {
  display: flex;
  margin-left: auto;
}

.amws-SelectedWsName {
  font-family: $system-ui;
  // padding-left: 16px;
  padding-left: 8px;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
<style lang="scss">
.amws-WorkspaceSelectContent {
  .as-BottomFixedItems {
    padding-top: 9px;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 1px;
      width: calc(100% - 20px);
      background: $grey-2-next;
      pointer-events: none;
      position: absolute;
      top: 5px;
      left: 10px;
    }
  }
}
</style>
