<template>
  <AppSelectItem
    v-if="label"
    v-tippy="{
      ...TOOLTIP_CONFIGURATION,
      content: tooltipLabel
    }"
    :show-delete-button="showDeleteButton"
    :show-selected-count-circle="showSelectedCountCircle"
    type="secondary-next"
  >
    {{ label }}
  </AppSelectItem>
</template>

<script setup>
import { TOOLTIP_CONFIGURATION } from '@/utils/tippy-config'

import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'

defineProps({
  label: {
    type: String,
    required: true
  },

  tooltipLabel: {
    type: String,
    required: true
  },

  showDeleteButton: {
    type: Boolean
  },

  showSelectedCountCircle: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped></style>
