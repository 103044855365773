<template>
  <!-- this ref is used in a parent component -->
  <!-- eslint-disable-next-line vue/no-unused-refs -->
  <div ref="timelinesWrapper" class="otl-TimelinesWrapper">
    <div ref="header" class="otl-Head">
      <OkrTimelinesTableHead
        v-if="showHeader"
        :active-view="activeView"
        :data="dates"
        :one-day-width="oneDayWidth"
        @select-active-date-reference="onActiveDateReferenceSelected"
      />
    </div>
    <div ref="timelines" class="otl-Timelines">
      <div :style="{ '--timelines-body-height': timelinesBodyHeight }" class="otl-Timelines_Items">
        <div
          ref="timelinesBody"
          :class="{
            'otl-Body-noInteracted': roadmapState.someTimelineInteracted,
            'otl-Body-backlogError': isBacklogEnabled
          }"
          class="otl-Body"
        >
          <OkrTimelinesTableGrid
            v-if="showHeader"
            :active-view="activeView"
            :data="dates"
            :one-day-width="oneDayWidth"
          />
          <OkrTimelinesList
            v-if="showTimelines"
            ref="list"
            :active-view="activeView"
            :min-max-date="minMaxDateAndToday"
            :model-value="listState.objectives"
            :one-day-width="oneDayWidth"
            @update-elements="$emit('update-elements', $event)"
            @on-okr-element-updated="$emit('on-okr-element-updated', $event)"
          />
        </div>

        <OkrTimelinesBacklogError v-if="isBacklogEnabled" :elements="elementsWithBacklogInterval" />

        <portal to="roadmap-footer">
          <OkrTimelinesControls
            v-if="showTimelines"
            v-model:oneDayWidth="oneDayWidth"
            :active-view="activeView"
            :min-max-date="minMaxDateAndToday"
            :show-today-button="showTodayButton"
            @scroll-to-point="scrollToPoint"
            @set-active-view="setActiveView"
            @scroll-to-today="scrollToActive"
            @move-timelines="moveTimelines"
            @toggle-show-area="$emit('toggle-show-area', $event)"
            @export-roadmap="$emit('export-roadmap', $event)"
          />
        </portal>

        <LoadingCircle v-if="showLoading" size="small" />
      </div>
    </div>
  </div>
</template>

<script>
import { useElementBounding } from '@vueuse/core'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import MinMax from 'dayjs/plugin/minMax'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { uniqBy, chunk, uniq } from 'lodash'
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue'
import { mapActions, mapGetters, useStore } from 'vuex'

import { utcDateToLocal } from '@/utils/date'
import { isBetweenDates } from '@/utils/interval'
import { getCellWidth } from '@/utils/memoizations'
import { filteredObjectivesToDisplay } from '@/utils/objectives'
import { updateStorageByKey } from '@/utils/persist'
import {
  ONE_DAY_WIDTH_BY_VIEW,
  TIMELINES_VIEWS,
  UNITS_FOR_MIN_MAX_DATES_BY_VIEW
} from '@/utils/roadmap'
import { disconnectSyncScroll, initSyncScroll, SYNC_AXES } from '@/utils/sync-scroll'
import {
  getResolvedRestoredValue,
  ROADMAP_ACTIVE_VIEW,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'
import { scrollIntoViewOnlyHorizontally } from '@/utils/window'

import OkrTimelinesBacklogError from '@/components/objectives/roadmap/OkrTimelinesBacklogError'
import OkrTimelinesControls from '@/components/objectives/roadmap/OkrTimelinesControls'
import OkrTimelinesList from '@/components/objectives/roadmap/OkrTimelinesList'
import OkrTimelinesTableGrid from '@/components/objectives/roadmap/OkrTimelinesTableGrid'
import OkrTimelinesTableHead from '@/components/objectives/roadmap/OkrTimelinesTableHead'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

dayjs.extend(quarterOfYear)
dayjs.extend(MinMax)
dayjs.extend(localeData)

const LS_ROADMAP_VIEW_KEY = 'roadMapView'

const DAYS_UNIT = 'days'

const SYNC_SCROLL_GROUP_NAME = 'timelines'

export default defineComponent({
  name: 'OkrTimelines',
  components: {
    OkrTimelinesBacklogError,
    OkrTimelinesTableGrid,
    OkrTimelinesList,
    LoadingCircle,
    OkrTimelinesControls,
    OkrTimelinesTableHead
  },

  inject: ['listState', 'roadmapState'],

  props: {
    noObjectives: {
      type: Boolean
    },

    timelinesBodyHeight: {
      type: Number,
      default: 0
    }
  },

  emits: {
    'on-okr-element-updated': null,
    'update-elements': null,
    'update:timelines-body-height': null,
    'update:timelines-body-width': null,
    'reset-scroll-target': null,
    'toggle-show-area': null,
    'export-roadmap': null
  },

  setup() {
    const header = ref(null)
    const { top } = useElementBounding(header)
    const store = useStore()

    watch(
      () => top.value,
      newValue => {
        const stuckOffset = parseInt(getComputedStyle(header.value).top)
        if (newValue <= stuckOffset) {
          store.dispatch('system/toggleRoadmapStickyStatus', true)
        } else {
          store.dispatch('system/toggleRoadmapStickyStatus', false)
        }
      }
    )

    onBeforeUnmount(() => {
      store.dispatch('system/toggleRoadmapStickyStatus', false)
    })

    return {
      header
    }
  },

  data() {
    return {
      resizeObserver: null,
      activeDateReference: null,
      activeView: TIMELINES_VIEWS.QUARTERS,
      oneDayWidth: ONE_DAY_WIDTH_BY_VIEW[TIMELINES_VIEWS.QUARTERS].DEFAULT
    }
  },

  computed: {
    ...mapGetters('system', {
      roadmapActiveView: 'roadmapActiveView'
    }),

    showTimelines() {
      return !this.noObjectives && this.showHeader
    },

    showHeader() {
      return this.showTable && !this.isBacklogEnabled
    },

    months() {
      // computed coz we have locales
      return dayjs.months()
    },

    weekDays() {
      // computed coz we have locales
      return dayjs.weekdays()
    },

    quartersView() {
      return this.activeView === TIMELINES_VIEWS.QUARTERS
    },

    weeksView() {
      return this.activeView === TIMELINES_VIEWS.WEEKS
    },

    showTodayButton() {
      return this.dates.some(date => date.includesToday)
    },

    displayedOkrElementsIds() {
      const getDisplayedOkrElementsIds = (okrIds, depth = 0) => {
        return okrIds.reduce((acc, val) => {
          const isExpanded = this.listState.filtersValues.expandedItems[`${val}-${depth}`]
          if (isExpanded) {
            const allChildren = this.listState.okrElementChildren[val] || []
            acc = [...getDisplayedOkrElementsIds(allChildren, depth + 1), ...acc, val]
            return acc
          } else {
            acc = [...acc, val]
            return acc
          }
        }, [])
      }
      return getDisplayedOkrElementsIds(
        filteredObjectivesToDisplay(this.listState.objectives, this.listState)
      )
    },

    displayedOkrElementsDates() {
      return this.displayedOkrElementsIds.map(elementId => {
        const okrElement = this.listState.okrElements[elementId]
        const { elementStartDate, dueDate, automaticElementStartDate, automaticDueDate } =
          okrElement
        return {
          startDate: elementStartDate || automaticElementStartDate,
          dueDate: dueDate || automaticDueDate,
          elementId
        }
      })
    },

    isBacklogEnabled() {
      return this.displayedOkrElementsDates.some(item => {
        const { dueDate, startDate } = item
        return !dueDate || !startDate
      })
    },

    elementsWithBacklogInterval() {
      return this.displayedOkrElementsDates
        .filter(({ dueDate, startDate }) => !dueDate || !startDate)
        .map(({ elementId }) => this.listState.okrElements[elementId])
    },

    minMaxDates() {
      const displayedStartEndDates = this.displayedOkrElementsDates.reduce(
        (acc, val) => {
          const { startDate, dueDate } = val
          acc.startDates = [...acc.startDates, dayjs(utcDateToLocal(new Date(startDate)))]
          acc.dueDates = [...acc.dueDates, dayjs(utcDateToLocal(new Date(dueDate)))]
          return acc
        },
        { startDates: [], dueDates: [] }
      )

      const minDate = dayjs.min(displayedStartEndDates.startDates)
      const maxDate = dayjs.max(displayedStartEndDates.dueDates)
      return { minDate, maxDate }
    },

    minMaxDateAndToday() {
      const { minDate, maxDate } = this.minMaxDates
      const today = dayjs()
      const unit = UNITS_FOR_MIN_MAX_DATES_BY_VIEW[this.activeView]

      if (this.quartersView) {
        return {
          today,
          minDate: minDate.subtract(3, TIMELINES_VIEWS.QUARTERS).startOf(unit),
          maxDate: maxDate.add(3, TIMELINES_VIEWS.QUARTERS).endOf(unit)
        }
      } else {
        return {
          today,
          minDate: minDate.subtract(3, TIMELINES_VIEWS.MONTHS).startOf(unit),
          maxDate: maxDate.add(3, TIMELINES_VIEWS.MONTHS).endOf(unit)
        }
      }
    },

    showTable() {
      const { minDate, maxDate } = this.minMaxDates
      return Boolean(minDate && maxDate)
    },

    showLoading() {
      return !this.showTable && !this.listState.dataLoaded
    },

    datesForQuarters() {
      const { today, minDate, maxDate } = this.minMaxDateAndToday

      const diff = maxDate.diff(minDate, TIMELINES_VIEWS.QUARTERS)
      return [...Array(diff + 1).keys()].map(i => {
        const start = minDate.add(i, TIMELINES_VIEWS.QUARTERS)
        const quarterEndDate = start.add(1, TIMELINES_VIEWS.QUARTERS)
        const includesToday = isBetweenDates(start, quarterEndDate)
        const daysCount = quarterEndDate.diff(start, DAYS_UNIT)
        const todayNumber = today.diff(start, DAYS_UNIT) + 1
        const totalDaysCount = maxDate.diff(minDate, DAYS_UNIT) + 1
        const quarterShortcut = this.$t('roadmap.quarter_shortcut')
        return {
          key: `${quarterShortcut}${start.quarter()}’${start.year()}`,
          title: `${quarterShortcut}${start.quarter()}’${start.year()}`,
          includesToday,
          daysCount,
          todayNumber,
          totalDaysCount
        }
      })
    },

    datesForWeeksOrMonths() {
      const { today, minDate, maxDate } = this.minMaxDateAndToday
      const diff = maxDate.diff(minDate, DAYS_UNIT)
      const dates = [...Array(diff + 1).keys()].map(day => {
        const isoDate = minDate.add(day, DAYS_UNIT)
        const year = isoDate.year()
        const monthName = this.months[isoDate.month()]
        const includesToday = today.month() === isoDate.month() && today.year() === isoDate.year()
        const daysCount = new Date(year, isoDate.month() + 1, 0).getDate()
        // removed localDatesToUtc
        // because of https://oboard.atlassian.net/jira/software/c/projects/OK/boards/14?assignee=557058%3A989b8931-c8bf-4350-8b05-2ff823c62976&selectedIssue=OK-2859
        // in negative timezones we have a bug with wrong dates and calendar shifting
        const [shortcut] = this.weekDays[isoDate.day()]
        return {
          id: +isoDate,
          year,
          monthName,
          key: `${monthName}-${year}`,
          shortcut,
          dateNumber: isoDate.date(),
          active: isoDate.isToday(),
          isWeekEnd: isoDate.day() === 0 || isoDate.day() === 6,
          includesToday,
          daysCount
        }
      })

      const monthsFromDates = dates.map(date => {
        const {
          id,
          monthName,
          key,
          year,
          includesToday,
          daysCount,
          dateNumber,
          active,
          shortcut,
          isWeekEnd
        } = date
        return {
          title: `${monthName.slice(0, 3)} ${year}`,
          key,
          year,
          includesToday,
          daysCount,
          id,
          dateNumber,
          shortcut,
          active,
          isWeekEnd
        }
      })

      const uniqueMoths = uniqBy(monthsFromDates, 'key').map(month => {
        const { title, key, includesToday, daysCount } = month
        return { title, key, includesToday, daysCount }
      })

      // const monthsWithDates = uniqueMoths.map((item) => {
      //   return {
      //     ...item,
      //     dates: dates.filter(
      //       (date) => date.monthName === item.monthName && date.year === item.year
      //     ),
      //   };
      // });

      const weeksWithDates = chunk(monthsFromDates, 7).map(chunk => {
        const title = uniq(chunk.map(item => item.title)).join(' - ')
        const [firstDay] = chunk
        const [lastDay] = chunk.slice(-1)
        const datesRange = [firstDay, lastDay].map(day => day.dateNumber).join('-')
        const uniqKey = uniq(chunk.map(item => item.key)).join('-')
        const key = `${uniqKey}-${datesRange}`
        return {
          title,
          key,
          includesToday: chunk.some(date => date.active),
          dates: chunk.map(date => {
            const { active, id, shortcut, dateNumber, isWeekEnd } = date
            return {
              active,
              id,
              shortcut,
              dateNumber,
              isWeekEnd
            }
          })
        }
      })

      return this.weeksView ? weeksWithDates : uniqueMoths
    },

    dates() {
      const resolvedDates = this.quartersView ? this.datesForQuarters : this.datesForWeeksOrMonths
      return resolvedDates.map(item => {
        const cellWidth = getCellWidth({
          data: item,
          oneDayWidth: this.oneDayWidth,
          isWeeksView: this.weeksView
        })
        return {
          ...item,
          cellWidth
        }
      })
    }
  },

  watch: {
    showHeader: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            const elements = [
              {
                element: this.$refs.header
              },
              {
                element: this.$refs.timelines
              }
            ]
            initSyncScroll({
              elements,
              syncAxes: [SYNC_AXES.X],
              groupName: SYNC_SCROLL_GROUP_NAME
            })
          })
        } else {
          disconnectSyncScroll(SYNC_SCROLL_GROUP_NAME)
        }
      },

      immediate: true
    }
  },

  beforeUnmount() {
    this.disconnectObserver()
    disconnectSyncScroll(SYNC_SCROLL_GROUP_NAME)
  },

  mounted() {
    this.initResizeObserver()
    this.restoreRoadMapSettings()

    this.roadmapState.canvas = this.$refs.timelines
  },

  methods: {
    ...mapActions('system', {
      updateUserSettings: 'updateUserSettings'
    }),

    /** @public */
    refreshPositions() {
      this.$refs.list?.refreshPositions()
    },

    restoreRoadMapSettings() {
      const resolvedResolvedView = getResolvedRestoredValue({
        valueFromSettings: this.roadmapActiveView,
        localStorageKey: LS_ROADMAP_VIEW_KEY
      })

      if (resolvedResolvedView && Object.values(TIMELINES_VIEWS).includes(resolvedResolvedView)) {
        this.setActiveView(resolvedResolvedView)
      }
    },

    async setActiveView(view) {
      if (this.activeView !== view) {
        this.$emit('reset-scroll-target')
        this.activeView = view
        updateStorageByKey(LS_ROADMAP_VIEW_KEY, view)

        if (view !== this.roadmapActiveView) {
          await this.updateUserSettings({
            [USER_SETTINGS_MAPPER[ROADMAP_ACTIVE_VIEW]]: view
          })
        }
      }
    },

    async onActiveDateReferenceSelected({ reference, isFirstLoad }) {
      this.activeDateReference = reference
      if (isFirstLoad) {
        // nextTick and setTimeout both are needed to make sure that the list is rendered
        await this.$nextTick()
        setTimeout(() => {
          this.scrollToActive()
        })
      }
    },

    scrollToActive() {
      scrollIntoViewOnlyHorizontally(this.activeDateReference, { inline: 'center' })
    },

    scrollToPoint(point) {
      this.$refs.timelines.scrollLeft = point
    },

    moveTimelines(offset) {
      this.$refs.timelines.scrollLeft += offset
    },

    async initResizeObserver() {
      await this.$nextTick()
      const { timelinesBody } = this.$refs
      this.resizeObserver = new ResizeObserver(() => {
        this.$emit('update:timelines-body-height', timelinesBody.offsetHeight)
        this.$emit('update:timelines-body-width', timelinesBody.offsetWidth)
      })

      this.resizeObserver.observe(timelinesBody)
    },

    disconnectObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/roadmap';
@import '~@/assets/styles/canvas-dimensions';
.otl-Timelines_Items {
  height: 100%;
}

.otl-Body {
  z-index: 1;
  width: fit-content;

  &:not(&-backlogError) {
    height: 100%;
  }

  // overflow: hidden;

  &-noInteracted {
    user-select: none;
    pointer-events: none;
  }
}

/* eslint-disable-next-line */
%interaction-remove-styles {
  pointer-events: none;
  user-select: none;
}

.otl-Timelines {
  @include styled-native-scrollbar();
  flex: 1 1 0;
  overflow-x: auto;
  position: relative;
  z-index: 2;
  background-color: $white;
  // box-shadow: inset 0 1px 0 $grey-medium;
  overscroll-behavior-x: none;
  // box-shadow: inset 10px 0 16px -10px rgb(9 30 66 / 20%);

  .rm-OkrPage-resizeContinues & {
    @extend %interaction-remove-styles;
  }
}

.otl-TimelinesWrapper {
  width: calc(100% - var(--width));
  display: grid;
  grid-template-rows: $timelines-head-height 1fr;
  font-family: $system-ui;
}

.otl-Head {
  position: sticky;
  top: calc(
    var(--objectives-page-header-height, $default-objectives-page-header-height) +
      var(--app-license-banner-height, 0px)
  );
  overflow-x: hidden;
  z-index: 3;
  background: $white;
}
</style>
