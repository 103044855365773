<template>
  <div class="dp-DashboardPage">
    <div :class="{ [`dp-MainContent-${view}`]: view }" class="dp-MainContent">
      <AppRadioGroup
        v-if="!hideNavigation"
        :model-value="view"
        :options="dashboardTabsMenu"
        class="dp-DashboardPage_Navigation"
        name="dashboard-navigation"
        type="tab-like"
        @update:model-value="navigateByName"
      />

      <portal v-slot="{ tab }" to="dashboard-interval-select">
        <AppSelect
          v-model="intervalId"
          :dropdown-min-width="200"
          :is-highlighted="!!intervalId"
          :loading="isIntervalsLoading"
          :offset="[0, 0]"
          :options="intervals"
          append-icon-height="20"
          append-icon-width="20"
          class="dp-DashboardPage_IntervalSelect"
          icon="calendar-next"
          item-label="name"
          item-value="id"
          n-selected-label="field.quoter.title"
          position="bottom-start"
          prepend-icon="calendar-next"
          theme="no-shadow-next light"
          type="secondary-next"
          @update:model-value="selectIntervals($event, tab)"
        >
          <template #option="props">
            <IntervalSelectOption v-bind="props" />
          </template>
          <template #button-content="{ option }">
            <AppSelectItemWithTooltip
              v-if="option"
              :label="option.name"
              :tooltip-label="option.name"
            />
          </template>
        </AppSelect>
      </portal>
      <router-view>
        <template #default="{ Component, route }">
          <template v-if="isNoIntervals && route.name !== ROUTE_NAMES.CUSTOM_DASHBOARD_LIST">
            <EmptyStateDashboard>
              <div class="dp-NoIntervals">
                <div class="dp-NoIntervals_Title">
                  {{ $t('dashboard.no_intervals') }}
                </div>
                <template v-if="userIsAdmin">
                  <div class="dp-NoIntervals_Actions">
                    <div class="dp-NoIntervalsActions_Title">
                      {{ $t('dashboard.no_intervals_subtitle') }}
                    </div>
                    <AppDivider class="dp-NoIntervalsActions_Divider" no-margin />
                    <AppButton
                      height="24"
                      icon="plus-next"
                      type="primary-next"
                      width="24"
                      @click="onCreateIntervalClick"
                    >
                      {{ $t('intervals.create_interval') }}
                    </AppButton>
                  </div>
                </template>
              </div>
            </EmptyStateDashboard>
          </template>

          <component :is="Component" v-else :interval-id="intervalId" :workspace-id="workspaceId" />
        </template>
      </router-view>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { getSuitableInterval } from '@/utils/interval'
import { sendIntervalFilterTrackerEvent } from '@/utils/okr-elements/filters'
import {
  EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY,
  EDIT_WORKSPACE_QUERY_KEY,
  INTERVAL_ID_KEY
} from '@/utils/query-parameters'
import { updateQueryParameter } from '@/utils/router'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import {
  DASHBOARD_FILTERS_KEYS,
  DASHBOARD_GENERAL_FILTERS,
  getResolvedRestoredValueWithQuery,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'

import EmptyStateDashboard from '@/components/dashboard/EmptyStateDashboard'
import IntervalSelectOption from '@/components/objectives/toolbar/IntervalSelectOption'
import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'
import AppSelectItemWithTooltip from '@/components/ui/AppSelect/AppSelectItemWithTooltip'
import TitleSelectItem from '@/components/ui/AppSelect/TriggerButtons/TitleSelectItem'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

export default defineComponent({
  name: 'DashboardLayout',
  components: {
    AppSelectItemWithTooltip,
    AppButton,
    AppDivider,
    EmptyStateDashboard,
    NoSearchResults,
    AppBadge,
    AppRadioGroup,
    PageContentHeader,
    AppSelect,
    AppTitle,
    TitleSelectItem,
    AppSelectItem,
    IntervalSelectOption
  },

  beforeRouteEnter(_, __, next) {
    next(vm => {
      const showDashboardItem = vm.showDashboardItem
      const selectedWorkspaceId = vm.workspaceId
      if (!showDashboardItem) {
        // wait for finishing initial route resolve and redirect
        vm.$nextTick(() => {
          vm.$router.push(`${ROUTE_PATHS.WORKSPACES}${selectedWorkspaceId}`)
        })
      }
    })
  },

  data() {
    return {
      intervals: [],
      isIntervalsLoading: false,
      intervalId: null
    }
  },

  computed: {
    ...mapGetters('system', {
      showDashboardItem: 'showDashboardItem',
      userIsAdmin: 'userIsAdmin',
      dashboardGeneralSavedFiltersByWorkspaceId: 'dashboardGeneralFiltersByWorkspaceId',
      dashboardGeneralSavedFilters: 'dashboardGeneralFilters'
      // performanceReportFilters: 'performanceReportFilters'
    }),

    ROUTE_NAMES: () => ROUTE_NAMES,

    isNoIntervals() {
      return isEmpty(this.intervals) && !this.isIntervalsLoading
    },

    view() {
      if (this.$route.path.includes('dashboard/performance-report')) {
        return ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT
      }
      return this.$route.name
    },

    hideNavigation() {
      return this.$route.meta.hideNavigation
    },

    dashboardTabsMenu() {
      // const [performanceReportRouteName] = this.performanceReportFilters
      return [
        {
          label: this.$t('dashboard.menu.general_report'),
          value: ROUTE_NAMES.DASHBOARD_GENERAL
        },
        {
          label: this.$t('dashboard.menu.performance_report'),
          value: ROUTE_NAMES.DASHBOARD_PERFORMANCE_REPORT
        },
        {
          label: this.$t('dashboard.menu.custom_dashboard'),
          value: ROUTE_NAMES.CUSTOM_DASHBOARD_LIST
        }
      ]
    },

    workspaceId() {
      return this.$route.params.workspaceId
    }
  },

  watch: {
    async workspaceId(newValue) {
      if (newValue) {
        this.intervalId = await this.getSavedIntervalId()

        this.getAllIntervals()
      }
    },

    intervalId(newValue) {
      updateQueryParameter(this.$router, this.$route, INTERVAL_ID_KEY, newValue)
    }
  },

  async mounted() {
    await this.restoreFilters()
    this.getAllIntervals()
  },

  methods: {
    ...mapActions('system', {
      updateUserSettings: 'updateUserSettings'
    }),

    async getSavedIntervalId() {
      const savedFilters = this.dashboardGeneralSavedFiltersByWorkspaceId({
        workspaceId: this.workspaceId
      })

      // 8148 q422
      const savedIntervalIds = savedFilters[DASHBOARD_FILTERS_KEYS.INTERVAL_IDS] || []

      const [savedIntervalId] = savedIntervalIds || null

      return savedIntervalId
    },

    async restoreFilters() {
      const savedIntervalId = await this.getSavedIntervalId()

      const restoredIntervalId = getResolvedRestoredValueWithQuery({
        valueFromSettings: savedIntervalId,
        valueFromQuery: this.$route.query.intervalId
      })

      if (restoredIntervalId) {
        this.intervalId = Number(restoredIntervalId)
      }
    },

    selectIntervals(value, tab) {
      sendIntervalFilterTrackerEvent({
        tab,
        section: EVENT_SOURCES.DASHBOARD,
        newValue: [value],
        intervals: this.intervals
      })

      this.saveSelectedInterval({ intervalId: value })
    },

    saveSelectedInterval({ intervalId }) {
      this.updateUserSettings({
        [USER_SETTINGS_MAPPER[DASHBOARD_GENERAL_FILTERS]]: {
          ...this.dashboardGeneralSavedFilters,
          [this.workspaceId]: {
            ...this.dashboardGeneralSavedFiltersByWorkspaceId({ workspaceId: this.workspaceId }),
            [DASHBOARD_FILTERS_KEYS.INTERVAL_IDS]: [intervalId]
          }
        }
      })
    },

    navigateByName(name) {
      this.$router.push({ name })
    },

    onCreateIntervalClick() {
      this.$router.push({
        name: ROUTE_NAMES.SETTINGS_WORKSPACES,
        query: {
          [EDIT_WORKSPACE_QUERY_KEY]: this.workspaceId,
          [EDIT_WORKSPACE_ACTIVE_TAB_QUERY_KEY]: TABLE_COLUMNS_KEYS.INTERVALS
        }
      })
    },

    async getAllIntervals() {
      const api = new IntervalsInfoApiHandler()
      this.isIntervalsLoading = true
      try {
        const { intervals } = await api.getIntervalsInfo({
          workspaceId: this.workspaceId
        })
        this.intervals = intervals.filter(item => item.open && !item.backlog)
        const suitableInterval = getSuitableInterval(this.intervals, [], true)

        if (this.intervalId) {
          const index = this.intervals.findIndex(int => int.id === this.intervalId)
          if (index === -1) {
            this.intervalId = suitableInterval
            this.saveSelectedInterval({
              intervalId: suitableInterval
            })
          }
        } else {
          this.intervalId = suitableInterval
        }
      } catch (error) {
        handleError({ error })
      } finally {
        this.isIntervalsLoading = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dp-DashboardPage {
  padding-bottom: $page-bottom-padding;
}

.dp-MainContent {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  padding-top: $page-top-padding;
  margin: 0 auto;
  &-CustomDashboardItem {
    padding: 0;
  }
}

.dp-Navigation {
  margin-bottom: 24px;
}

.dp-DashboardPage_IntervalSelect {
  width: max-content;
  max-width: 200px;
}

.dp-DashboardPage_Navigation {
  margin: 0 0 16px 0;
}

.dp-NoIntervals_Title {
  margin-bottom: 16px;
}

.dp-NoIntervals {
  text-align: center;
  max-width: 800px;
}

.dp-NoIntervals_Actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.dp-NoIntervalsActions_Title {
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  font-weight: fw('regular');
}

.dp-NoIntervalsActions_Divider {
  width: 100%;
}
</style>
