<template>
  <div class="df-DescriptionField" tabindex="0">
    <div ref="editorRef" :class="editorClasses">
      <div v-if="editor" v-show="active" class="df-Toolbar">
        <AppSelect
          :dropdown-min-width="200"
          :model-value="textFormat"
          :offset="[0, 0]"
          :options="textFormats"
          append-to="parent"
          class="df-TextFormats"
          theme="no-shadow-next light"
          type="default-next"
        >
          <template #button="{ option, active: TextFormatActive }">
            <div
              :class="{ 'df-TextFormats_DroplistButton-active': TextFormatActive }"
              class="df-TextFormats_DroplistButton"
            >
              <span class="df-DroplistButton_Text">
                {{ option.label }}
              </span>
              <AppIcon
                class="df-DroplistButton_Icon"
                height="24"
                icon-name="arrow-down-black"
                width="24"
              />
            </div>
          </template>
          <template #option="{ option, onSelect, selected }">
            <div
              :class="{
                [`df-TextFormats_Heading-${option.value}`]: option.value,
                'df-TextFormats_Heading-active': selected
              }"
              class="df-TextFormats_Heading"
              @click="setTextFormat(option, onSelect)"
            >
              {{ option.label }}
            </div>
          </template>
        </AppSelect>

        <div class="df-ToolbarSplitter" />

        <DescriptionButton
          :active="editor.isActive('bold')"
          icon="format-bold"
          @click="editor.chain().focus().toggleBold().run()"
        />

        <DescriptionButton
          :active="editor.isActive('italic')"
          icon="format-italic"
          @click="editor.chain().focus().toggleItalic().run()"
        />

        <AppSelect
          ref="textColorPicker"
          :offset="[0, 0]"
          :options="COLORS"
          append-to="parent"
          content-class="df-ColorText_ContentOptions"
          dropdown-width="260px"
          theme="no-shadow-next light"
        >
          <template #button="{ active: activeTextColorPicker }">
            <div class="df-ColorTextWrapper">
              <DescriptionButton
                :active="activeTextColorPicker || editor.isActive('textStyle')"
                icon="format-color-picker"
                icon-height="24"
                icon-width="32"
                size="md"
              />
              <div
                :style="{
                  background: getColorAttribute('textStyle')
                }"
                class="df-ColorTextWrapper_Marker"
              />
            </div>
          </template>
          <template #option="{ option }">
            <div class="df-ColorTextWrapper_OptionWrapper">
              <div
                :class="{
                  'df-ColorTextWrapper_Option-active': editor.isActive('textStyle', {
                    color: option
                  })
                }"
                :style="{ 'background-color': option }"
                class="df-ColorTextWrapper_Option"
                @click="onTextColorChange(option)"
              >
                <AppIcon
                  v-if="editor.isActive('textStyle', { color: option })"
                  :style="{ color: ICON_COLORS[option] }"
                  height="22"
                  icon-name="check"
                  width="22"
                />
              </div>
            </div>
          </template>
        </AppSelect>

        <AppSelect
          :dropdown-min-width="220"
          :group-by="item => item.group"
          :model-value="moreFormat"
          :offset="[0, 0]"
          :options="moreFormats"
          :show-group-header="false"
          append-to="parent"
          max-height="240"
          show-group-divider
          theme="no-shadow-next light"
          type="default-next"
        >
          <template #option="{ option, onSelect }">
            <!-- eslint-disable vue/no-v-html -->
            <div
              :class="{
                'df-TextFormats_Heading-active': editor.isActive(option.value.toLowerCase())
              }"
              class="df-TextFormats_Heading"
              @click="onMoreFormatChange(option, onSelect)"
            >
              <template v-if="option.labelDescription">
                {{ $t(option.label) }}
                <i18n-t :keypath="option.labelDescription" :tag="option.tag" scope="global" />
              </template>
              <i18n-t v-else :keypath="option.label" :tag="option.tag" scope="global" />
            </div>
          </template>
          <template #button="{ active: activeMoreFormats }">
            <DescriptionButton
              :active="activeMoreFormats || isActiveAnyMoreFormats"
              icon="format-more"
            />
          </template>
        </AppSelect>

        <div class="df-ToolbarSplitter" />

        <DescriptionButton
          :active="editor.isActive('bulletList')"
          icon="list_bulleted"
          @click="editor.chain().focus().toggleBulletList().run()"
        />

        <DescriptionButton
          :active="editor.isActive('orderedList')"
          icon="list_numbered"
          @click="editor.chain().focus().toggleOrderedList().run()"
        />

        <div class="df-ToolbarSplitter" />

        <!--    forbidden to insert table when another table is active   -->
        <template v-if="!simpleView">
          <DescriptionButton
            :disable="editor.isActive('table')"
            icon="table-icon"
            @click="insertTable"
          />

          <AppDroplist
            v-model="showUrlModal"
            :offset="[0, 0]"
            append-to="parent"
            max-width="595"
            position="bottom"
            theme="no-shadow-next light"
          >
            <template #button>
              <DescriptionButton :active="showUrlModal || editor.isActive('link')" icon="link" />
            </template>
            <div class="df-Toolbar_LinkWrapper">
              <AppInput
                v-model.trim="url"
                :placeholder="$t('action.paste_link')"
                class="df-LinkWrapper_Input"
                icon="link"
                size="xlg"
                style-type="secondary"
              />
              <AppButton
                class="df-LinkWrapper_Button"
                size="lg-next"
                type="primary-next"
                @click="onConfirmSetUrl"
              >
                {{ $t('action.add_link') }}
              </AppButton>
            </div>
          </AppDroplist>

          <AppEmojiPicker @select-emoji="insertEmoji" />
          <div class="df-ToolbarSplitter" />
        </template>
        <AppSelect
          :dropdown-min-width="200"
          :offset="[0, 0]"
          :options="simpleViewList"
          append-to="parent"
          position="bottom-end"
          theme="no-shadow-next light"
          type="default-next"
        >
          <template #option="{ option, onSelect }">
            <div
              :class="{
                'df-TextFormats_Heading-active': option.isActive,
                'df-TextFormats_Heading-disabled': option.disabled
              }"
              class="df-TextFormats_Heading df-SimpleViewList"
              @click="
                () => {
                  execAction(option.action)
                  onSelect(option)
                }
              "
            >
              <AppIcon :icon-name="option.icon" height="24" width="24"></AppIcon>
              {{ option.title }}
            </div>
          </template>
          <template #button="{ active: activeSimpleView }">
            <DescriptionButton
              :active="activeSimpleView"
              icon="add-more"
              icon-height="24"
              icon-width="32"
              size="md"
            />
          </template>
        </AppSelect>
        <!--   This and next row needs for displaying droplist content in the simple view     -->
        <AppDroplist
          v-if="simpleView"
          v-model="showUrlModal"
          append-to="parent"
          max-width="595"
          position="bottom"
        >
          <template #button>
            <div />
          </template>
          <div class="df-Toolbar_LinkWrapper">
            <AppInput
              v-model.trim="url"
              :placeholder="$t('action.paste_link')"
              class="df-LinkWrapper_Input"
              icon="link"
              size="xlg"
              style-type="secondary"
            />
            <AppButton
              class="df-LinkWrapper_Button"
              size="lg-next"
              type="primary-next"
              @click="onConfirmSetUrl"
            >
              {{ $t('action.add_link') }}
            </AppButton>
          </div>
        </AppDroplist>
        <AppEmojiPicker
          v-if="simpleView"
          ref="emojiPickerRef"
          :show-button-trigger-button="!simpleView"
          @select-emoji="insertEmoji"
        />
      </div>

      <div
        v-if="!active && isDescriptionEmpty && showPlaceholder"
        class="df-Placeholder"
        @click="showEditor"
      >
        <span class="df-Placeholder_Text">{{ placeholder }}</span>
      </div>

      <EditorContent
        v-if="active || !isDescriptionEmpty"
        :editor="editor"
        class="df-Content"
        @click="onClickDescriptionField"
      />
    </div>

    <div v-if="active" class="df-ActionsAndCounter">
      <div v-if="actions" class="df-Actions">
        <div class="df-Actions_Item">
          <AppButton
            :disable="(!allowEmpty && isDescriptionEmpty) || !isDescriptionValid || disable"
            type="primary-next"
            @click="onSave"
          >
            {{ $t('action.save') }}
          </AppButton>
        </div>
        <div class="df-Actions_Item">
          <AppButton type="ghost-next" @click="onCancel">
            {{ $t('action.cancel') }}
          </AppButton>
        </div>
      </div>
      <div
        v-if="!(!actions && charactersKept > 500)"
        :class="{
          'df-DescriptionCounter': true,
          'df-DescriptionCounter-negative': !isDescriptionValid
        }"
      >
        {{ charactersKept }}
      </div>
    </div>
  </div>
</template>

<script>
// Do not update tiptap, because in next versions it has some performance problems. Current versions work without bugs (we didn't find :)

import { mergeAttributes } from '@tiptap/core'
import CharacterCount from '@tiptap/extension-character-count'
import { Color } from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent, VueNodeViewRenderer, VueRenderer } from '@tiptap/vue-3'
// eslint-disable-next-line import/no-extraneous-dependencies
import tippy from 'tippy.js'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

import UsersApi from '@/api/workspace-users'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { unshiftKeydownEventListener, removeKeydownEventListener } from '@/utils/keyevents'
import { getIdsInMentions, resolveMentions } from '@/utils/rich-edit'
import { DATA_OBOARD_UID } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import AppEmojiPicker from '@/components/AppEmojiPicker'
import CustomTableCellTiptap from '@/components/objectives/forms/CustomTableCellTiptap'
import CustomTableTiptap from '@/components/objectives/forms/CustomTableTiptap'
import DescriptionButton from '@/components/objectives/forms/DescriptionButton'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

import MentionList from './MentionList'

const BLACK = '#000'
const WHITE = '#fff'
const moreFormatsActions = {
  UNDERLINE: 'Underline',
  STRIKETHROUGH: 'Strikethrough',
  SUBSCRIPT: 'Subscript',
  SUPERSCRIPT: 'Superscript',
  STRIKE: 'Strike',
  CLEAR: 'Clear'
}
const COLORS = [
  'rgb(23, 43, 77)',
  'rgb(7, 71, 166)',
  'rgb(0, 141, 166)',
  'rgb(0, 102, 68)',
  'rgb(255, 153, 31)',
  'rgb(191, 38, 0)',
  'rgb(64, 50, 148)',
  'rgb(151, 160, 175)',
  'rgb(76, 154, 255)',
  'rgb(0, 184, 217)',
  'rgb(54, 179, 126)',
  'rgb(255, 196, 3)',
  'rgb(255, 86, 48)',
  'rgb(101, 85, 192)',
  'rgb(255, 255, 255)',
  'rgb(178, 212, 255)',
  'rgb(180, 245, 255)',
  'rgb(171, 245, 209)',
  'rgb(255, 241, 179)',
  'rgb(255, 190, 173)',
  'rgb(234, 231, 255)'
]

const ICON_COLORS = COLORS.reduce((acc, val) => {
  const [, r, g, b] = val.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  return { ...acc, [val]: hsp > 127.5 ? BLACK : WHITE }
}, {})

const TEXT_GROUP = 'text'
const SMALL_TEXT_GROUP = 'small-text'

export default defineComponent({
  name: 'DescriptionField',

  components: {
    AppButton,
    EditorContent,
    AppSelect,
    DescriptionButton,
    AppIcon,
    AppEmojiPicker,
    AppDroplist,
    AppInput
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },

    actions: {
      type: Boolean,
      default: true
    },

    readonly: {
      type: Boolean
    },

    workspaceId: {
      type: [Number, String],
      required: true
    },

    maxLength: {
      type: Number,
      default: 18000
    },

    // Note: without placeholder cursor on focused field is invisible in Safari
    placeholder: {
      type: String,
      default: ''
    },

    allowEmpty: {
      type: Boolean,
      default: true
    },

    blurOnSave: {
      type: Boolean,
      default: true
    },

    styled: {
      type: Boolean,
      default: true
    },

    showPlaceholder: {
      type: Boolean,
      default: true
    },

    simpleView: {
      type: Boolean
    },

    disable: {
      type: Boolean
    },

    appendMentionToBody: {
      type: Boolean,
      default: true
    },

    showOnInit: {
      type: Boolean
    }
  },

  emits: { 'update:modelValue': null, cancel: null, save: null },

  data() {
    return {
      active: false,
      editor: null,
      textFormat: 'paragraph',
      moreFormat: '',
      localValue: this.modelValue,
      editorPopup: null,
      editorComponent: null,
      showUrlModal: false,
      url: '',
      showSimpleList: false
    }
  },

  computed: {
    COLORS: () => COLORS,
    ICON_COLORS: () => ICON_COLORS,

    editorClasses() {
      return {
        'df-Editor': true,
        'df-Editor-inactive': !this.active,
        'df-Editor-active': this.active,
        'df-Editor-styled': this.styled,
        'df-Editor-readonly': this.readonly
      }
    },

    simpleViewList() {
      return [
        {
          title: this.$t('action.table'),
          action: 'insertTable',
          disable: this.editor.isActive('table'),
          icon: 'table-icon',
          type: 'default',
          showInSimpleView: true
        },
        {
          title: this.$t('action.link'),
          action: 'insertLink',
          isActive: this.showUrlModal || this.editor.isActive('link'),
          icon: 'link',
          type: 'default',
          showInSimpleView: true
        },
        {
          title: this.$t('action.code_snippet'),
          action: 'insertCodeSnippet',
          isActive: this.editor.isActive('code'),
          icon: 'code',
          type: 'default'
        },
        {
          title: this.$t('action.mention'),
          action: 'insertMention',
          isActive: this.editor.isActive('mention'),
          icon: 'mention',
          type: 'default'
        },
        {
          title: this.$t('action.quote'),
          action: 'insertQuote',
          isActive: this.editor.isActive('blockquote'),
          icon: 'quote',
          type: 'default'
        },
        {
          title: this.$t('action.emoji'),
          action: 'showEmojiPicker',
          icon: 'emoji',
          type: 'default',
          showInSimpleView: true
        }
      ].filter(item => (this.simpleView ? true : !item.showInSimpleView))
    },

    isActiveAnyMoreFormats() {
      return (
        this.moreFormats.filter(format => this.editor.isActive(format.value.toLowerCase())).length >
        0
      )
    },

    textFormats() {
      return [
        {
          label: this.$t('description.normal_text'),
          value: 'paragraph'
        },
        {
          label: this.$t('description.heading_1'),
          value: 1
        },
        {
          label: this.$t('description.heading_2'),
          value: 2
        },
        {
          label: this.$t('description.heading_3'),
          value: 3
        },
        {
          label: this.$t('description.heading_4'),
          value: 4
        },
        {
          label: this.$t('description.heading_5'),
          value: 5
        },
        {
          label: this.$t('description.heading_6'),
          value: 6
        }
      ]
    },

    moreFormats() {
      return [
        {
          label: 'description.underline',
          tag: 'u',
          value: moreFormatsActions.UNDERLINE,
          highlightOption: this.editor.isActive('underline'),
          group: TEXT_GROUP
        },
        {
          label: 'description.strikethrough',
          tag: 's',
          value: moreFormatsActions.STRIKE,
          highlightOption: this.editor.isActive('strike'),
          group: TEXT_GROUP
        },
        {
          label: 'description.position',
          labelDescription: 'description.subscript',
          tag: 'sub',
          value: moreFormatsActions.SUBSCRIPT,
          highlightOption: this.editor.isActive('subscript'),
          group: SMALL_TEXT_GROUP
        },
        {
          label: 'description.position',
          labelDescription: 'description.superscript',
          tag: 'sup',
          value: moreFormatsActions.SUPERSCRIPT,
          highlightOption: this.editor.isActive('superscript'),
          group: SMALL_TEXT_GROUP
        },
        {
          label: 'description.clear_formatting',
          value: moreFormatsActions.CLEAR,
          group: MENU_ITEMS_GROUPS.REMOVING
        }
      ]
    },

    isDescriptionEmpty() {
      return !(this.editor && !this.editor.isEmpty)
    },

    isDescriptionValid() {
      return this.charactersKept > -1
    },

    charactersKept() {
      return this.maxLength - this.editor.storage.characterCount.characters()
    }
  },

  watch: {
    modelValue(newValue) {
      if (this.localValue !== this.modelValue) {
        this.localValue = newValue
        this.fixMentions()
        // setContent in fixMentions makes editor focused in safari, avoid this
        this.blur()
      }
    },

    localValue(newValue) {
      if (this.localValue !== this.modelValue) {
        this.$emit('update:modelValue', newValue)
      }
    },

    readonly() {
      this.editor.setEditable(!this.readonly)
    }
  },

  mounted() {
    this.$el.addEventListener('keydown', this.keyListener)
    this.$el.addEventListener('keyup', this.keyListener)
    this.$el.addEventListener('keypress', this.keyListener)
    unshiftKeydownEventListener('esc', this.onEsc)
    this.fixMentions()
    this.editor = new Editor({
      content: this.localValue,
      editable: !this.readonly,
      editorProps: {
        attributes: {
          class: 'df-EditorContent'
        }
      },

      extensions: [
        StarterKit,
        Link,
        Subscript,
        Underline,
        Superscript,
        TextStyle,
        Color,
        Table.extend({
          name: 'table',
          tableRole: 'table',
          addAttributes() {
            return {
              ...this.parent?.(),
              [DATA_OBOARD_UID]: {
                default: '',
                parseHTML: element => element.getAttribute(DATA_OBOARD_UID),
                renderHTML: attributes => {
                  return {
                    [DATA_OBOARD_UID]: attributes[DATA_OBOARD_UID]
                  }
                }
              }
            }
          },
          parseHTML() {
            return [
              {
                // Do not change this name its reserved name for correct parsing HTML. If you change this name it will be break all saved table in each objective
                tag: 'table',
                getAttrs: dom => ({
                  [DATA_OBOARD_UID]: dom.getAttribute(DATA_OBOARD_UID)
                })
              }
            ]
          },
          renderHTML({ HTMLAttributes }) {
            // Do not change this name and structure
            return ['table', mergeAttributes(HTMLAttributes), ['tbody', 0]]
          },
          addNodeView() {
            return VueNodeViewRenderer(CustomTableTiptap)
          }
        }),
        TableHeader.extend({
          name: 'tableHeader',
          addAttributes() {
            return {
              ...this.parent?.(),
              [DATA_OBOARD_UID]: {
                default: '',
                parseHTML: element => element.getAttribute(DATA_OBOARD_UID),
                renderHTML: attributes => {
                  return {
                    [DATA_OBOARD_UID]: attributes[DATA_OBOARD_UID]
                  }
                }
              }
            }
          },
          parseHTML() {
            return [
              {
                // Do not change this name its reserved name for correct parsing HTML. If you change this name it will be break all saved table in each objective
                tag: 'vue-component-oboard-header',
                getAttrs: dom => ({
                  [DATA_OBOARD_UID]: dom.getAttribute(DATA_OBOARD_UID)
                })
              }
            ]
          },
          renderHTML({ HTMLAttributes }) {
            // Do not change this name and structure
            return ['th', ['vue-component-oboard-header', mergeAttributes(HTMLAttributes), 0]]
          },
          addNodeView() {
            return VueNodeViewRenderer(CustomTableCellTiptap)
          }
        }),
        TableRow,
        TableCell.extend({
          name: 'tableCell',
          addAttributes() {
            return {
              ...this.parent?.(),
              [DATA_OBOARD_UID]: {
                default: '',
                parseHTML: element => element.getAttribute(DATA_OBOARD_UID),
                renderHTML: attributes => {
                  return {
                    [DATA_OBOARD_UID]: attributes[DATA_OBOARD_UID]
                  }
                }
              }
            }
          },
          parseHTML() {
            return [
              {
                // Do not change this name its reserved name for correct parsing HTML. If you change this name it will be break all saved table in each objective
                tag: 'vue-component-oboard-cell',
                getAttrs: dom => ({
                  [DATA_OBOARD_UID]: dom.getAttribute(DATA_OBOARD_UID)
                })
              }
            ]
          },
          renderHTML({ HTMLAttributes }) {
            // Do not change this name and structure
            return ['td', ['vue-component-oboard-cell', mergeAttributes(HTMLAttributes), 0]]
          },
          addNodeView() {
            return VueNodeViewRenderer(CustomTableCellTiptap)
          }
        }),
        CharacterCount.configure({
          limit: this.maxLength
        }),
        Placeholder.configure({
          placeholder: this.placeholder
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention'
          },
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
          },
          suggestion: {
            items: async ({ query }) => {
              let users = []
              const usersApi = new UsersApi()

              if (!this.active) return
              try {
                users = await usersApi.getUsersForMentioning({
                  searchString: query,
                  workspaceId: this.workspaceId
                })
                this.addUsersFromList(users)
              } catch (error) {
                handleError({ error })
              }
              return users
            },
            render: () => {
              let component
              let popup

              return {
                onStart: props => {
                  if (!props.clientRect) return
                  const clientRect = props.clientRect()
                  // https://tiptap.dev/examples/suggestions
                  if (
                    clientRect.x === 0 &&
                    clientRect.y === 0 &&
                    clientRect.width === 0 &&
                    clientRect.height === 0
                  ) {
                    // something gone wrong, don't display a popup
                    // it's a case when user deletes mention and also quickly
                    // '@' symbol at the beginning, request for mention suggestions
                    // is sent, but response come after mention onExit lifycecle hook
                    // and mention suggestions are shown, however shouldn't
                    return
                  }
                  component = new VueRenderer(MentionList, {
                    props,
                    editor: props.editor
                  })
                  this.editorComponent = component

                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () =>
                      this.appendMentionToBody ? document.body : this.$refs.editorRef,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                    theme: 'light no-padding',
                    arrow: false
                  })
                  this.editorPopup = popup
                },
                onUpdate(props) {
                  if (!props.clientRect) return

                  if (!component) return

                  component.updateProps(props)
                  if (!(popup && popup[0])) {
                    return
                  }

                  if (popup[0].popperInstance) {
                    popup[0].popperInstance.reference = {
                      clientWidth: 0,
                      clientHeight: 0,
                      getBoundingClientRect() {
                        return props.clientRect()
                      }
                    }
                  }
                },
                onKeyDown: props => {
                  return component?.ref?.onKeyDown(props)
                },
                onExit() {
                  if (popup && popup[0]) {
                    popup[0].destroy()
                  }
                  if (component) {
                    component.destroy()
                  }
                  this.editorPopup = null
                  this.editorComponent = null
                }
              }
            }
          }
        })
      ],

      onUpdate: ({ editor }) => {
        this.localValue = editor.getHTML()
      },

      onSelectionUpdate: ({ editor }) => {
        // select value should be updated manually
        const [textFormat] = [
          editor.isActive('heading', { level: 1 }) && 1,
          editor.isActive('heading', { level: 2 }) && 2,
          editor.isActive('heading', { level: 3 }) && 3,
          editor.isActive('heading', { level: 4 }) && 4,
          editor.isActive('heading', { level: 5 }) && 5,
          editor.isActive('heading', { level: 6 }) && 6,
          'paragraph'
        ].filter(Boolean)
        this.textFormat = textFormat
      }
    })
    this.active = this.showOnInit
  },

  beforeUnmount() {
    this.$el.removeEventListener('keydown', this.keyListener)
    this.editor.destroy()
    removeKeydownEventListener('esc', this.onEsc)
  },

  methods: {
    ...mapActions('objectives', {
      addUsersFromList: 'addUsersFromList',
      getUsersForMentioning: 'getUsersForMentioning'
    }),

    async replyOnComment(payload) {
      await this.$nextTick()
      if (this.editor) {
        this.editor
          .chain()
          .focus()
          .insertContent({
            type: 'mention',
            attrs: {
              id: payload.accountId,
              label: payload.userName
            }
          })
          .insertContent(' ')
          .run()
      }

      await this.$nextTick()
      this.editor?.commands.focus()
    },

    onClickDescriptionField(e) {
      if (e.target.href) {
        this.editor.commands.blur() // for blur editor when link opening
      } else if (!e.target.href && !this.readonly) {
        this.showEditor()
      }
    },

    execAction(action) {
      this[action]()
    },

    onConfirmSetUrl() {
      if (isStringEmpty(this.url)) {
        return
      }

      let validatedUrl = this.url
      if (!this.url.includes('://')) {
        validatedUrl = 'https://'.concat(this.url)
      }

      this.setLink(validatedUrl)
      this.url = ''
    },

    insertTable() {
      // Hardbreak need for create <p> tag before table, its needed because its a lot of difficult to blur the table
      this.editor.chain().focus().setHardBreak().run()
      this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
    },

    insertLink() {
      this.showUrlModal = true
    },

    insertCodeSnippet() {
      this.editor.chain().focus().toggleCode().run()
    },

    insertMention() {
      this.editor.chain().focus().insertContent('@').run()
    },

    insertQuote() {
      this.editor.chain().focus().toggleBlockquote().run()
    },

    showEmojiPicker() {
      this.$refs.emojiPickerRef.opened = true
    },

    getColorAttribute(attribute) {
      return this.editor?.getAttributes(attribute)?.color
    },

    onTextColorChange(value) {
      if (this.getColorAttribute('textStyle') === value) {
        this.editor.chain().focus().unsetColor().run()
      } else {
        this.editor.chain().focus().setColor(value).run()
      }
      this.$refs.textColorPicker.hideDropdown()
    },

    showEditor() {
      if (!this.readonly) {
        this.active = true
        this.focus()
      }
    },

    onCancel() {
      this.onEsc({ focus: false })
      this.$emit('cancel')
      this.active = false
    },

    onSave() {
      this.$emit('save')
      if (this.blurOnSave) {
        this.blur()
      }
    },

    setLink(url) {
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
      this.showUrlModal = false
    },

    onMoreFormatChange(option, onSelect) {
      const { value } = option
      onSelect(value)
      if (value === moreFormatsActions.CLEAR) {
        this.editor.chain().focus().clearNodes().unsetAllMarks().run()
      } else {
        if (value === moreFormatsActions.SUBSCRIPT && this.editor.isActive('superscript')) {
          this.editor.chain().focus().unsetSuperscript().run()
        } else if (value === moreFormatsActions.SUPERSCRIPT && this.editor.isActive('subscript')) {
          this.editor.chain().focus().unsetSubscript().run()
        }
        this.editor.chain().focus()[`toggle${value}`]().run()
      }
    },

    insertEmoji(value) {
      this.editor.commands.insertContent(value)
      this.editor.chain().focus()
    },

    setTextFormat(option, onSelect) {
      const { value } = option
      this.textFormat = value

      onSelect(option)
      if (value === 'paragraph') {
        this.editor.chain().focus().setParagraph().run()
      }
      this.editor.chain().focus().toggleHeading({ level: value }).run()
    },

    /** @public */
    focus() {
      this.active = true
      this.$nextTick(() => setTimeout(() => this.editor.chain().focus().run(), 100))
    },

    blur() {
      this.active = false
      this.$nextTick(() =>
        setTimeout(() => {
          this.editor.chain().blur().run()
        }, 100)
      )
    },

    async fixMentions() {
      let content = this.localValue
      const accountIds = getIdsInMentions(content)
      const users = await this.getUsersForMentioning({
        accountIds,
        workspaceId: this.workspaceId
      })
      content = resolveMentions(content, accountIds, users)
      this.editor?.commands.setContent(content, false)
    },

    onEsc() {
      let closed = false
      if (this.editorPopup && this.editorPopup[0]) {
        this.editorPopup[0].destroy()
        closed = true
        this.editorPopup = null
      }
      if (this.editorComponent) {
        this.editorComponent.destroy()
        closed = true
        this.editorComponent = null
      }

      if (!closed) {
        // blur manually instead of blur method because text editor should be blurred
        // only and kept active
        this.editor.commands.blur()
      }

      if (closed) {
        return false
      } else if (this.active) {
        this.$emit('cancel')
        this.active = false
        return false
      }
    },

    keyListener(event) {
      /* Jira Server has own shortcuts, and they raise even in editor. Prevent this behavior. */
      // duplicate keyCode with key, because in keypress(at least in Firefox 93) keyCode is always 0
      event.stopPropagation()
      event.stopImmediatePropagation()
    }
  }
})
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
%field-hover {
  &:hover {
    &:not(.df-Editor-readonly &) {
      &:after {
        cursor: text;
        background-color: $grey-3-next;
      }
    }
  }
  &:not(.df-Editor-active &) {
    &:after {
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      left: -10px;
      top: 0;
      border-radius: $border-radius-sm-next;
      z-index: 1;
      width: calc(100% + 20px);
      pointer-events: none;
    }
  }
}

.df-Editor {
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow-wrap: break-word;
  border-radius: $border-radius-md-next;

  &-active {
    padding: 10px;
    border: 2px solid $grey-3-next;
  }
}

.df-Content {
  display: flex;
  flex: 1 1 auto;
  height: min-content;
  border-radius: $border-radius-md;
  position: relative;
  cursor: text;

  @extend %field-hover;

  .df-Editor-styled & {
    padding: 12px 0;
  }

  .df-Editor-active & {
    padding: 0 10px 15px;
  }

  .df-Editor-active:hover & {
    background-color: transparent;
    cursor: initial;
  }
}

.df-ActionsAndCounter {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
}

.df-Actions {
  display: flex;

  &_Item {
    margin-right: 8px;
  }
}

.df-Toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 8px;
}

.df-ToolbarSplitter {
  width: 1px;
  height: 24px;
  background-color: $grey-medium;
}

.df-Placeholder {
  color: $dark-3;
  padding: 12px 0;
  border-radius: $border-radius-md-next;
  position: relative;
  cursor: text;

  @extend %field-hover;

  &_Text {
    position: relative;
    z-index: 5;
  }
}

.df-DescriptionCounter {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  font-family: $system-ui;
  color: $grey-1-next;

  &-negative {
    color: $grade-low-color-next;
  }
}
</style>

<style lang="scss">
@import '~@/assets/styles/mixins';

.df-EditorContent {
  width: 100%;
  word-break: break-word;
  position: relative;
  z-index: 2;
  &:not(.df-Editor-active &) {
    cursor: text;
  }

  ul {
    padding-left: 18px;
    margin-bottom: 0;
  }

  ol {
    padding-left: 16px;
    margin: 0;
  }
  p[data-placeholder] {
    caret-color: $primary-color-next;
    &.is-editor-empty:first-child::before {
      color: $grey-1-next;
    }
  }

  p {
    margin-bottom: 0;
  }

  * + p {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: $grey-10;
    pointer-events: none;
    height: 0;
  }

  span.mention {
    @extend %mention-item;
  }
}

.df-DescriptionField {
  margin-bottom: 8px;
  font-family: $system-ui;

  .df-TextFormats {
    min-width: 125px;

    .df-TextFormats_DroplistButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      border-color: transparent;
      min-height: 32px;
      color: $dark-grey;
      font-weight: fw('semi-bold');
      border-radius: 6px;
      padding: 4px 4px 4px 12px;
      font-family: $system-ui;
      cursor: pointer;
      &:hover {
        background-color: $grey-3-next;
      }

      .df-DroplistButton_Text {
        color: $dark-grey;
      }
      .df-DroplistButton_Icon {
        transition: transform $transition-fast ease;
      }
      &-active {
        background: $dark-2;
        color: $white;
        .df-DroplistButton_Icon {
          transform: rotate(180deg);
        }
        &:hover {
          @include hoverState($dark-2);
        }
        .df-DroplistButton_Text {
          color: $white;
        }
      }
      &-focused {
        background: $dark-grey;
        color: $white;

        .as-AppDroplistButton_Text {
          color: $white;
        }

        &:hover {
          border-color: transparent;
          background: $dark-grey;
        }
      }
    }
  }
}

.ProseMirror blockquote,
.ci-Message blockquote {
  padding: 10px 20px 10px 16px;
  border-left: 2px solid $grey-11;
}

.ProseMirror blockquote {
  margin: 14px;
}

.ci-Message blockquote {
  margin: 0 0 4px;
}

.df-ColorTextWrapper {
  position: relative;

  .df-ColorTextWrapper_Marker {
    position: absolute;
    left: 3px;
    right: 0;
    bottom: 3px;
    margin: 0 0 0 2px;
    width: 11px;
    height: 3px;
    border-radius: 3px;
    pointer-events: none;
    background: linear-gradient(to right, #172b4d, #36b27e, #0052cc, #f64963);
  }
}

.df-ColorTextWrapper_OptionWrapper {
  border: 2px solid transparent;
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;

  &:hover {
    border-color: $grey-2-next;
  }
}

.df-ColorTextWrapper_Option {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  height: 22px;
  width: 22px;
  border-radius: $border-radius-md;
  border: 1px solid rgba(23, 43, 77, 0.12);
  cursor: pointer;
}

.df-ColorText_ContentOptions {
  .as-UngroupedItems {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 2px;
    justify-content: space-between;
  }
}

.tableWrapper {
  overflow-x: auto;
}

.df-SimpleViewList {
  display: flex;
  align-items: center;
  gap: 8px;
}

.df-TextFormats_Heading {
  padding: 12px 10px;
  cursor: pointer;

  &:hover,
  &-active {
    background-color: rgba($primary-color-next, 0.15);
  }

  &-disabled {
    pointer-events: none;
    background-color: transparent;
    color: $grey-semi-medium;

    &:active {
      cursor: not-allowed;
    }
  }

  &-paragraph {
    @include setHeading($fs-14, 20px, fw('regular'));
  }

  &-1 {
    @include setHeading($fs-24, 28px);
  }

  &-2 {
    @include setHeading($fs-20, 28px);
  }

  &-3 {
    @include setHeading($fs-18, 20px);
  }

  &-4 {
    @include setHeading($fs-16, 20px);
  }

  &-5 {
    @include setHeading($fs-14, 20px);
  }

  &-6 {
    @include setHeading($fs-12, 16px, fw('bold'));
    color: $grey-semi-medium;
  }
}

.df-Toolbar_LinkWrapper {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.df-LinkWrapper {
  &_Input {
    min-width: 460px;
  }
}
</style>

<style lang="scss">
@import '~@/assets/styles/tiptap-styles';
</style>
