<template>
  <portal to="modal-windows">
    <AppDialog :show="show" :title="$t('action.share')" hide-hero @on-close="close">
      {{ $t('common.copy_link_description') }}

      <FormFieldNext :label="$t('common.direct_link')" class="sl-LinkWrapper" @click="onCopyLink">
        <div :class="{ 'sl-Link-copied': isCopied }" class="sl-Link oboard-truncated-text">
          {{ link }}
        </div>
      </FormFieldNext>

      <template #footer-actions>
        <AppButton height="24" icon="link" type="primary-next" width="24" @click="onCopyLink">
          {{ $t('breadcrumb.copy_link') }}
        </AppButton>
      </template>
    </AppDialog>
  </portal>
</template>

<script setup>
import { Portal } from 'portal-vue'
import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { ROUTE_PATHS } from '@/routes/route-helpers'
import { getExternalLinkHelper } from '@/utils/external-link-helper'
import { copyToClipboard } from '@/utils/general'
import { showNotify } from '@/utils/notify'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'ShareLink'
})

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },

  token: {
    type: String,
    default: ''
  }
})

watch(
  () => props.show,
  newValue => {
    if (!newValue) {
      isCopied.value = false
    }
  }
)

const emit = defineEmits({ 'update:show': null })

const isCopied = ref(false)

const link = computed(
  () => getExternalLinkHelper({ appPlatform }) + `/${ROUTE_PATHS.SHARE}/` + props.token
)

const appPlatform = inject('appPlatform')

const close = () => {
  emit('update:show', false)
}
const { t } = useI18n()

const onCopyLink = () => {
  copyToClipboard(link.value)
  isCopied.value = true
  showNotify({ title: t('common.link_copied') })
}
</script>

<style lang="scss" scoped>
.sl-Link {
  border-radius: $border-radius-sm-next;
  padding: 10px;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  color: $dark-1;

  &:not(&-copied) {
    background-color: $grey-3-next;
  }

  &-copied {
    background-color: rgba($grade-high-color-next, 0.1);
  }
}

.sl-LinkWrapper {
  margin-top: 20px;
}
</style>
