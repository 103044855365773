import { getAppType } from '@/util'
import { isFirefox } from '@/utils/general'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { FILTER_DEFAULT_VALUES, FILTERS_KEYS } from '@/utils/okr-elements/filters'
import {
  EDIT_ELEMENT_QUERY_KEYS,
  EDIT_WORKSPACE_QUERY_KEY,
  OPENED_NOTIFICATIONS_KEY,
  REFERER
} from '@/utils/query-parameters'
import { getAtlassianBaseUrl, getAtlassianIssueUrl, JIRA_CLOUD_API } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'
import { APP_PLATFORMS } from '@root/app-platforms'

export const CONFLUENCE_MACRO_MODULE_TYPE = 'dynamicContentMacros'

const getRefererModuleName = ({ isJiraApp = true }) => {
  const NO_REFERER = null
  if (isJiraApp) {
    if (JIRA_CLOUD_API) {
      const moduleName = JIRA_CLOUD_API._data.options.moduleType

      const REFERRERS = {
        jiraDashboardItems: 'Dashboard',
        webPanels: 'Jira issue',
        dynamicContentMacros: 'Confluence gadget'
      }

      return REFERRERS[moduleName] || NO_REFERER
    } else {
      return NO_REFERER
    }
  }

  return NO_REFERER
}

export const OBOARD_CLOUD_PLUGIN_ROUTE = '/plugins/servlet/ac/okrplugin_prod/okrboard-company/#!'

const getOkrElementsListQuery = ({ predefinedParams = {}, withEmptyFilters = false }) => {
  const defaultQuery = Object.entries(predefinedParams).reduce((acc, [key, value]) => {
    const query = `${key}=${value}`
    return acc ? `${acc}&${query}` : `${query}`
  }, '')

  if (withEmptyFilters) {
    const excludedParams = Object.keys(predefinedParams)

    const emptyFiltersQuery = Object.entries(FILTER_DEFAULT_VALUES)
      .filter(([key]) => {
        return !excludedParams.includes(key)
      })
      .reduce((acc, [key, value]) => {
        const valueForQuery = Array.isArray(value) ? JSON.stringify(value) : value
        const query = `${key}=${valueForQuery}`
        return acc ? `${acc}&${query}` : `${query}`
      }, defaultQuery)

    return emptyFiltersQuery
  }

  return defaultQuery
}

//     intervalId && `${FILTERS_KEYS.INTERVAL_IDS}=[${intervalId}]`,
export const createQueryForExternalLink = ({
  id,
  typeId,
  intervalId,
  levelId,
  source,
  withEmptyFilters = false,
  isJiraApp = true
}) => {
  const [queryParameter] = [
    typeId === OBJECTIVE_TYPES.PERSONAL && `${EDIT_ELEMENT_QUERY_KEYS.EDIT_OBJECTIVE}=${id}`,
    typeId === OBJECTIVE_TYPES.KR && `${EDIT_ELEMENT_QUERY_KEYS.EDIT_KR}=${id}`,
    typeId === OBJECTIVE_TYPES.TASK && `${EDIT_ELEMENT_QUERY_KEYS.EDIT_LINK_JIRA_ISSUE}=${id}`,
    intervalId &&
      getOkrElementsListQuery({
        predefinedParams: {
          [FILTERS_KEYS.INTERVAL_IDS]: JSON.stringify([intervalId])
        },
        withEmptyFilters
      }),
    levelId && `${EDIT_ELEMENT_QUERY_KEYS.EDIT_OBJECTIVE}=${id}&levelId=${levelId}`
  ].filter(Boolean)
  const referer = getRefererModuleName({ isJiraApp })
  let composedQueryParameter = queryParameter ? `?${queryParameter}` : ''

  if (
    !composedQueryParameter &&
    source === EXTERNAL_LINK_HANDLER_SOURCES.WORKSPACE_NAME_CELL &&
    withEmptyFilters
  ) {
    composedQueryParameter = `?${getOkrElementsListQuery({ withEmptyFilters })}`
  }

  if (!!referer && !!composedQueryParameter) {
    composedQueryParameter = `${composedQueryParameter}&${REFERER}=${referer}`
  }

  if (isFirefox() && source === EXTERNAL_LINK_HANDLER_SOURCES.COMMENT && !!composedQueryParameter) {
    composedQueryParameter = `${composedQueryParameter}&${OPENED_NOTIFICATIONS_KEY}=true`
  }

  return composedQueryParameter
}

export const EXTERNAL_LINK_HANDLER_SOURCES = {
  COMMENT: 'comment',
  INTERVALS_PAGE: 'intervals-page',
  WORKSPACE_NAME_CELL: 'workspace-name-cell',
  JIRA_DASHBOARD_GADGET: 'jira-dashboard-gadget',
  JIRA_ISSUE_WIDGET: 'jira-issue-widget',
  CONFLUENCE_MACRO: 'confluence-macro',
  JIRA_ISSUE_LINK: 'jira-issue-link',
  USER_DETAILS_PAGE: 'user-details-page'
}

const getHashFromLocation = () => {
  return location.hash.startsWith('#') ? '#' : ''
}

export const openLink = (url, target = '_blank') => {
  window.open(url, target)
}

export const getDefaultAppRoute = ({ workspaceId, query }) => {
  if (!String(workspaceId)) {
    throw new Error('workspaceId is required')
  }
  return `/workspaces/${workspaceId}/objectives/alignment/table${query}`
}

export const getExternalLinkHelper = ({ appPlatform = APP_PLATFORMS.JIRA }) => {
  const { isJiraApp, isWebApp } = getAppType({ appPlatform })
  const atlassianBaseUrl = getAtlassianBaseUrl()

  if (IS_DEVELOPMENT) {
    return `${location.origin}${getHashFromLocation()}`
  }

  if (isJiraApp) {
    return `${atlassianBaseUrl}${OBOARD_CLOUD_PLUGIN_ROUTE}`
  } else if (isWebApp) {
    return `${location.origin}${getHashFromLocation()}`
  }
}

/**
 * Handle external link from app part on some source to another app part on main source
 *
 * @param {{ id: [number, string, undefined],
 * typeId: [number, string, null],
 * intervalId: [number, string],
 * workspaceId: [number, string, null],
 * source: string
 * }} params for creating external link payload, source — source where link was clicked (required)
 * @returns {void} open link in new tab or redirect to link
 */
export const externalLinkHelper = ({
  id,
  typeId,
  intervalId,
  levelId,
  workspaceId,
  source,
  withEmptyFilters = false,
  appPlatform = APP_PLATFORMS.JIRA
}) => {
  const { isJiraApp, isWebApp } = getAppType({ appPlatform })
  const atlassianBaseUrl = getAtlassianBaseUrl()
  const composedQueryParameter = createQueryForExternalLink({
    id,
    typeId,
    intervalId,
    levelId,
    source,
    withEmptyFilters,
    isJiraApp
  })

  const DEFAULT_APP_ROUTE = getDefaultAppRoute({ workspaceId, query: composedQueryParameter })

  const APP_ROUTE =
    source === EXTERNAL_LINK_HANDLER_SOURCES.USER_DETAILS_PAGE
      ? `/workspaces/settings/workspaces/?${EDIT_WORKSPACE_QUERY_KEY}=${workspaceId}`
      : DEFAULT_APP_ROUTE

  if (IS_DEVELOPMENT) {
    showNotify({
      type: NOTIFICATION_TYPES.WARNING,
      content:
        'You are in <b>DEVELOPMENT</b> mode, so your link will be opened on <b>localhost</b> domain. <br>If You want to test real functionality of <pre>externalLinkHelper</pre> please comment <b>this condition</b>.',
      expanded: true,
      isHtml: true
    })
    setTimeout(() => {
      openLink(`${location.origin}${getHashFromLocation()}${APP_ROUTE}`)
    }, 200)

    return
  }

  if (isJiraApp) {
    const {
      COMMENT,
      INTERVALS_PAGE,
      WORKSPACE_NAME_CELL,
      JIRA_DASHBOARD_GADGET,
      JIRA_ISSUE_WIDGET,
      CONFLUENCE_MACRO,
      JIRA_ISSUE_LINK,
      USER_DETAILS_PAGE
    } = EXTERNAL_LINK_HANDLER_SOURCES

    if (JIRA_CLOUD_API) {
      if (!source) {
        throw new Error('Argument [source] is required')
      }

      const isHistoryHandler = [
        COMMENT,
        INTERVALS_PAGE,
        WORKSPACE_NAME_CELL,
        USER_DETAILS_PAGE
      ].includes(source)

      const isNavigatorHandler = [
        JIRA_DASHBOARD_GADGET,
        JIRA_ISSUE_WIDGET,
        CONFLUENCE_MACRO
      ].includes(source)

      // specific case for JIRA issue link all browsers except firefox open in new tab by <a href>
      // tag, but we have problem with firefox defence mechanism so for firefox we use
      // JIRA_CLOUD_API method. But this method works only for JIRA application.
      // Confluence is not JIRA application,
      // so we check if we are in Confluence and use navigator method in this case only in Firefox

      if (source === JIRA_ISSUE_LINK) {
        if (JIRA_CLOUD_API._data?.options?.moduleType === CONFLUENCE_MACRO_MODULE_TYPE) {
          JIRA_CLOUD_API.navigator.go('site', {
            absoluteUrl: `${atlassianBaseUrl}/browse/${id}`
          })
        } else {
          JIRA_CLOUD_API.navigator.go('issue', { issueKey: id })
        }
        return
      }

      /** Why we don't use window.open method for all cases?
       * Because fucking Firefox has a stupid defence mechanism
       * when open new tab from iframe to iframe Firefox blocks form fields:
       * (inputs, textarea, buttons, radios, etc) for interaction, so we can't click or focus on them
       */

      if (isFirefox()) {
        /**
         * Why we don't use JIRA_CLOUD_API.history.pushState() for all cases?
         * Because JIRA_CLOUD_API.history methods don't navigate to new url from same domain
         */

        if (isHistoryHandler) {
          JIRA_CLOUD_API.history.pushState(APP_ROUTE, '', APP_ROUTE)
          JIRA_CLOUD_API.history.go()
        }

        /**
         * Why we don't use JIRA_CLOUD_API.navigator.go() method for all cases?
         * Because JIRA_CLOUD_API.navigator.go() method doesn't work properly
         * with sources placed in another domain (e.g. jira dashboard items, jira issue sidebar, confluence)
         */

        if (isNavigatorHandler) {
          const isConfluence = source === CONFLUENCE_MACRO
          const navigatorPayloadKey = isConfluence ? 'absoluteUrl' : 'relativeUrl'
          const navigatorPayloadValue = isConfluence
            ? `${atlassianBaseUrl}${OBOARD_CLOUD_PLUGIN_ROUTE}${APP_ROUTE}`
            : `${OBOARD_CLOUD_PLUGIN_ROUTE}${APP_ROUTE}`

          JIRA_CLOUD_API.navigator.go('site', {
            [navigatorPayloadKey]: navigatorPayloadValue
          })
        }
      } else {
        openLink(`${atlassianBaseUrl}${OBOARD_CLOUD_PLUGIN_ROUTE}${APP_ROUTE}`)
      }
    } else {
      if (source === JIRA_ISSUE_LINK) {
        openLink(getAtlassianIssueUrl(id))
      } else {
        openLink(`${atlassianBaseUrl}${APP_ROUTE}`)
      }
    }
  } else if (isWebApp) {
    openLink(`${location.origin}${getHashFromLocation()}${APP_ROUTE}`)
  }
}
