export default {
  "action.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar"])},
  "action.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
  "action.revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revogar"])},
  "action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
  "action.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "action.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "action.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
  "action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "action.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
  "action.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
  "action.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
  "action.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrelar"])},
  "action.restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar"])},
  "create.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar Novo Objetivo"])},
  "create.objective.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Objetivo"])},
  "create.objective.btn.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
  "create.keyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do resultado chave"])},
  "create.keyResult.label.originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
  "create.keyResult.label.currentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atual"])},
  "create.keyResult.label.targetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
  "create.keyResult.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado chave criado"])},
  "create.task.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
  "create.task.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item do Jira atrelado"])},
  "field.labels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
  "field.labels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Etiqueta"])},
  "field.assignee.stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investidores"])},
  "filed.assignee.stakeholders.plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar investidor..."])},
  "field.group.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "field.group.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um grupo"])},
  "field.status.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "field.quoter.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval"])},
  "field.quoter.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um Intervalo"])},
  "field.parentObjective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo Pai"])},
  "field.issue.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque um item Jira por nome ou chave"])},
  "field.issue.jql_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloque sua query JQL"])},
  "field.select.nooptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem opções"])},
  "field.select.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando"])},
  "field.contribute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuir para a nota do pai"])},
  "field.workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O none deve ter no minímo 2 caracteres"])},
  "field.workspace_key_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave deve ter 1-3 caracteres"])},
  "field.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])},
  "field.workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área de trabalho"])},
  "fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos obrigatórios"])},
  "users.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "users.table_header_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
  "objectives.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos e Resultados Chave"])},
  "objectives.table_header_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
  "objectives.table_header_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "objectives.table_header_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsável"])},
  "objectives.table_header_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo"])},
  "objectives.table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "objectives.table_header_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "objectives.table_header_duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término"])},
  "objectives.table_header_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas"])},
  "objectives.krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRs"])},
  "objectives.unavailable_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem acesso a esse objetivo. Por favor contate o seu administrador do Jira."])},
  "objectives.type_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
  "objectives.type_PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoal"])},
  "objectives.type_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "objective.btn.custom_weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanhos personalizados"])},
  "objective.link_to_parent_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrelar para OKR pai"])},
  "objective.add_key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar resultado chave"])},
  "objective.btn.add_nested_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar objetivo aninhado"])},
  "objective.modal_title.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o nome do objetivo"])},
  "objective.activity_log_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há registro de atividades"])},
  "objective.child_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos filhos"])},
  "objective.key_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados chaves"])},
  "objective.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicada:"])},
  "objective.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanhos"])},
  "objective.a_moment_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Há pouco tempo"])},
  "objective.hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas atrás"])},
  "objectives.days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dias atrás"])},
  "objective.search_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por ID ou nome"])},
  "objective.no_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem responsável"])},
  "objectives.due_date.end_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim do Intervalo"])},
  "objective.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizar"])},
  "objective.level_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nível OKR está faltando. Entre em contato conosco em <a href='mailto:support", "@", "oboard.io'><strong>support", "@", "oboard.io</strong></a>"])},
  "objective.sorting_switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificar filho"])},
  "objective.btn.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem direitos de somente leitura"])},
  "objective.chart_table.split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividir por itens de contribuição"])},
  "objective.chart.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso"])},
  "objective.chart.fullSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho cheio"])},
  "objective.chart.no_items_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem itens selecionados"])},
  "intervals.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloque seus itens no backlog se o intervalo não estiver definido para eles."])},
  "kr.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens aninhados"])},
  "kr.add_nested_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar item aninhado"])},
  "intervals.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aberto"])},
  "intervals.status.archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivado"])},
  "intervals.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "intervals.new_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo intervalo"])},
  "intervals.create_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo intervalo"])},
  "intervals.edit_interval": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editar intervalo ", _interpolate(_named("intervalName"))])},
  "workspaces.new_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova área de trabalho"])},
  "workspaces.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciuar áreas de trabalho"])},
  "workspaces.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áreas de trabalho"])},
  "workspaces.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar área de trabalho"])},
  "workspaces.workspace_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áreas de trabalho criada."])},
  "workspaces.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nossa área de trabalho é um lugar dedicado para gerenciar seus OKR's. Você pode usar as áreas de trabalho para medir o progresso de diferentes produtos ou departamentos."])},
  "workspaces.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação"])},
  "workspace.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da área de trabalho"])},
  "workspace.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave"])},
  "workspaces.key.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A chave é usada como prefixo dos seus objetios e resultados chave dentro da sua área de trabalho ex: TE-O-203"])},
  "workspaces.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Área de trabalho"])},
  "workspaces.private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privada"])},
  "workspaces.public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pública"])},
  "workspaces.visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidade"])},
  "groups.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo encontrado"])},
  "groups.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar grupo"])},
  "groups.new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo grupo"])},
  "groups.users_tab.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de usuários. O grupo padrão será definido automaticamente quando um usuário criar OKR e para o filtro “Meu Grupo“"])},
  "groups.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar grupo"])},
  "group.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do grupo"])},
  "group.select_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a área de trabalho"])},
  "users.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Usuário"])},
  "users.change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar cargo"])},
  "users.change_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar cargo padrão"])},
  "user.selected_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo selecionado"])},
  "user.group.sync.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O grupo não pode ser selecionado pois importa usuários de grupos associados ao ", _interpolate(_named("platform")), "."])},
  "admins.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores do plugin podem criar, deletar e alterar áreas de trabalho, grupos, convidar pessoas e ver todos os OKR's em todas áreas de trabalho."])},
  "create.admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar administrador"])},
  "admin.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
  "filter.search_okr_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar OKR"])},
  "filter.manage_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar Intervalos"])},
  "filter.all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos grupos"])},
  "filter.all_grade_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos status"])},
  "filter.all_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos cargos"])},
  "filter.all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos usuários"])},
  "filter.all_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos responsáveis"])},
  "filter.all_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas etiquetas"])},
  "filter.all_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos investidores"])},
  "filter.expand_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir Todos"])},
  "filter.collapse_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colapsar Todos"])},
  "filter.export_xls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar *.xlsx"])},
  "filter.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar link"])},
  "filter.no_objective": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nenhum OKR foi encontrado pela sua busca. ", _interpolate(_named("break")), " Tente modificar os parametros da sua busca ou criar um objetivo."])},
  "filter.no_objective_jira_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum OKR foi encotrado na sua busca. Tenta modificar os parametros de busca."])},
  "filter.no_objective_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem nenhum Objetivo nesse intervalo"])},
  "filter.this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa semana"])},
  "filter.this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esse mês"])},
  "filter.custom_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período personalizado"])},
  "filter.show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todos"])},
  "filter.due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Término"])},
  "filter.all_are_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos objetivos estão filtrados "])},
  "filter.show_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra-los"])},
  "search_criteria.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar critérios de busca"])},
  "filter.hide_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder KR's"])},
  "filter.show_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar KR's"])},
  "filter.sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])},
  "filter.clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar todos"])},
  "filter.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsáveis"])},
  "filter.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "filter.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "filter.statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "filter.levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níveis"])},
  "filter.okr_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nível OKR"])},
  "filter.more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Filtros"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecionado"])},
  "dropdown.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
  "dropdown.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso customizavel"])},
  "dropdown.sync_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar agora"])},
  "dropdown.export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar CSV"])},
  "confirm.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrelar"])},
  "confirm.dontLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não atrelar"])},
  "confirm.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar Objetivo"])},
  "confirm.objective.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar objetivos filho"])},
  "confirm.kr.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover KR"])},
  "confirm.user.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem certeza que deseja deletar o usuário?"])},
  "confirm.admin.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remover ", _interpolate(_named("userName")), " dos administradores"])},
  "confirm.admin.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você tem certeza que deseja remover o ", _interpolate(_named("userName")), " dos administradores? O usúario não vai ser removido do plugin se ele estiver adicionado em pelo menos uma área de trabalho."])},
  "delete_confirm_workspace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar área de trabalho"])},
  "delete_confirm_interval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar Intervalo"])},
  "delete_confirm_interval_input_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Coloque o ", _interpolate(_named("keyword")), " para confirmar"])},
  "delete_confirm_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revogar chave de API"])},
  "delete_confirm_token_message_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem certeza que deseja remover o acesso da sua chave de API"])},
  "delete_confirm_token_message_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após revogar o acesso de uma chave de API não é possível voltar atrás"])},
  "users.roles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargos"])},
  "menu.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos"])},
  "menu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
  "menu.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "menu.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de Trabalho"])},
  "menu.support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte"])},
  "menu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores"])},
  "menu.integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrações"])},
  "menu.api_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API tokens"])},
  "menu.okr_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
  "menu.my_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu OKR"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
  "menu.tooltip.collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder"])},
  "menu.tooltip.expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir"])},
  "menu.tooltip.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "tour.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo objetivo"])},
  "tour.step1.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui você pode criar um novo objetivo."])},
  "tour.step2.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar novo objetivo ou resultado chave"])},
  "tour.step2.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui você pode adicionar objetivos, resultados chave ou atrelar seus itens do Jira a um OKR."])},
  "tour.step3.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar a nota"])},
  "tour.step3.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui você pode mudar o progresso do seu KR. Progresso para itens jira vinculados é calculado automaticamente."])},
  "tour.step4.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar membros"])},
  "tour.step4.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar membros e gerenciar grupos."])},
  "support.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte"])},
  "support.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugestões de funcionalidade e comentários sobre nosso plugin são bem vindos"])},
  "support.response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nós tentamos responder a todos os chamado do suporte em até 1 dia útil. Oboard fica localizado na Europa Central"])},
  "support.support_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["horário de atendimento é ", _interpolate(_named("hours")), ", Segunda a sexta no fuso GMT+1"])},
  "support.plugin_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versão do plugin:"])},
  "support.security_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud security program"])},
  "breadcrumb.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar atrelação"])},
  "roles.not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não selecionado"])},
  "roles.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
  "roles.group_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líder do grupo"])},
  "roles.workspace_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador da área de trabalho"])},
  "roles.user_no_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário/não excluir"])},
  "roles.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitor"])},
  "dashboard.average_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méd. Total"])},
  "dashboard.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo"])},
  "dashboard.no_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum responsável ainda"])},
  "dashboard.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo ainda"])},
  "dashboard.assigned_to_all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuido a todos grupos"])},
  "dashboard.assigned_to_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuido ao grupo"])},
  "dashboard.across_all_the_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em todos os grupos"])},
  "dashboard.in_this_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessa área de trabalho"])},
  "dashboard.in_this_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesse grupo"])},
  "dashboard.avg_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méd. Progresso"])},
  "dashboard.all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas"])},
  "dashboard.interval_passed_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cyclePassedPercent")), "% percorrido"])},
  "dashboard.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos"])},
  "dashboard.people_with_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas com OKR"])},
  "dashboard.people_without_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas sem OKR's"])},
  "dashboard.no_linked_krs_nested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum KR atrelado, objetivo aninhado"])},
  "dashboard.jira_issue_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Jira Atrelado"])},
  "homepage.user_select.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros do time"])},
  "homepage.user_select.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades da empresa"])},
  "homepage.objectives.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você é responsável"])},
  "homepage.objectives.stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você é investidor"])},
  "homepage.objectives.watcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está acompanhando"])},
  "homepage.user_summary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso da minha equipe"])},
  "homepage.group_summary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas no time"])},
  "homepage.okr_elements_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nenhum objetivo ou resultado chave para o cargo ", _interpolate(_named("role")), "."])},
  "homepage.role.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["você é um proprietário"])},
  "homepage.role.stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["você é uma parte interessada"])},
  "homepage.role.watcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["você é um observador"])},
  "homepage.role.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o grupo é um proprietário"])},
  "homepage.notify.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Não foi possível encontrar o cargo ", _interpolate(_named("role")), " selecionado"])},
  "homepage.notify.role.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usúario"])},
  "tokens.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode usar sua chave de API para autenticar no OKR Board. Você deve tratar chaves de API com tanta segurança quanto senhas. Você tem um limite de 10 chaves de API que podem ser criadas."])},
  "tokens.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
  "tokens.no_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma chave de API"])},
  "token.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar chave de API"])},
  "token.create.label_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha uma etiqueta, isso é, um nome curto ou prefixo que fica fácil para você lembrar."])},
  "token.minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos atrás"])},
  "token.toast.revoke_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua chave de API foi inválidada"])},
  "token.copy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua nova chave de API"])},
  "token.copy.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenha certeza que salvou sua nova chave de API. Você não vai conseguir ver ela novamente."])},
  "pagination.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "pagination.of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "pagination.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responsáveis"])},
  "pagination.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registros"])},
  "link_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincule o item do Jira ao resultado chave"])},
  "link_jira_issue.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você deseja vincular o item Jira de contribuição ao resultado chave."])},
  "link_jira_issue.it_means": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso significa:"])},
  "link_jira_issue.list_item_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nota será calculada automaticamente de acordo com o status do item do jira."])},
  "link_jira_issue.list_item_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode alterar a nota manualmente, a menos que você desvincule o problema ou desative a opção de contribuição."])},
  "link_jira_issue.link_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular mesmo assim?"])},
  "link_jira_issue.jql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JQL"])},
  "link_jira_issue.jql_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação JQL inválida"])},
  "link_jira_issue.jira_issues_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " itens do Jira encontrados."])},
  "link_jira_issue.check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique os resultados na nova guia."])},
  "link_jira_issue.jql_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo limite do request"])},
  "interval.label.time_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de tempo"])},
  "interval.label.end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término"])},
  "status.switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O status mudará conforme o progresso e o prazo"])},
  "grade_info.total_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota total"])},
  "grade_info.your_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus KR's"])},
  "grade_info.filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens filtrados"])},
  "custom_weights.contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuir"])},
  "custom_weights.weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
  "custom_weights.impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impacto"])},
  "saving_indicator.saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvando..."])},
  "saving_indicator.saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvo"])},
  "levels.header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níveis de OKR"])},
  "levels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode criar quantos níveis desejar. O nível criado é mostrado em todos os filtros, disponíveis na API, exportações e claro para construir uma hierarquia personalizada"])},
  "levels.title.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar nível de OKR"])},
  "levels.title.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar nível do OKR"])},
  "levels.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do nível"])},
  "levels.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id (1-2)"])},
  "levels.color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cor"])},
  "levels.add_level_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar nível a esse espaço de trabalho"])},
  "levels.add_to_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar a todas os espaços de trabalho"])},
  "levels.delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deletar o nível ", _interpolate(_named("levelName"))])},
  "levels.convert_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Converter OKRs ", _interpolate(_named("levelName")), " para"])},
  "levels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ícone"])},
  "debug.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de depuração"])},
  "debug.migrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrações"])},
  "debug.migrate_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar tarefas"])},
  "debug.migrate_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar itens Jira"])},
  "debug.migrate_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar atributos"])},
  "debug.migrate_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migração de atributos de tarefas"])},
  "debug.migrate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar intervalos"])},
  "debug.migrate_intervals_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migração de intervalos"])},
  "debug.migrate_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar níveis"])},
  "debug.migrate_levels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migração de níveis"])},
  "debug.confirm_migration_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza que deseja iniciar a migração?"])},
  "debug.start_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar migração"])},
  "debug.restore_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar níveis"])},
  "debug.restore_levels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar níveis para as áreas de trabalho"])},
  "debug.clean_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar"])},
  "debug.clean_up_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar itens atrelados corrompidos"])},
  "debug.grade_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo da Nota"])},
  "debug.calculate_grades_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lançar notas recalculadas"])},
  "debug.calculate_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular notas"])},
  "debug.debug_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação de depuração"])},
  "debug.download_info_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar informações de depuração"])},
  "debug.download_logs_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar registros"])},
  "debug.download_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar"])},
  "debug.grades_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas atualizadas"])},
  "debug.restore_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar atributos"])},
  "debug.restore_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar atributos do elemento"])},
  "debug.remove_duplicated_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover intervalos duplicados"])},
  "debug.remove_users_without_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover usuários sem grupos"])},
  "debug.restore_interval_dates_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar intervalos e datas para os elementos"])},
  "debug.restore_users_and_groups_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar usuários e grupos para elementos"])},
  "debug.restore_jira_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar detalhes do Jira"])},
  "comments.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite ", "@", " para mencionar outros usuários"])},
  "comments.edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado"])},
  "banner.trial_subscribe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua licença de teste vai acabar. Por favor compre uma licença em ", _interpolate(_named("licenseDaysLeft")), " continuar usando OKR Board for Jira."])},
  "banner.license_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A licença expirou. Por favor renove sua licença para continuar usando o plugin por completo."])},
  "description.normal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto normal"])},
  "description.heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho 1"])},
  "description.heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho 2"])},
  "description.heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho 3"])},
  "description.heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho 4"])},
  "description.heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho 5"])},
  "description.heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho 6"])},
  "description.underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sublinhado"])},
  "description.strikethrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxado"])},
  "description.subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscrito"])},
  "description.superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrescrito"])},
  "description.clear_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar formatação"])},
  "description.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar descrição..."])},
  "table.no_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem itens"])},
  "settings.company.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações da empresa"])},
  "settings.company.dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações da dashboard"])},
  "settings.company.dashboard_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder dashboard dos usuários"])},
  "settings.company.dashboard_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando o recurso estiver ativado, a seção do painel ficará oculta para todos, exceto para o administrador do espaço de trabalho."])},
  "settings.company.homepage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração da página inicial do OKR"])},
  "settings.company.homepage_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueie a exploração do progresso de outras pessoas"])},
  "settings.company.homepage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando o recurso está ativado. A opção de verificar o progresso será desativada para um usuário regular. Somente administradores de WS e líderes de equipe poderão verificar o progresso."])},
  "settings.company.alignment_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração de hierarquia do OKR"])},
  "settings.company.alignment_settings_switch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Permitir que ", _interpolate(_named("objective")), " e ", _interpolate(_named("keyResult")), " sejam vinculados a resultados chave"])},
  "settings.company.default_states_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estados padrão"])},
  "settings.company.default_states_okr_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar contribuição para objetivos"])},
  "settings.company.default_states_okr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando essa funcionalidade está ativada, objetivos aninhados contribuem para o objetivo pai."])},
  "settings.company.default_states_tasks_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar contribuição para itens do Jira"])},
  "settings.company.default_states_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando o recurso está ativado, os itens aninhados do jira estão contribuindo para seus pais."])},
  "labels.switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir a criação de marcadores a partir de formulários OKR"])},
  "labels.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador"])},
  "labels.no_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda sem marcadores"])},
  "labels.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover marcador"])},
  "labels.delete_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de remover o marcador ", _interpolate(_named("levelName")), " de todos os objetivos e resultados chave?"])},
  "labels.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar marcador"])},
  "labels.toast.create_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador criado"])},
  "labels.new_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo Marcador"])},
  "jira.dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar painel para OKR"])},
  "jira.dashboard.okrs_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKRs exibidos (máx. 100)"])},
  "jira.dashboard.okrs_displayed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Números de 1 a 100 são permitidos"])},
  "jira.dashboard.hierarchy_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização de hierarquia"])},
  "jira.dashboard.okr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de OKR"])},
  "jira.dashboard.okr_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador de OKR"])},
  "jira.dashboard.access.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem acesso ao espaço de trabalho."])},
  "notifications.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
  "notifications.excel_export_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O relatório foi solicitado e será baixado em breve."])},
  "notifications.excel_export_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O relatório está sendo preparado e será enviado para o seu e-mail assim que estiver pronto."])},
  "notifications.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copiado."])},
  "notifications.plugin_version_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A versão do plugin foi copiada."])},
  "notifications.administrator_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador adicionado."])},
  "notifications.user_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário deletado."])},
  "notifications.role_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo alterado."])},
  "notifications.comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário deletado"])},
  "notifications.comment_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverter"])},
  "view_select.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alinhamento"])},
  "view_select.explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista"])},
  "view_select.mindmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
  "objective.link_to_another_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular outro OKR"])},
  "filter.all_okr_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos níveis de OKR"])},
  "jira.sidebar.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O item do Jira será desvinculado do objetivo ou resultado chave. Confirme por favor."])},
  "datepicker.select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar data"])},
  "datepicker.select_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar período"])},
  "datepicker.next": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["próximos ", _interpolate(_named("days")), " dias"])},
  "datepicker.previous": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dias atrás"])},
  "date.custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data personalizada"])},
  "objectives.percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentagem (%)"])},
  "objectives.binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binário"])},
  "objectives.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
  "objective.columns_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de colunas"])},
  "user.preferences.change_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "filter.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
  "filter.avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méd"])},
  "filter.A_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Z"])},
  "filter.Z_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-A"])},
  "tour.got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendi!"])},
  "status.switch_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automático"])},
  "status.switch_manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "status.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum"])},
  "stand_with_ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoie a Ucrânia"])},
  "objectives.due_date.start_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início do Intervalo"])},
  "objectives.period_mode.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de período"])},
  "confirm.interval_change_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A data ", _interpolate(_named("dateProp")), " vai ser reiniciada para o novo valor do intervalo."])},
  "menu.synchronization_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronização"])},
  "roadmap.views.weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanas"])},
  "roadmap.views.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meses"])},
  "roadmap.views.quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestres"])},
  "roadmap.updated_timeline_success_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateProp")), " editada com sucesso."])},
  "roadmap.start_and_due_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de ínicio e termino"])},
  "roadmap.backlog_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roteiro não está disponível para o intervalo não ativo"])},
  "roadmap.invalid_dates_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de ínicio deve ser menor que a data de termino"])},
  "settings.synchronization.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A sincronização permite que você mantenha as datas de vencimento no OKR com os campos de pendência correspondentes do Jira. As configurações definidas aqui se aplicam a todos os problemas que foram adicionados aos OKRs. ", _interpolate(_named("break")), "Quando habilitada, a sincronização de tarefas é bidirecional e instantânea. Por exemplo, se você mover uma pendência usando o OKR, os campos \"Data inicial\" e \"Data final\" da pendência serão atualizados. Por outro lado, se você editar os campos \"Data de início\" ou \"Data de término\" por meio da visualização de problemas do Jira, as alterações de problemas serão atualizadas e visíveis no OKR."])},
  "settings.synchronization.general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações gerais"])},
  "settings.synchronization.save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar configuração de sincronização"])},
  "settings.synchronization.select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar projeto"])},
  "settings.synchronization.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeto"])},
  "settings.synchronization.select.start_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo data de ínicio"])},
  "settings.synchronization.select.start_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o campo para data de ínicio"])},
  "settings.synchronization.select.end_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo para data de termino"])},
  "settings.synchronization.select.end_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o campo para data de termino"])},
  "view_select.roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roteiro"])},
  "period_mode.auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo p/ cima (Auto)"])},
  "period_mode.manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "filters.update_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar filtro"])},
  "filters.save_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar filtro como.."])},
  "filters_saver.filter_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filtro: ", _interpolate(_named("filterName")), " salvo com sucesso"])},
  "filters_saver.filter_renamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filtro: ", _interpolate(_named("filterName")), " renomeado com sucesso"])},
  "filters_saver.filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filtro: ", _interpolate(_named("filterName")), " editado com sucesso"])},
  "filters.filter_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filtro: ", _interpolate(_named("filterName")), " deletado com sucesso"])},
  "menu.show_other_views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar outras visualizações"])},
  "action.remove_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remover ", _interpolate(_named("entity"))])},
  "dashboard.no_objectives_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum objetivo no grupo"])},
  "settings.synchronization.projects_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do projeto"])},
  "settings.synchronization.select_synchronisation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tipo de sincronização"])},
  "settings.synchronization.keep_value_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenha o valor original do Jira se o valor do OKR for nulo."])},
  "settings.synchronization.keep_value_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenha o valor original do OKR se o valor do Jira for nulo."])},
  "settings.synchronization.delete_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar customização"])},
  "settings.synchronization.sync_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizando..."])},
  "objectives.table_header_startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início"])},
  "objectives.table_header_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datas"])},
  "objectives.table_header_currentTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atual / Alvo"])},
  "objective.incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incompleto"])},
  "objective.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completo"])},
  "objective.chart.daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diário"])},
  "objective.chart.weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanal"])},
  "objective.chart.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])},
  "objective.chart.quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestral"])},
  "objective.chart.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anos"])},
  "objective.chart.downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar CSV"])},
  "workspace.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuso horário"])},
  "filter.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de ínicio"])},
  "tour.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
  "tour.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pular tutorial"])},
  "dashboard.no_linked_krs_nested_jira_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum KR atrelado, objetivo aninhado ou Item Jira"])},
  "unlink_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desassociar Item Jira"])},
  "roadmap.quarter_shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
  "levels.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
  "levels.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["outro"])},
  "banner.license_renew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua licença de teste vai acabar. Por favor compre uma licença em ", _interpolate(_named("licenseDaysLeft")), " continuar usando OKR Board for Jira."])},
  "settings.synchronization.synchronization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você deseja deletar a syncronização customizada para o projeto ", _interpolate(_named("projectName")), "?"])},
  "settings.synchronization.not_synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não sincronizado"])},
  "period_mode.auto.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datas de início e término agendadas ", _interpolate(_named("break")), " com base em seus elementos aninhados"])},
  "period_mode.manual.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datas de início e término programadas manualmente"])},
  "validation.input_number.allowed_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido números , ponto, vírgula"])},
  "validation.input_number.allowed_max_characters_before_dot": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Permitido ", _interpolate(_named("integerMaxLength")), " caracteres antes do ponto"])},
  "validation.input_number.allowed_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Permitido ", _interpolate(_named("integerMaxLength")), " caracteres"])},
  "validation.input_number.allowed_one_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido um ponto"])},
  "validation.input_number.allowed_one_minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido um menos"])},
  "validation.input_number.integer_begins_with_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inteiro começa com zero"])},
  "group.group_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupar por"])},
  "confluence.settings.api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave de API"])},
  "confluence.token.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A chave de API gerada pelo Oboard para sua instância Jira. Por favor contate seu administrado do plugin para pegar a chave de API."])},
  "menu.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
  "menu.activity_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de atividade"])},
  "comments.placeholder.leave_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixe um comentário"])},
  "settings.company.okr_grading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abordagem de classificação do OKR"])},
  "confluence.permission_less.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem permissão para ver essa página"])},
  "confluence.permission_less.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você acha que isso está incorreto, favor contatar seu administrador do plugin Oboard."])},
  "confluence.error_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado."])},
  "confluence.error_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor reinicie a página."])},
  "action.proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosseguir"])},
  "action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
  "action.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover"])},
  "field.parentItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item pai"])},
  "objective.move_objective": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mova ", _interpolate(_named("displayId")), " para outro WS"])},
  "kr.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mova itens aninhados. Somente do espaço de trabalho atual."])},
  "kr.move_from_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mova de todos os espaços de trabalho."])},
  "move.select_assignee_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não encontramos o respectivo responsável no espaço de trabalho de destino, mapeie-o."])},
  "move.select_groups_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não encontramos os respectivos grupos no espaço de trabalho de destino, mapeie-os."])},
  "move.select_levels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não encontramos os respectivos níveis no espaço de trabalho de destino, mapeie-os."])},
  "debug.restore_task_chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar corrente de tarefas"])},
  "support.btn_write_to_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatar o suporte"])},
  "roadmap.backlog_error.list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seguintes OKRs ou seus itens aninhados estão no período do backlog"])},
  "levels.delete_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para excluir o nível ", _interpolate(_named("levelName")), ", você precisa converter todos os OKRs ", _interpolate(_named("levelName")), " em qualquer um dos níveis OKR existentes."])},
  "missing_page.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina Inicial"])},
  "text_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado"])},
  "in_app_notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
  "in_app_notifications.no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem nenhuma notificação"])},
  "in_app_notifications.notification_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " mencionou você em um ", _interpolate(_named("element"))])},
  "okr_elements.kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KR"])},
  "okr_elements.objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo"])},
  "action.add_column_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir coluna a direita"])},
  "action.add_column_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir coluna a esquerda"])},
  "action.add_row_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir coluna acima"])},
  "action.add_row_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir coluna abaixo"])},
  "action.delete_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar linha"])},
  "action.delete_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar coluna"])},
  "action.merge_or_split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesclar ou separar celulas"])},
  "action.table_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opção da tabela"])},
  "action.delete_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletar tabela"])},
  "action.toggle_header_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar coluna de cabeçalho"])},
  "action.toggle_header_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternager linha de cabeçalho"])},
  "action.toggle_header_cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar celula de cabeçalho"])},
  "objective.select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar Nível"])},
  "objective.select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar Grupo"])},
  "objective.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar responsável"])},
  "move.success_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movido com sucesso"])},
  "move.no_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item principal"])},
  "confluence.okr_board_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão do OKR Board está"])},
  "in_app_notifications.button.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem notificações não lidas"])},
  "action.add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Link"])},
  "action.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela"])},
  "action.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de código"])},
  "action.mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencionar"])},
  "action.quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menção"])},
  "action.emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
  "objectives.predicted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsto"])},
  "objectives.status_indicator.description.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("predicted")), " — Pontuação prevista (", _interpolate(_named("grade")), "/", _interpolate(_named("passed")), ")*100"])},
  "objectives.status_indicator.description.grade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("grade")), " - Nota atual"])},
  "objectives.status_indicator.description.passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("passed")), " — Tempo decorrido"])},
  "objective.no_key_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem Resultado Chave"])},
  "objective.btn.no_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item aninhado"])},
  "link_jira_issue.linked_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Jira Vinculado:"])},
  "link_jira_issue.link_another_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrelar outro item Jira"])},
  "status.switch_auto_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status automático"])},
  "debug.confirm_migration_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A migração não pode ser revertida automaticamente, não incie ela a não ser que você tenha sido orientado pelo time de suporte."])},
  "description.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posição:"])},
  "lock_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você não tem direto a editar isso ", _interpolate(_named("element"))])},
  "element.type.key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado Chave"])},
  "element.type.jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Jira"])},
  "user.personal_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações pessoais"])},
  "user.personal_settings.dont_have_email_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não temos acesso ao seu endereço de e-mail"])},
  "user.personal_settings.please_contact_our": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Entre em contato com nosso ", _interpolate(_named("contact")), " para mais informações"])},
  "user.personal_settings.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
  "user.personal_settings.product_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviaremos a você um boletim informando as atualizações essenciais de produtos no Oboard."])},
  "user.personal_settings.product_case_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicas sobre colaboração, estudos de caso, tutoriais e muitos artigos interessantes."])},
  "user.personal_settings.product_promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de promoções, próximos eventos e webinários."])},
  "user.personal_settings.about_qty_per_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cerca de ", _interpolate(_named("qty")), " por mês"])},
  "user.personal_settings.about_qty_every_months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cerca de ", _interpolate(_named("qtd")), " a cada ", _interpolate(_named("repeat")), " meses"])},
  "menu.personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações pessoais"])},
  "debug.migrate_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar marcadores"])},
  "debug.migrate_labels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migração de marcadores"])},
  "confluence.add_okrs_to_confluence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar OKRs ao Confluence"])},
  "weights.objective.manual.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mode")), " Para preservar seus pesos personalizados de OKR, todos os novos OKRs serão adicionados com um peso de 0. Seu peso poderá ser alterado posteriormente."])},
  "weights.objective.manual_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo manual."])},
  "field.owner.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar responsável…"])},
  "objective.move_objective_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza que deseja mover o Objetivo:"])},
  "objective.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover itens inseridos"])},
  "move.copy_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar objetivos inseridos"])},
  "objective.clear_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar grupo"])},
  "link_jira_issue.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir tarefa no Jira"])},
  "status.switch_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igual"])},
  "debug.upload_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar"])},
  "debug.i_understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu compreendo"])},
  "debug.instance_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migração de instância"])},
  "debug.export_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar para .zip"])},
  "debug.import_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar de .zip"])},
  "debug.import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro de importação"])},
  "debug.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso"])},
  "debug.migration_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["- Por favor, migre sua instância do Jira primeiro (com todos os temas, usuários, grupos etc.), para que as informações dos OKRs não sejam perdidas, como temas, responsáveis etc. do Jira.", _interpolate(_named("break")), " - As configurações da empresa do plug-in serão substituídas.", _interpolate(_named("break")), " - Nenhuma área de trabalho existente será excluída ou substituída."])},
  "debug.import_file_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo é muito grande. Envie uma mensagem para nós pelo <a href=\"mailto:support", "@", "oboard.io\">suporte", "@", "oboard.io</a> para aumentar o limite."])},
  "app.platform.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])},
  "action.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar mais"])},
  "action.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
  "action.add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar usuários"])},
  "action.add_user_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Adicionar usuário"]), _normalize(["Adicionar usuários"])])},
  "action.unlink_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desvincular grupos"])},
  "action.import_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar usuários"])},
  "action.link_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vincular grupos do ", _interpolate(_named("plataform"))])},
  "date.date_with_word_break": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " ", _interpolate(_named("break")), " — ", _interpolate(_named("end"))])},
  "create.objective.btn.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar objetivo"])},
  "field.interval_wrong_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-chave errada"])},
  "users.all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as áreas de trabalho"])},
  "users.bulk_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar vários"])},
  "objectives.table_header_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
  "objectives.table_header_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partes interessadas"])},
  "objective.no_key_results_for_selected_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há Resultados-chave disponíveis para o Objetivo selecionado."])},
  "objective.sorting_drag_blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(arrastar e soltar está bloqueado)"])},
  "objective.chart.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tela cheia"])},
  "intervals.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum intervalo encontrado"])},
  "workspaces.no_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode criar uma nova área de trabalho. Entre em contato com seu administrador"])},
  "workspaces.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há áreas de trabalho"])},
  "workspaces.no_workspaces_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie a primeira área de trabalho para começar"])},
  "workspaces.workspace_field_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " atualizado com sucesso"])},
  "workspace.select_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar fuso horário"])},
  "workspaces.users_from_platform": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuários dos grupos ", _interpolate(_named("platform")), " vinculados"])},
  "workspaces.linked_user_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários vinculados não podem ser removidos manualmente"])},
  "workspaces.delete_user_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir ", _interpolate(_named("userName")), " do grupo ", _interpolate(_named("groupName")), "?"])},
  "workspaces.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função na área de trabalho"])},
  "workspaces.intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalos"])},
  "workspaces.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma área de trabalho encontrada"])},
  "workspaces.groups_tab.linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupos ", _interpolate(_named("plataform")), " vinculados"])},
  "workspaces.groups_tab.linked_groups.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os grupos onde obtemos uma lista de usuários para adicionar ao plug-in."])},
  "workspaces.table.cell.users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Usuário"]), _normalize([_interpolate(_named("count")), " Usuários"])])},
  "workspaces.table.cell.groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Grupo"]), _normalize([_interpolate(_named("count")), " Grupos"])])},
  "workspaces.table.cell.intervals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Intervalo"]), _normalize([_interpolate(_named("count")), " Intervalos"])])},
  "workspace.change_role": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alterar a função do usuário"]), _normalize(["Alterar função para ", _interpolate(_named("qty")), " usuários"])])},
  "workspaces.no_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há grupos"])},
  "workspaces.no_groups.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um grupo para adicionar pessoas à área de trabalho"])},
  "workspaces.add_users_to_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adicionar usuários a ", _interpolate(_named("group"))])},
  "workspaces.create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um grupo"])},
  "workspaces.select_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecionar grupos do ", _interpolate(_named("plataform"))])},
  "workspaces.select_linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecionar grupos vinculados do ", _interpolate(_named("plataform"))])},
  "workspaces.manage_workspace.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerencie o acesso e as funções da área de trabalho para todos os usuários"])},
  "groups.no_groups_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem grupos nesta Área de Trabalho."])},
  "groups.unlink.keep_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manter os usuários"])},
  "groups.unlink.keep_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manter os usuários em ", _interpolate(_named("group")), " após desvincular o grupo do ", _interpolate(_named("platform")), "."])},
  "groups.unlink.delete_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir usuários"])},
  "groups.unlink.delete_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Excluir usuários de ", _interpolate(_named("group")), " após desvincular o grupo do ", _interpolate(_named("platform")), "."])},
  "groups.unlink.strategy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a opção"])},
  "groups.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear grupo"])},
  "users.add_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar area de trabalho"])},
  "users.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar outro usuário"])},
  "users.import_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe usuários do grupo Jira uma vez. Os usuários importados podem ser removidos manualmente a qualquer momento"])},
  "users.link_jira_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular grupos do Jira"])},
  "users.link_jira_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincule um grupo do Jira ao painel de OKR. Todas as alterações no grupo Jira serão inseridas automaticamente no grupo nos painéis de OKR. Os usuários vinculados não podem ser removidos manualmente"])},
  "users.select_jira_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar o grupo Jira"])},
  "users.select_import_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione as opções de importação"])},
  "users.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há usuários correspondentes aos filtros aplicados."])},
  "user_details.platform_profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perfil do ", _interpolate(_named("platform"))])},
  "user_details.delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para excluir o usuário do ", _interpolate(_named("workspaceName")), ", remova-o do respectivo Grupo sincronizado do ", _interpolate(_named("platform")), "."])},
  "users.delete_user_from_plugin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para excluir usuários selecionados, remova-os do respectivo grupo sincronizado do ", _interpolate(_named("platform")), "."])},
  "user_details.add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar à área de trabalho"])},
  "user_details.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar a outra área de trabalho"])},
  "user_details.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuário não está em nenhuma área de trabalho"])},
  "user_details.select_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar grupos"])},
  "filter.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar"])},
  "filter.clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar seleção"])},
  "filter.only_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas este"])},
  "filter.no_objective_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tente alterar seus critérios de pesquisa ou ", _interpolate(_named("creating"))])},
  "filter.creating_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crie um novo objetivo."])},
  "filter.no_objective_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem nenhum Objetivo nesse intervalo"])},
  "filter.add_another_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar outro campo"])},
  "filters.update_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar filtro como"])},
  "filters.update_dashboard_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar painel como"])},
  "filters.save_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar como..."])},
  "dropdown.archive_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para arquivado"])},
  "dropdown.unarchive_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para abrir"])},
  "dropdown.link_new_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vincular novos grupos no ", _interpolate(_named("platform"))])},
  "dropdown.unlink_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Desvincular grupos do ", _interpolate(_named("platform"))])},
  "confirm.discard_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
  "delete_user.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Excluir ", _interpolate(_named("userName"))]), _normalize(["Excluir usuários"])])},
  "delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir ", _interpolate(_named("userName")), " do espaço de trabalho: ", _interpolate(_named("workspaceName")), "?"])},
  "delete_user_from_app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir ", _interpolate(_named("userName")), " do ", _interpolate(_named("appName")), "?"])},
  "delete_confirm_workspace_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir o espaço de trabalho ", _interpolate(_named("workspaceName")), " e todos os OKRs desse espaço de trabalho?"])},
  "delete_confirm_interval_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir o intervalo ", _interpolate(_named("intervalName")), " e todos os OKRs desse intervalo?"])},
  "delete_confirm_objective_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza que deseja excluir o Objetivo:"])},
  "delete_confirm_kr_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir o Resultado Principal:"])},
  "delete_confirm_issue_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja desvincular o problema:"])},
  "users.roles.total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("qty")), " funções"])},
  "users.add_users_to_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adicionar usuário selecionado ao espaço de trabalho"]), _normalize(["Adicionar ", _interpolate(_named("count")), " usuários selecionados ao espaço de trabalho"])])},
  "menu.workspaces.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos, usuários e intervalos"])},
  "support.help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central de Ajuda"])},
  "support.support.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crie um tíquete de suporte aqui ou envie-nos um e-mail para ", _interpolate(_named("emailAddress"))])},
  "support.help_center.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre respostas para perguntas comuns em nossa Central de Ajuda da Confluence."])},
  "support.help_center.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visite a central de ajuda"])},
  "confirm_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar mudanças?"])},
  "confirm_interval_change.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar mudança de intervalo"])},
  "confirm_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode ter dados não salvos. Tem certeza de que deseja descartar todas as alterações não salvas?"])},
  "dashboard.dashboard_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Painel: ", _interpolate(_named("dashboardName")), " excluído com sucesso"])},
  "dashboard.no_owners_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum proprietário corresponde a esta pesquisa"])},
  "dashboard.menu.general_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel geral"])},
  "dashboard.menu.performance_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório de desempenho"])},
  "dashboard.menu.custom_dashboard": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Painel personalizado"]), _normalize(["Painéis personalizados"])])},
  "dashboard.across_all_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em todo o grupo"])},
  "dashboard.by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por grupo"])},
  "dashboard.days_left_in_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias restantes no ciclo"])},
  "dashboard.linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vinculado"])},
  "dashboard.in_this_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neste ciclo"])},
  "dashboard.last_qty_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Últimos ", _interpolate(_named("qty")), " dias"])},
  "dashboard.enter_the_main_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o objetivo principal"])},
  "dashboard.access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso negado"])},
  "dashboard.you_dont_have_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem permissão para acessar um ou mais OKRs neste relatório"])},
  "homepage.export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar *.pdf"])},
  "tokens.create_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar token"])},
  "tokens.token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
  "tokens.no_tokens_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie o primeiro token para começar"])},
  "token.copy.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token de API"])},
  "roadmap.export.width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largura de uma parte:"])},
  "roadmap.export.max_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largura máxima da imagem:"])},
  "roadmap.export.max_width_item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["• ", _interpolate(_named("app")), " — ", _interpolate(_named("width")), " px"])},
  "roadmap.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "roadmap.export_option.visible.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área visível"])},
  "roadmap.export_option.visible.hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporte apenas o que você vê na tela"])},
  "roadmap.export_option.full.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap inteiro"])},
  "roadmap.export_option.multiple.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar como várias imagens"])},
  "roadmap.export.safari_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O navegador Safari não suporta totalmente a funcionalidade de exportação. Use Chrome ou Firefox."])},
  "roadmap.export_beta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar roadmap (beta)"])},
  "levels.no_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum nível ainda"])},
  "levels.no_levels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie o primeiro Nível OKR para começar"])},
  "debug.migrate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrar e-mail"])},
  "debug.update_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar informações dos usuários"])},
  "debug.migrate_email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migração de e-mail"])},
  "debug.update_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar informações dos usuários"])},
  "settings.synchronization.custom_project_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de projeto personalizado"])},
  "settings.synchronization.no_custom_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum projeto personalizado ainda"])},
  "settings.synchronization.create_a_new_custom_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um novo projeto personalizado"])},
  "settings.synchronization.projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projetos"])},
  "settings.synchronization.new_custom_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo projeto personalizado"])},
  "settings.synchronization.okr_replace_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR substitui Jira"])},
  "settings.synchronization.jira_replace_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira substitui OKR"])},
  "settings.synchronization.jira_values_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os valores de Jira serão substituídos (sobrescritos) pelos valores de OKR de 1 a 1."])},
  "settings.synchronization.okr_values_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os valores de OKR serão substituídos (sobrescritos) pelos valores de Jira de 1 a 1."])},
  "settings.synchronization.new_custom_sync_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novas configurações de sincronização personalizadas"])},
  "settings.private_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho privados"])},
  "settings.public_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho públicos"])},
  "settings.roles_in_the_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funções nos espaços de trabalho"])},
  "labels.no_labels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie a primeira etiqueta para começar"])},
  "labels.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma etiqueta encontrada"])},
  "view_select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista"])},
  "expired_license.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seu painel OKR Para a licença ", _interpolate(_named("platform")), " ", _interpolate(_named("break")), "não é mais válida"])},
  "expired_license.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Continue usando painéis OKR, roadmaps e outras ferramentas poderosas ", _interpolate(_named("break")), "do painel OKR para ", _interpolate(_named("platform")), " assinando o plano pago."])},
  "expired_license.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar licença"])},
  "expired_license.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver preços detalhados"])},
  "expired_license.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você não for o administrador do JIRA, solicite a eles a habilitação do acesso"])},
  "missing_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops, desculpe, não conseguimos encontrar esta página"])},
  "missing_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado ou a página não existe mais."])},
  "units.px": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["px"])},
  "figma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figma"])},
  "miro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miro"])},
  "badge.already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já adicionado"])},
  "badge.added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionado"])},
  "badge.already_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já vinculado"])},
  "notification.contact_ws_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com o administrador do seu espaço de trabalho."])},
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard"])},
  "action.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
  "tree.fit_content_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustar tamanho do conteúdo"])},
  "tree.switch_to_detailed_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar para exibição de cartão detalhado"])},
  "tree.switch_to_compact_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar para visualização de cartão compacto"])},
  "tree.legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenda"])},
  "tree.legend.show_child_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar itens filho"])},
  "tree.legend.show_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar itens filtrados"])},
  "tree.legend.hide_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar itens filtrados"])},
  "tree.legend.not_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não contribui para a nota dos pais"])},
  "okr_element.rename.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o novo nome"])},
  "global_groups.add_sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um subgrupo"])},
  "global_groups.manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar usuários"])},
  "global_groups.select_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o grupo-pai"])},
  "global_groups.add_selected_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adicionar ", _interpolate(_named("count")), " grupo selecionado"]), _normalize(["Adicionar ", _interpolate(_named("count")), " grupos selecionados"])])},
  "global_groups.audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de auditoria"])},
  "global_groups.unavailable_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem direitos para gerenciar este grupo"])},
  "global_groups.users_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ou remover usuários pode afetar os espaços de trabalho"])},
  "global_groups.delete_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir ", _interpolate(_named("groupName")), "? O grupo será excluído de todos os objetivos e todos os espaços de trabalho!"])},
  "global_groups.keep_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenha os usuários no espaço de trabalho após excluir o grupo."])},
  "global_groups.delete_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir usuários com grupo."])},
  "global_groups.add_groups_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar grupos ao espaço de trabalho"])},
  "add_users_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["O usuário será adicionado sem nenhum grupo"]), _normalize(["Os usuários serão adicionados sem nenhum grupo"])])},
  "global_groups.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover do grupo"])},
  "global_groups.sub_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgrupos"])},
  "action.claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reivindicar"])},
  "action.remove_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover da área de trabalho"])},
  "action.add_to_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao grupo"])},
  "action.delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir usuário"])},
  "action.edit_on_user_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar na página do usuário"])},
  "action.add_to_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar aos favoritos"])},
  "action.remove_from_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover dos favoritos"])},
  "action.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar"])},
  "create.objective.success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo criado. Verifique os filtros caso não consiga ver ele"])},
  "create.objective.success_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se não estiver aparecendo, verifique os filtros"])},
  "user.personal_settings.when_someone_reply_me": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quando alguém ", _interpolate(_named("mention")), " e ", _interpolate(_named("reply")), " para você"])},
  "user.personal_settings.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responder"])},
  "user.personal_settings.mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "menções"])},
  "user.personal_settings.you_are_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você é o responsável"])},
  "user.personal_settings.you_are_stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você é a parte interessada"])},
  "dropdown.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizador"])},
  "menu.my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR do meu grupos"])},
  "menu.whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que há de novo"])},
  "support.okr_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultoria de OKR"])},
  "support.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumente sua curva de aprendizado de OKR com o treinamento e workshops de consultores de OKR da Oboard."])},
  "support.okr_review.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma ligação com o Instrutor de OKR"])},
  "support.okr_consulting.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma consultoria"])},
  "release_updates.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abra o changelog"])},
  "release_updates.footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para mais atualizações, confira:"])},
  "dashboard.layout_orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientação de layout"])},
  "dashboard.horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "dashboard.vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
  "dashboard.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
  "dashboard.created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Criado por ", _interpolate(_named("userName"))])},
  "dashboard.start_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar apresentação"])},
  "dashboard.stop_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interromper apresentação"])},
  "dashboard.export_as_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar como PDF"])},
  "dashboard.duplicate_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar painel"])},
  "dashboard.share_with_same_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhe com as mesmas pessoas"])},
  "dashboard.share_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar painel"])},
  "dashboard.share.public.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Todos os painéis públicos são visíveis para todos os usuários e disponíveis para duplicação.", _interpolate(_named("break")), _interpolate(_named("break")), " Torne o painel privado se desejar compartilhá-lo com pessoas específicas ou se ele possui dados confidenciais."])},
  "dashboard.add_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar pessoas"])},
  "homepage.progress.switch.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso dos Objetivos"])},
  "homepage.progress.switch.krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso dos resultados-chave"])},
  "homepage.objectives_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objetivos do ", _interpolate(_named("groupName"))])},
  "homepage.objectives.foreign_objectives": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resultados-chave de ", _interpolate(_named("userName")), " de objetivos externos"])},
  "homepage.key_result_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Key result"]), _normalize(["Key results"])])},
  "homepage.objective_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Objetivo"]), _normalize(["Objetivos"])])},
  "auth.session_expired.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já faz um tempo desde sua última atividade"])},
  "auth.session_expired.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregue a página para obter dados precisos."])},
  "debug.rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direitos"])},
  "debug.remove_okr_board_global_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover permissão global do painel de OKR"])},
  "debug.claim_admin-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reivindicar direitos de administrador"])},
  "settings.learn_more_email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre notificações por e-mail"])},
  "settings.get_email_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receba atualizações por e-mail de atividades da OKR quando"])},
  "jira.dashboard.okr_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de OKR"])},
  "jira.auto_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação automática"])},
  "jira.manual_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação manual"])},
  "jira.auto_grade.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A classificação automática é baseada no status de resolução de problemas do Jira ou no progresso de suas subtarefas"])},
  "in_app_notifications.notification.status.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " mudou de status"])},
  "in_app_notifications.notification.commented.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " comentou em um ", _interpolate(_named("element"))])},
  "in_app_notifications.notification.replied.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " respondeu a você sobre um problema"])},
  "in_app_notifications.notification.updates.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+ ", _interpolate(_named("qty")), " atualizações de ", _interpolate(_named("userName")), " e outros"])},
  "in_app_notifications.notification.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menos"])},
  "action.merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesclar"])},
  "action.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover do grupo"])},
  "update.keyResult.result_type_lock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa nota não pode ser editada pois é calculada a partir dos itens filhos."])},
  "update.keyResult.result_type_lock_message_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se você quiser retornar ao modo manual, ", _interpolate(_named("action")), " de todos os itens sobrepostos"])},
  "update.keyResult.result_type_lock_message_description_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desativar contribuição"])},
  "field.name_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, este nome está reservado"])},
  "objectives.table_header_lastGradeUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última atualização de nota"])},
  "objectives.last_count_items_shown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Últimas ", _interpolate(_named("count")), " ", _interpolate(_named("entity")), " são mostradas. Para ver mais, pesquise usando o ID completo ou nome."])},
  "objectives.okr": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["OKR"]), _normalize(["OKRs"])])},
  "group.user_will_be_added_to_the_workspaces": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["O usuário será adicionado aos espaços de trabalho:"]), _normalize(["Os usuários serão adicionados aos espaços de trabalho:"])])},
  "users.add_to_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Adicionar usuário ao espaço de trabalho"]), _normalize(["Adicionar usuários ao espaço de trabalho"])])},
  "users.add_to_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Adicionar usuário ao grupo"]), _normalize(["Adicionar usuários aos grupos"])])},
  "users.add_to_group_userName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adicionar ", _interpolate(_named("userName")), " ao grupo"]), _normalize(["Adicionar ", _interpolate(_named("userName")), " aos grupos"])])},
  "user.preferences.default_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Grupo-padrão"]), _normalize(["Grupos-padrão"])])},
  "user.preferences.no_groups_for_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pertence a nenhum grupo no espaço de trabalho"])},
  "filter.custom_range.between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre"])},
  "filter.custom_range.since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
  "filter.custom_range.before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes"])},
  "filter.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
  "filter.limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limitação"])},
  "filter.last_grade_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualização de nota"])},
  "menu.organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos e usuários"])},
  "menu.organization.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos e usuários globais"])},
  "roles.suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspenso"])},
  "dashboard.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum painel personalizado"])},
  "dashboard.no_report_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar o primeiro painel personalizado"])},
  "dashboard.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há nenhum painel que corresponda aos filtros aplicados."])},
  "settings.company.default_user_role_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função de usuário-padrão quando eles são adicionados a um espaço de trabalho"])},
  "settings.company.default_user_role_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando um usuário é adicionado manualmente, a função pode ser selecionada lá, bem como pode ser alterada a qualquer momento, se necessário."])},
  "settings.company.ws_admins_manage_permission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Permitir que administradores do espaço de trabalho gerenciem usuários e grupos em ", _interpolate(_list(0)), "."])},
  "settings.company.ws_admins_allowed_entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos os espaços de trabalho"])},
  "settings.when_someone_manually_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando alguém altera manualmente o status ou deixa um comentário nos OKRs, onde:"])},
  "notifications.oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops…"])},
  "notifications.lost_group_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que você perdeu o acesso a este grupo. Entre em contato com seu administrador"])},
  "notifications.groups_removed_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo removido do espaço de trabalho."])},
  "global_groups.manage_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar grupo"])},
  "global_groups.merge_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesclar grupos"])},
  "global_groups.parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo originário"])},
  "global_groups.merge_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione pelo menos dois grupos para mesclar"])},
  "global_groups.unavailable_for_add_to_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você precisa ser um administrador do espaço de trabalho em todos os espaços de trabalho aos quais o grupo foi atribuído. Peça ao administrador do seu plug-in para conceder os direitos."])},
  "global_groups.merge_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesclar grupos afetará os espaços de trabalho"])},
  "global_groups.add_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar grupos"])},
  "global_groups.will_be_removed_from_ws": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["O usuário também pode ser removido dos espaços de trabalho"]), _normalize(["O usuário também pode ser removido dos espaços de trabalho"])])},
  "global_groups.without_parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem grupo originário"])},
  "global_groups.no_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum usuário neste grupo ainda"])},
  "global_groups.no_users.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar usuários a este grupo para usá-los em todos os espaços de trabalho"])},
  "global_groups.remove_from_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover grupo do espaço de trabalho"])},
  "global_groups.remove_from_workspace.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja remover ", _interpolate(_named("groupName")), " do espaço de trabalho?"])},
  "audit_log.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "action.add_to_group.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O usuário foi adicionado ", _interpolate(_named("break")), "a todos os espaços de trabalho ", _interpolate(_named("break")), "vinculados ao grupo"])},
  "user_details.add_user.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adicionar usuário a um espaço de trabalho ", _interpolate(_named("break")), "com um grupo específico"])},
  "action.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado"])},
  "action.updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado"])},
  "action.lets_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos começar"])},
  "action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "action.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar"])},
  "action.activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
  "action.inactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativar"])},
  "datepicker.without_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem data"])},
  "weights.objective.cant_change_weight_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você não pode alterar os pesos no objetivo ", _interpolate(_named("status")), ".\nReabra o objetivo para editar pesos personalizados"])},
  "objectives.table_header_gradeUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota atualizada"])},
  "objectives.table_header_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidade"])},
  "objective.you_cant_make_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode tornar o objetivo privado.\nEle precisa ser atribuído ou ter uma parte interessada"])},
  "objective.make_okr_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar visível apenas para proprietários e partes interessadas"])},
  "objective.make_okr_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar visível para todos"])},
  "objective.columns_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas"])},
  "objective.by_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por status"])},
  "objective.obj_cant_move": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O objetivo não pode ser movido com elementos sobrepostos, pois um dos itens sobrepostos está ", _interpolate(_named("closedStatus")), " ou ", _interpolate(_named("abandonedStatus"))])},
  "objective.cant_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode mudar o objetivo"])},
  "objective.obj_cant_move_because_kr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O objetivo não pode ser mudado, pois um dos itens sobrepostos está ", _interpolate(_named("closedStatus")), " ou ", _interpolate(_named("abandonedStatus"))])},
  "objective.move_all_count_okrs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mover ", _interpolate(_named("count")), " OKR"]), _normalize(["Mover todos os ", _interpolate(_named("count")), " OKRs"])])},
  "group.table_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização de tabela"])},
  "filter.clear_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar selecionados"])},
  "filter.more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais que"])},
  "filter.less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos que"])},
  "filter.at_least_comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pelo menos ", _interpolate(_named("count")), " comentário"]), _normalize(["Pelo menos ", _interpolate(_named("count")), " comentários"])])},
  "filter.never_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca comentou"])},
  "filter.is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está vazio"])},
  "filter.is_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não está vazio"])},
  "filter.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativa"])},
  "filter.last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último comentário"])},
  "filter.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Filtros"])},
  "filters.custom_fields.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O filtro salvo tem um campo personalizado que não está mais disponível."])},
  "menu.custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos personalizados"])},
  "menu.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
  "dashboard.reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabrir"])},
  "dashboard.add_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar painel personalizado"])},
  "dashboard.choose_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher painel personalizado"])},
  "homepage.progress.last_week_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alteração da semana passada"])},
  "tokens.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o número máximo (", _interpolate(_named("limit")), ") de tokens de API."])},
  "link_jira_issue.import_count_jira_issues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Importaremos apenas os primeiros ", _interpolate(_named("count")), " problemas do Jira"])},
  "status.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backlog"])},
  "comments.see_last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver último comentário"])},
  "comments.add_a_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um comentário"])},
  "comments.open_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir comentários"])},
  "comments.more_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["mais ", _interpolate(_named("count")), " resposta"]), _normalize(["mais ", _interpolate(_named("count")), " respostas"])])},
  "settings.company.adjust_thresholds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuste os limites que representam os status puxando os pontos na linha."])},
  "lock_message.grade_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A nota para OKRs ", _interpolate(_named("status")), " não está atualizada"])},
  "lock_message.entity_parent_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O ", _interpolate(_named("parentOkr")), " originário está ", _interpolate(_named("statusName")), ", e sua nota não será atualizada"])},
  "lock_message.kr_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A nota não pode ser editada, pois o KR ​​está ", _interpolate(_named("statusName"))])},
  "element.set_new_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alterar o status ", _interpolate(_named("okr")), " para ", _interpolate(_named("status"))])},
  "element.close_nested_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar o status de todos os principais resultados e objetivos sobrepostos"])},
  "custom_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos personalizados"])},
  "custom_fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigatório"])},
  "custom_fields.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
  "custom_fields.content_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área de conteúdo"])},
  "custom_fields.right_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel direito"])},
  "custom_fields.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum campo personalizado"])},
  "custom_fields.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie o primeiro campo personalizado"])},
  "custom_fields.create_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar campo personalizado"])},
  "custom_fields.add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar campo personalizado"])},
  "custom_fields.disable_create_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça ao seu administrador para adicionar um novo campo personalizado."])},
  "custom_fields.disable_create_button_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atualmente, só é possível criar ", _interpolate(_named("limit")), " campos personalizados."])},
  "custom_fields.single_line_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto de linha única"])},
  "custom_fields.long_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto longo"])},
  "custom_fields.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrelar"])},
  "custom_fields.link.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome para exibir"])},
  "custom_fields.link.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o número máximo (", _interpolate(_named("limit")), ") de links para o OKR."])},
  "custom_fields.assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsável"])},
  "custom_fields.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "custom_fields.single_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleção única"])},
  "custom_fields.multi_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleção múltipla"])},
  "custom_fields.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
  "custom_fields.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinheiro"])},
  "custom_fields.options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
  "custom_fields.options.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar opção"])},
  "custom_fields.options.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o nome da opção"])},
  "custom_fields.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda"])},
  "custom_fields.currency.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira a moeda"])},
  "custom_fields.field_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de campo"])},
  "custom_fields.field_type.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o tipo de campo"])},
  "custom_fields.field_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do campo"])},
  "custom_fields.field_name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o nome do campo"])},
  "custom_fields.add_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar para"])},
  "custom_fields.help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto de ajuda"])},
  "custom_fields.help_text.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o texto de ajuda"])},
  "custom_fields.add_field_to_placement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adicionar campo a ", _interpolate(_named("placement"))])},
  "custom_fields.warning.filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo não pode ser filtrado ou classificado"])},
  "custom_fields.warning.not_editable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " não pode ser editado no futuro"])},
  "custom_fields.warning.options.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode configurar opções somente ao criar um novo campo de seleção."])},
  "custom_fields.warning.options.create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você pode configurar opções somente ao criar um novo campo de seleção. ", _interpolate(_named("break")), "As opções não podem ser editadas no futuro."])},
  "custom_fields.notification.created_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Campo ", _interpolate(_named("fieldName")), " ", _interpolate(_named("action")), " com sucesso"])},
  "custom_fields.delete_field_confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir o campo personalizado ", _interpolate(_named("fieldName")), "? Todas as informações desse campo serão excluídas!"])},
  "custom_fields.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir campo personalizado"])},
  "custom_fields.field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definir ", _interpolate(_named("fieldName")), "…"])},
  "custom_fields.text_field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adicionar ", _interpolate(_named("fieldName")), "…"])},
  "onboarding.welcome.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bem-vindo, ", _interpolate(_named("userName"))])},
  "onboarding.welcome.rerun_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você recebeu a função de administrador!"])},
  "onboarding.welcome.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos felizes em ter você em nosso aplicativo. Vamos começar nossa jornada juntos."])},
  "onboarding.welcome.rerun_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É uma função executiva que permite que você gerencie o processo OKR. Agora você pode definir objetivos, configurar ciclos e outros detalhes, gerenciar permissões e funções de usuário."])},
  "onboarding.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscreva-se no boletim informativo do Oboard com atualizações essenciais de produtos, promoções e anúncios para próximos eventos e webinars. Cerca de 1 por mês, sem spam."])},
  "onboarding.demo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conheça um dos nossos especialistas"])},
  "onboarding.demo.product_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demonstração do produto"])},
  "onboarding.demo.product_demo.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende sua sessão de demonstração gratuita e descubra a implementação eficaz de OKRs em várias escalas."])},
  "onboarding.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça uma revisão de seus OKRs e potencialize seu aprendizado de OKR com os melhores coaches."])},
  "onboarding.tutorial.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assista ao guia em vídeo"])},
  "onboarding.tutorial.admin_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guia de configuração do administrador"])},
  "onboarding.tutorial.user_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guia do usuário"])},
  "onboarding.admins.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione mais administradores"])},
  "onboarding.invite_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convide o administrador do OKR Board"])},
  "onboarding.existed_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores do OKR Board"])},
  "onboarding.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passo"])},
  "support.onboarding_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chamada de integração"])},
  "support.consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultoria"])},
  "support.video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guias em vídeo"])},
  "support.video_guides.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assista aos nossos guias para garantir que você use nosso aplicativo ao máximo"])},
  "support.video_guides.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduzir vídeo"])},
  "support.onboarding_call.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comece seu primeiro ciclo de OKR de 90 dias com suporte completo ao produto."])},
  "support.onboarding_call.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma chamada"])},
  "support.okr_consulting.link.text.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma revisão gratuita de OKR"])},
  "support.define_your_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina seu nível de prontidão para OKR"])},
  "support.define_your_okr.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalie a prontidão, a cultura e as capacidades da sua organização para implementar OKRs de forma eficaz."])},
  "support.define_your_okr.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina seu nível"])},
  "support.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fale conosco"])},
  "homepage.okr_elements_empty_foreign_objectives": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nenhum resultado-chave atribuído a ", _interpolate(_named("name"))])},
  "action.change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar status"])},
  "dropdown.restore_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar padrão"])},
  "statuses.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomeie estes status para melhor alinhamento com seu fluxo de trabalho."])},
  "statuses.status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do status"])},
  "statuses.progress_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status de progresso"])},
  "statuses.close_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar status"])},
  "statuses.close_statuses.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar status bloqueia o objeto e impede a edição e alteração da nota"])},
  "statuses.notifications.renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status renomeado com sucesso"])},
  "statuses.rename_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear status"])},
  "app.platform.web_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicativo da web"])},
  "app.platform.both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambas as plataformas"])},
  "action.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
  "action.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar"])},
  "action.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desvincular"])},
  "action.create_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar painel"])},
  "action.grant_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conceder acesso à web"])},
  "action.revoke_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revogar acesso à web"])},
  "action.remove_from_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover dos administradores"])},
  "action.grant_web_access.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Observe que isto será", _interpolate(_named("break")), " cobrado separadamente com base", _interpolate(_named("break")), " na licença do aplicativo da web."])},
  "action.select_user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Selecionar usuário"]), _normalize(["Selecionar usuários"])])},
  "action.select_user_or_email": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Insira o e-mail ou selecione o usuário"]), _normalize(["Insira os e-mails ou selecione os usuários"])])},
  "action.update_license_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar status da licença"])},
  "create.okr_element.create_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar outro"])},
  "weights.objective.cant_change_weight_by_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível no plano de assinatura Professional ou superior."])},
  "weights.objective.cant_change_weight_by_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usar pesos personalizados, você precisa ter pelo menos 1 elemento sobreposto."])},
  "action.create_keyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar KR"])},
  "action.link_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrelar item do Jira"])},
  "field.not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não definido"])},
  "field.groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "field.period.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
  "action.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
  "common.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão"])},
  "common.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
  "users.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar usuário"])},
  "user.preferences.user.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de usuário"])},
  "user.preferences.user.email.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "admins.platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plataforma"])},
  "filter.all_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as plataformas"])},
  "common.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativa"])},
  "common.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativa"])},
  "action.create_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar objetivo"])},
  "action.create_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar item Jira"])},
  "action.unlink_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desvincular issue"])},
  "action.paste_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colar Link"])},
  "delete_user.count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Excluir ", _interpolate(_named("count")), " usuários"])},
  "confirm.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tem certeza de que deseja excluir ", _interpolate(_named("entity")), "?"])},
  "menu.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturamento"])},
  "menu.security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança"])},
  "menu.branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgação da marca"])},
  "user_menu.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "user_menu.switch_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar organização"])},
  "user_menu.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
  "user_menu.create_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma nova organização"])},
  "roles.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
  "roles.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsável"])},
  "banner.join_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se agora!"])},
  "banner.okr_accelerator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Accelerator para líderes"])},
  "settings.billing.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturamento"])},
  "notifications.role_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função atualizada com sucesso."])},
  "okr_elements.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos"])},
  "badge.soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em breve"])},
  "global_groups.available_only_in_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas no plug-in Jira"])},
  "onboarding.setup_name.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo ao Oboard!"])},
  "organization.create_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure sua organização"])},
  "organization.invite_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convide usuários"])},
  "organization.invite_users.email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira um ou mais endereços de e-mail separados por vírgula ou espaço"])},
  "organization.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da organização"])},
  "organization.name.updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da organização atualizado com sucesso"])},
  "limitations.create_button.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para sua assinatura, só é possível criar ", _interpolate(_named("limit")), " ", _interpolate(_named("entity")), "."])},
  "limitations.entity.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o número máximo (", _interpolate(_named("limit")), ") de ", _interpolate(_named("entity")), "."])},
  "subscription.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])},
  "subscription.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plano atual"])},
  "subscription.manage_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar plano"])},
  "subscription.manage_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar nível"])},
  "subscription.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
  "subscription.subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinar"])},
  "subscription.user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nível do usuário"])},
  "subscription.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo pagamento"])},
  "subscription.billing_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclo de faturamento"])},
  "subscription.next_payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima data de pagamento"])},
  "subscription.compare_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparar planos"])},
  "subscription.upgrade_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer upgrade de nível"])},
  "subscription.invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de faturas"])},
  "subscription.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura"])},
  "subscription.receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
  "subscription.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de pagamento"])},
  "subscription.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de faturamento"])},
  "subscription.tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID fiscal"])},
  "subscription.try_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por que não experimentar o plano anual? Economize 15%"])},
  "subscription.switch_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar para anual"])},
  "subscription.switch_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar para mensal"])},
  "subscription.upgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer upgrade para anual"])},
  "subscription.upgrade_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer upgrade para mensal"])},
  "subscription.downgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer downgrade para anual"])},
  "subscription.your_current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu plano atual"])},
  "subscription.your_current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu nível atual"])},
  "subscription.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer downgrade"])},
  "subscription.leave_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair mesmo assim"])},
  "subscription.apply_discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aplicar desconto de ", _interpolate(_named("percent"))])},
  "subscription.leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentamos que você esteja saindo"])},
  "subscription.up_sale_promocode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Considere ", _interpolate(_named("discount")), " para continuar conosco"])},
  "subscription.discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " de desconto"])},
  "subscription.subscription_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua assinatura foi cancelada"])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovar"])},
  "subscription.tier_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quantidade de usuários excede o limite do nível. Faça upgrade do seu nível ou exclua usuários"])},
  "subscription.license_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licença inativa"])},
  "subscription.contact_your_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com seu gerente"])},
  "subscription.switch_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de plano"])},
  "subscription.select_subscription_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um ciclo de assinatura"])},
  "subscription.per_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por usuário"])},
  "subscription.per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por mês"])},
  "subscription.current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nível atual"])},
  "subscription.expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de expiração"])},
  "subscription.cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar assinatura"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer upgrade"])},
  "subscription.contact_oboard_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com a equipe do Oboard"])},
  "subscription.schedule_free_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma sessão de demonstração gratuita e veja como implementar OKRs corretamente em diferentes escalas."])},
  "subscription.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma reunião"])},
  "subscription.drop_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixe uma mensagem"])},
  "subscription.your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu nome"])},
  "subscription.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail comercial"])},
  "subscription.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da empresa"])},
  "subscription.company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho da empresa"])},
  "subscription.get_your_price_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenha sua cotação de preço"])},
  "subscription.is_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comece a assinatura"])},
  "subscription.choose_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha o plano"])},
  "subscription.back_to_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para o faturamento"])},
  "subscription.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "subscription.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone"])},
  "subscription.subscription_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura atualizada com sucesso"])},
  "subscription.days_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dias restantes"])},
  "subscription.subscription_free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação grátis"])},
  "subscription.state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "subscription.total_pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total: ", _interpolate(_named("value"))])},
  "subscription.applied_balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saldo aplicado ", _interpolate(_named("value"))])},
  "subscription.amount_due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total devido hoje ", _interpolate(_named("value"))])},
  "subscription.from_to_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " de ", _interpolate(_named("to")), " usuários"])},
  "subscription.payment_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento incompleto. Verifique seu saldo ou atualize a forma de pagamento."])},
  "subscription.upgrade_your_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça upgrade do seu nível"])},
  "subscription.upgrade_your_tier_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["O valor de usuários excede o limite do nível. ", _interpolate(_named("day")), " dia restante para fazer upgrade do seu nível ou excluir usuários."]), _normalize(["O valor de usuários excede o limite do nível. ", _interpolate(_named("day")), " dias restantes para fazer upgrade do seu nível ou excluir usuários."])])},
  "subscription.save_count_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Economize ", _interpolate(_named("count")), "%"])},
  "subscription.annually_save_count_percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECONOMIZE 15% anualmente"])},
  "subscription.annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anualmente"])},
  "subscription.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])},
  "subscription.tier_upper_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O limite superior do nível não pode ser menor que o valor atual dos usuários."])},
  "subscription.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expira:"])},
  "subscription.subscription_wasnt_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A assinatura não foi atualizada"])},
  "subscription.subscription_will_be_downgraded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ocorrerá o downgrade da assinatura para ", _interpolate(_named("name")), " ", _interpolate(_named("cycle")), " no fim do ciclo."])},
  "subscription.number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de usuários"])},
  "subscription.bills_on_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faturas em ", _interpolate(_named("date")), " por 1 mês"])},
  "subscription.web_app_only_delete_from_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários somente do aplicativo da web serão excluídos do Oboard"])},
  "subscription.address_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha de endereço 1"])},
  "subscription.address_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha de endereço 2"])},
  "subscription.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
  "subscription.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "step_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passo ", _interpolate(_named("count")), "."])},
  "connections.connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
  "connections.connected_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado com"])},
  "connections.disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectado"])},
  "connections.connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectando…"])},
  "connections.connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão em andamento"])},
  "connections.disconnection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconexão em andamento"])},
  "connections.disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectando…"])},
  "connections.lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão perdida"])},
  "connections.copy_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar token"])},
  "connections.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token copiado"])},
  "connections.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da conexão"])},
  "connections.token_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colar token de integração"])},
  "connections.paste_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colar token"])},
  "connections.paste_token_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colar token de"])},
  "connections.help.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisa de ajuda?"])},
  "connections.guide.link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guia de integração"])},
  "integration_settings.web_app.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenha o token do plug-in Jira"])},
  "integration_settings.web_app.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você já tem um token, vá para o 2º passo. Caso contrário, certifique-se de ter uma conta do Jira. Abra o Atlassian Marketplace. Instale o plug-in do OKR Board para Jira. Abra o plug-in e vá para Configurações/Integrações/Aplicativo da Web."])},
  "integration_settings.web_app.step2.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cole o token de integração do plug-in do Jira no campo de entrada. Pressione \"Conectar\". A conexão demora um pouco."])},
  "integration_settings.jira.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressione o botão para copiar o token de integração. O tempo de vida do token é de 30 minutos. O token pode ser usado apenas uma vez."])},
  "integration_settings.jira.step2.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para estabelecer a conexão, você precisa ter uma conta do aplicativo da web. Pressione o botão para abrir a página do aplicativo da web.", _interpolate(_named("break")), "Faça o login. Selecione a conta de destino. Navegue até Configurações/Integrações/Plug-in do Jira."])},
  "integration_settings.jira.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para desconectar, pressione o botão para abrir o aplicativo da web e navegue até a conta relacionada."])},
  "integration_settings.connection_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que o plug-in do Jira foi desinstalado. Restaure o plug-in do Jira ou desconecte."])},
  "disconnect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isto resultará nas seguintes alterações"])},
  "disconnect_modal_events_list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova todos os usuários somente do Jira do aplicativo da web"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclua todas as conexões de grupo do Jira em ambos os aplicativos"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova problemas do Jira de todas as visualizações do aplicativo da web"])},
    
  ],
  "actions.chain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstAction")), " e ", _interpolate(_named("secondAction"))])},
  "app_name.jira_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plug-in do Jira"])},
  "app_name.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board para Jira"])},
  "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "action.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear"])},
  "action.leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar avaliação"])},
  "action.access_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de acesso"])},
  "action.add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar forma"])},
  "action.add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar endereço"])},
  "action.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pular"])},
  "action.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar outro"])},
  "objectives.table_header_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuir"])},
  "objectives.table_header_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
  "workspaces.edit_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar espaço de trabalho"])},
  "users.add_to_workspace_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["O usuário será adicionado sem nenhum grupo"]), _normalize(["Os usuários serão adicionados sem nenhum grupo"])])},
  "user_details.delete_user_from_synced_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para excluir o usuário, remova-o do respectivo grupo ", _interpolate(_named("platform")), " sincronizado."])},
  "common.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdução"])},
  "common.complete_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " de ", _interpolate(_named("end"))])},
  "support.instance_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu ID de instância:"])},
  "support.help_center.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre respostas para perguntas comuns."])},
  "dashboard.no_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O painel requer pelo menos um intervalo aberto"])},
  "dashboard.no_intervals_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um primeiro intervalo para começar"])},
  "homepage.okr_elements_empty_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há objetivos com resultados-chave atribuídos a você"])},
  "debug.delete_deactivated_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir usuários desativados"])},
  "jira.dashboard.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alinhamento"])},
  "edit_later.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode editá-los mais tarde"])},
  "onboarding.setup_name.input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira seu nome"])},
  "setup_okr_levels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure seus níveis de OKR"])},
  "setup_workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure seu espaço de trabalho"])},
  "setup_workspace.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode renomear seu espaço de trabalho mais tarde e adicionar mais conforme avança."])},
  "setup_intervals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar intervalos de OKR"])},
  "setup_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar grupos"])},
  "setup_groups.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta é uma estrutura organizacional padrão. ", _interpolate(_named("break")), "Você pode personalizá-la mais tarde na seção de configurações."])},
  "organization.create_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O nome da organização só ficará visível para sua equipe. ", _interpolate(_named("break")), "Você pode alterá-lo no futuro."])},
  "subscription.select_user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar nível de usuário"])},
  "subscription.final_amount_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*O valor final devido é determinado na finalização da compra"])},
  "subscription.number_of_users_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode adicionar mais usuários a qualquer momento. Você será cobrado por esses usuários adicionais automaticamente apenas no próximo período de assinatura."])},
  "subscription.select_user_tier_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode fazer upgrade do nível de usuário a qualquer momento. Se você fizer upgrade do nível de usuário, sua conta será cobrada com base na porcentagem do ciclo de cobrança restante no momento de upgrade do nível."])},
  "subscription.up_to_count_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Até ", _interpolate(_named("count")), " usuários"])},
  "subscription.user_tier_billed_annually": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nível de usuário ", _interpolate(_named("label")), ", cobrado anualmente"])},
  "subscription.per_user_billed_monthly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " usuários, US$", _interpolate(_named("price")), " por usuário, cobrado mensalmente"])},
  "subscription.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "subscription.next_bill_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima data de faturamento"])},
  "subscription.billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de faturamento"])},
  "connections.get_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter token"])},
  "disconnect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar o aplicativo da web do aplicativo do Jira"])},
  "connect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar o aplicativo da web do aplicativo do Jira"])},
  "connect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após pressionar \"Conectar\", todos os espaços de trabalho e OKRs relacionados de ambas as instâncias serão mesclados em um único banco de dados."])},
  "connect_modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O aplicativo da web herdará as configurações internas do aplicativo do Jira, como status, limites e configurações da empresa."])},
  "feedback.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajude-nos a criar um produto melhor"])},
  "feedback.modal.message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que há no plug-in que você considera mais desafiador ou que poderia ser melhorado?"])},
  "feedback.modal.rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como você nos avaliaria?"])},
  "feedback.modal.review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agradeceríamos sua avaliação no marketplace"])},
  "feedback.modal.review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso nos ajuda a crescer mais rápido e a entregar um produto melhor."])}
}