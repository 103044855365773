<template>
  <div class="us-Wrapper">
    <div class="us-Toolbar">
      <div class="us-Filters">
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, WORKSPACE_IDS)"
          :dropdown-min-width="200"
          :loading="loaders.workspaces"
          :model-value="filtersValues[WORKSPACE_IDS]"
          :options="optionsData.workspaces"
          :search-function="$event => useFetchWorkspaceFilter($event, filtersValues)"
          class="us-Filters_FilterSelect"
          has-only-this-button
          n-selected-label="workspaces.workspaces"
          prepend-icon="workspace"
          @update:model-value="onSelectWorkspaceFilter"
          @update:options="optionsData.workspaces = $event"
        >
          <template #option-label="{ option }">
            <WorkspaceSelectOption v-if="option" :workspace="option" hide-lock-icon show-tooltip />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, WORKSPACE_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, GROUP_IDS)"
          :dropdown-min-width="300"
          :loading="loaders.groups"
          :model-value="filtersValues[GROUP_IDS]"
          :options="optionsData.groups"
          :search-function="searchString => fetchGroupsForFilter({ searchString })"
          class="us-Filters_FilterSelect"
          has-only-this-button
          n-selected-label="filter.groups"
          prepend-icon="team-next"
          @update:model-value="changeSelectedValue($event, GROUP_IDS)"
          @update:options="optionsData.groups = $event"
        >
          <template #option-label="{ option }">
            <GlobalGroupsSelectOption v-if="option" :group="option" />
          </template>

          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, GROUP_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>

        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, ACCOUNT_IDS)"
          :dropdown-min-width="200"
          :loading="loaders.accounts"
          :model-value="filtersValues[ACCOUNT_IDS]"
          :options="optionsData.accounts"
          :search-function="$event => useFetchUserFilter($event, filtersValues)"
          class="us-Filters_FilterSelect"
          has-only-this-button
          item-value="accountId"
          n-selected-label="common.users"
          prepend-icon="user-next"
          @update:model-value="changeSelectedValue($event, ACCOUNT_IDS)"
          @update:options="optionsData.accounts = $event"
        >
          <template #option-label="{ option }">
            <OwnerFieldOption :option="option" label-key="name" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, ACCOUNT_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, ROLE_IDS)"
          :dropdown-min-width="200"
          :dropdown-search="false"
          :loading="loaders.roles"
          :model-value="filtersValues[ROLE_IDS]"
          :options="optionsData.roles"
          class="us-Filters_FilterSelect"
          has-only-this-button
          item-label="label"
          item-value="value"
          n-selected-label="users.roles.title"
          prepend-icon="active"
          @update:model-value="changeSelectedValue($event, ROLE_IDS)"
        >
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, ROLE_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>

        <OkrFilterSelect
          v-if="isPlatformsCellNeed"
          :bottom-fixed-items="getBottomFixedItemsClearSelection(filtersValues, PLATFORM_IDS)"
          :dropdown-min-width="200"
          :dropdown-search="false"
          :model-value="filtersValues[PLATFORM_IDS]"
          :options="optionsData.platforms"
          class="us-Filters_FilterSelect"
          has-only-this-button
          item-label="label"
          item-value="value"
          n-selected-label="users.roles.title"
          prepend-icon="platforms"
          @update:model-value="changeSelectedValue($event, PLATFORM_IDS)"
        >
          <template #option-label="{ option }">
            <PlatformFilterOption v-if="option" :option="option" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="isClearSelectionAction(item.action)"
                @click="bottomFixedItemsHandle(item.action, PLATFORM_IDS)"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>

        <ResetFilterButton v-if="showResetFilters" @click="onResetFilters" />
      </div>

      <DropdownMenu
        :items="USER_MANAGEMENT_ACTIONS_DROPDOWN_MENU_ITEMS"
        :offset="[0, 0]"
        data-testid="add-user-dropdown"
        preferred-position="bottom-end"
        type="default-next"
        @item-click="handleAddAction"
      >
        <template #item-label="{ item }">
          <DropdownMenuItemLabelWithDescription :label-item="item" />
        </template>
        <template #activator>
          <AppButton icon="plus-next" type="primary-next">
            {{ t('users.create') }}
          </AppButton>
        </template>
      </DropdownMenu>
    </div>
    <div class="us-Description">
      {{ t('workspaces.manage_workspace.access') }}
    </div>

    <UsersTableLoader v-if="loaders.table" :show-platforms-cell="isPlatformsCellNeed" />
    <AppTable
      v-else-if="tableUsersData.length && !loaders.table"
      :columns="tableColumns"
      :data="tableUsersData"
      :hover-row="tableHoverRow"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      type="primary-next"
    >
      <template #header-cell="{ column }">
        <template v-if="column.key === CHECKBOX">
          <AppCheckbox
            v-model="selectAll"
            data-testid="select-all-checkbox"
            size="sm"
            type="default-next"
            @update:model-value="onToggleAllUsers"
          />
        </template>
      </template>

      <template #cell="{ columnKey, item, index }">
        <template v-if="columnKey === CHECKBOX">
          <AppCheckboxListItem
            :data-testid="`select-user-${index}`"
            :model-value="selectedUsers"
            :val="item.accountId"
            type="default-next"
            @update:model-value="onChangeCheckboxListItem"
          />
        </template>
        <template v-if="columnKey === NAME">
          <UserNameCell :item="item" item-label="name" name-as-link />
        </template>

        <template v-if="columnKey === SYNC && isShowSyncIconCell(item)">
          <div class="us-SyncCell">
            <AppIcon height="24" icon-name="replace_jira" width="24" />
          </div>
        </template>

        <template v-if="columnKey === GROUPS">
          <div class="us-GroupsCell">
            <LimitTagList
              v-if="!isEmpty(item.groups)"
              :items="item.groups"
              :limit="3"
              style="--list-gap: 4px; --count-offset: 0"
              tooltip-value="name"
            >
              <template #item="{ item: group }">
                <GroupIcon
                  :clickable="hasAccessToGlobalGroupsPage"
                  :color="group.color"
                  :icon-name="group.icon"
                  class="us-GroupIcon"
                  @click="onGroupClick(group.id)"
                />
              </template>
            </LimitTagList>
            <AppButton
              :class="{ 'us-GroupsCell_AddGroup-withGroups': !isEmpty(item.groups) }"
              class="us-GroupsCell_AddGroup"
              height="24"
              icon="plus-next"
              remove-padding
              size="sm"
              type="tertiary-next"
              width="24"
              @click="onAddUserToGroupClick(item)"
            />
          </div>
        </template>
        <template v-if="columnKey === PRIVATE_WORKSPACES">
          <LimitTagList
            :items="getFilteredWorkspaces(item.workspaces, false)"
            :limit="3"
            style="--list-gap: 4px; --count-offset: 0"
            tooltip-value="tooltipLabel"
          >
            <template #item="{ item: workspace }">
              <WorkspaceIcon :option="workspace" />
            </template>
          </LimitTagList>
        </template>
        <template v-if="columnKey === PUBLIC_WORKSPACES">
          <LimitTagList
            :items="getFilteredWorkspaces(item.workspaces)"
            :limit="3"
            style="--list-gap: 4px; --count-offset: 0"
            tooltip-value="tooltipLabel"
          >
            <template #item="{ item: workspace }">
              <WorkspaceIcon :option="workspace" />
            </template>
          </LimitTagList>
        </template>
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.PLATFORM">
          <UserPlatformsCell
            :loading="platformCellLoadingIds.includes(item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])"
            :platforms="item[WEB_APP_ENTITY_KEYS.PLATFORM_IDS]"
          />
        </template>
        <template v-if="columnKey === ROLES_IN_THE_WORKSPACES">
          <span
            v-tippy="{
              content: useGetUserRolesText(item.workspaces, optionsData.roles).tooltipText,
              placement: 'bottom-start',
              theme: 'translucent-next word-break semi-bold-text',
              allowHTML: true
            }"
          >
            {{ useGetUserRolesText(item.workspaces, optionsData.roles).label }}
          </span>
        </template>
        <template v-if="columnKey === ACTION">
          <DropdownMenu
            :items="menuItemActions(item)"
            :offset="[0, 0]"
            position="bottom-end"
            type="default-next"
            @close="tableHoverRow = -1"
            @open="tableHoverRow = index"
            @item-click="onMenuActionsClick($event, item)"
          >
            <template #activator="{ isOpened }">
              <AppButton
                :class="{ 'us-ActionsButton-active': isOpened }"
                class="us-ActionsButton"
                icon="more-next"
                size="sm"
                type="tertiary-next"
              />
            </template>

            <template #item-label="{ item: labelItem }">
              <DropdownMenuItemLabelWithDescription :label-item="labelItem" />
            </template>
          </DropdownMenu>
        </template>
      </template>
      <template #footer>
        <DropdownMenu
          v-if="!selectedUsers.length"
          :items="USER_MANAGEMENT_ACTIONS_DROPDOWN_MENU_ITEMS"
          :offset="[0, 0]"
          data-testid="add-user-table-dropdown"
          follow-cursor="initial"
          preferred-position="bottom-start"
          type="default-next"
          @item-click="handleAddAction"
        >
          <template #item-label="{ item }">
            <DropdownMenuItemLabelWithDescription :label-item="item" />
          </template>
          <template #activator>
            <AppTableCreateButton full-width icon-name="plus-next">
              {{ t('users.create') }}
            </AppTableCreateButton>
          </template>
        </DropdownMenu>
      </template>
    </AppTable>
    <NoSearchResults v-else>
      {{ t('users.no_matching_filters') }}
      <AppButton class="us-BtnResetFilters" size="sm" type="link-next" @click="onResetFilters">
        {{ $t('search_criteria.reset') }}
      </AppButton>
    </NoSearchResults>
    <AppSnackbar
      :actions="snackbarActions"
      :count="selectedUsers.length"
      class="us-SnackbarContainer"
      inline
      sticky
      style="--text-color: var(--dark-2)"
      @close="clearCheckboxes"
      @action-click="onSnackbarActionClick"
    />
    <AppPagination
      v-if="tableUsersData.length"
      :current-page="currentPage"
      :items-count="totalUsers"
      :items-on-page="itemsOnPage"
      :items-shown="tableUsersData.length"
      :total-page="totalPage"
      @update:items-on-page="changeItemsOnPage"
      @update:current-page="changeCurrentPage"
    />
  </div>
  <UserDeleteModal
    :show="showUserBulkDeleteModal"
    :title="
      t(
        'delete_user.title',
        { userName: useGetDeleteUserName(selectedUsers, tableUsersData, deleteUserConfig) },
        selectedUsers.length
      )
    "
    @on-close="showUserBulkDeleteModal = false"
    @on-confirm="onDeleteUserBulk(selectedUsers)"
  >
    {{ t('confirm.user.message', selectedUsers.length) }}
  </UserDeleteModal>
  <BulkAddUsersToWorkspaceModal
    v-model:show="isShowAddUserToWorkspaceModal"
    :selected-users="selectedUsers"
    @reload-data="reloadTableData"
  />

  <UserDeleteModal
    :show="isShowUserDeleteModal"
    :title="t('delete_user.title', { userName: deleteUserConfig.name })"
    @on-close="closeUserDeleteModal"
    @on-confirm="onDeleteUserBulk([deleteUserConfig.accountId])"
  >
    {{ t('confirm.user.message') }}
  </UserDeleteModal>
  <AddUsersToWorkspaceModal
    v-model:show="isShowAddUsersToWorkspaceModal"
    :roles="optionsData.roles"
    data-testid="add-user-to-workspace-modal"
    @reload-data="reloadTableData"
  />
  <AddUsersToGroupModal v-model:show="isShowAddUsersToGroupModal" @reload-data="reloadTableData" />
  <AddUserToGroupModal
    v-model:show="isShowAddUserToGroupModal"
    :tracking-source="EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB"
    :user="userData"
    :workspace-ids="[]"
    @reload-data="reloadTableData"
  />
  <RemoveUserFromGroupModal
    v-model:show="isShowUserRemoveFromGroupModal"
    :tracking-source="EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB"
    :user="deleteUserConfig"
    @reload-data="reloadTableData"
  />
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import {
  clearSelection,
  getBottomFixedItemsClearSelection,
  isClearSelectionAction
} from '@/composables/bottom-fixed-items'
import { useGlobalGroups, useGoToEditGroup } from '@/composables/global-groups'
import { useGetPaginationData } from '@/composables/pagination'
import {
  useInitialGroupsForFilter,
  useCanDeleteBulkUsers,
  useDeleteUserFromPluginBulk,
  useFetchPluginUsers,
  useFetchRoles,
  useFetchUserFilter,
  useFetchWorkspaceFilter,
  useGetDeleteUserName,
  useGetSelectedUsers,
  useGetUserRolesText
} from '@/composables/plugin-users'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import {
  EVENT_SECTIONS,
  EVENT_SOURCES,
  FILTER_LABELS_FOR_TRACKING,
  MODE_NAMES_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackApplySettingsPagesFilterEvent,
  trackOpenAddUserModalEvent,
  trackRemoveUserEvent
} from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { ASSIGNEE_ENTITY_KEYS, WEB_APP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import {
  DEFAULT_VALUE_FOR_FILTER,
  restoreFilterValue,
  SELECT_ALL_VALUE
} from '@/utils/okr-elements/filters'
import { readFromStorageByKey, updateStorageByKey } from '@/utils/persist'
import {
  usePlatformHelpers,
  usePlatformFilter,
  useWebAppAccessActionsForUser
} from '@/utils/platforms-helpers'
import { replaceQueryParameters } from '@/utils/router'
import { getNewSelectWithSelectAllValue } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import {
  checkFormModelOnDefaultValue,
  isShowSyncIconCell,
  USER_MANAGEMENT_ACTIONS_DROPDOWN_MENU_ITEMS
} from '@/utils/users-helpers'

import AppCheckboxListItem from '@/components/form/AppCheckboxListItem'
import LimitTagList from '@/components/form/LimitTagList'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupIcon from '@/components/global-groups/GroupIcon'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppPagination from '@/components/ui/AppPagination/AppPagination'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import DropdownMenuItemLabelWithDescription from '@/components/ui/DropdownMenu/DropdownMenuItemLabelWithDescription'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import PlatformFilterOption from '@/components/ui/PlatformFilterOption/PlatformFilterOption'
import ResetFilterButton from '@/components/ui/ResetFilterButton'
import UsersTableLoader from '@/components/ui/SkeletonLoaders/UsersTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'
import UserDeleteModal from '@/components/workspaces/UserDeleteModal'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'
import AddUsersToGroupModal from '@/views/workspaces/settings/plugin-users/AddUsersToGroupModal'
import AddUsersToWorkspaceModal from '@/views/workspaces/settings/plugin-users/AddUsersToWorkspaceModal'
import AddUserToGroupModal from '@/views/workspaces/settings/plugin-users/AddUserToGroupModal'
import BulkAddUsersToWorkspaceModal from '@/views/workspaces/settings/plugin-users/BulkAddUsersToWorkspaceModal'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'
import UserPlatformsCell from '@/views/workspaces/settings/plugin-users/UserPlatformsCell'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'
import RemoveUserFromGroupModal from '@/views/workspaces/settings/workspace-users/RemoveUserFromGroupModal'

defineOptions({
  name: 'PluginUsersPage'
})

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const { WORKSPACE_IDS, GROUP_IDS, ACCOUNT_IDS, ROLE_IDS, PLATFORM_IDS } = USERS_QUERY_KEYS

const validateQueryParams = () => {
  const data = ref({})
  const DATA_QUERY_KEYS = {
    WORKSPACE_IDS: 'workspaces',
    GROUP_IDS: 'groups',
    ACCOUNT_IDS: 'accounts',
    ROLE_IDS: 'roles',
    PLATFORM_IDS: 'platforms'
  }
  Object.entries(DATA_QUERY_KEYS).forEach(([key, value]) => {
    const restoredValue = restoreFilterValue(
      route,
      USERS_QUERY_KEYS[key],
      DEFAULT_VALUE_FOR_FILTER,
      true
    )
    const isValueValid = restoredValue.every(id =>
      optionsData.value[value].find(
        item => item.id === id || item.accountId === id || item.value === id
      )
    )
    if (!isValueValid) {
      data.value[USERS_QUERY_KEYS[key]] = DEFAULT_VALUE_FOR_FILTER
    }
  })

  filtersValues.value = { ...filtersValues.value, ...data.value }
}

const LS_KEY = 'PLUGIN_USERS_FILTER'
const setQueryFilter = () => {
  const query = Object.values(USERS_QUERY_KEYS).reduce((acc, key) => {
    acc[key] = JSON.stringify(filtersValues.value[key].map(id => id))
    return acc
  }, {})
  replaceQueryParameters(router, route, query)
  updateStorageByKey(LS_KEY, query)
}
const parseQueryFilter = () => {
  Object.values(USERS_QUERY_KEYS).forEach(key => {
    filtersValues.value[key] = restoreFilterValue(route, key, DEFAULT_VALUE_FOR_FILTER, true)
  })
  if (Object.values(filtersValues.value).every(value => isEqual(value, DEFAULT_VALUE_FOR_FILTER))) {
    const dataFromLs = readFromStorageByKey(LS_KEY)
    if (dataFromLs && !isEmpty(dataFromLs)) {
      filtersValues.value = Object.keys(DEFAULT_FORM_MODEL).reduce((acc, key) => {
        const valueFromLs = readFromStorageByKey(LS_KEY)[key]

        return {
          ...acc,
          [key]: valueFromLs ? JSON.parse(valueFromLs) : DEFAULT_FORM_MODEL[key]
        }
      }, {})
    }
  }

  if (!isPlatformsCellNeed) {
    filtersValues.value[PLATFORM_IDS] = cloneDeep(DEFAULT_VALUE_FOR_FILTER)
  }
}

const DEFAULT_FORM_MODEL = {
  [WORKSPACE_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [GROUP_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [ACCOUNT_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [ROLE_IDS]: DEFAULT_VALUE_FOR_FILTER,
  [PLATFORM_IDS]: DEFAULT_VALUE_FOR_FILTER
}
const filtersValues = ref({ ...DEFAULT_FORM_MODEL })
const loaders = ref({
  table: false,
  workspaces: false,
  groups: false,
  accounts: false,
  roles: false
})

const showResetFilters = computed(() => {
  return checkFormModelOnDefaultValue(filtersValues.value)
})
const onResetFilters = () => {
  filtersValues.value = cloneDeep(DEFAULT_FORM_MODEL)

  currentPage.value = START_PAGE
  setQueryFilter()

  reloadTableData()
}
const changeSelectedValue = async (newValue, key) => {
  filtersValues.value[key] = getNewSelectWithSelectAllValue(newValue, filtersValues.value[key])

  trackApplySettingsPagesFilterEvent({
    section: EVENT_SECTIONS.GROUPS_AND_USERS,
    tab: TAB_NAMES_FOR_TRACKING.USERS,
    label: FILTER_LABELS_FOR_TRACKING[key]
  })

  await reloadTableData()
}

const { fetchGroupsForFilter, ALL_GROUPS_OPTION } = useGlobalGroups()

const optionsData = ref({
  workspaces: [
    {
      name: t('users.all_workspaces'),
      id: SELECT_ALL_VALUE
    }
  ],
  groups: [ALL_GROUPS_OPTION],
  accounts: [
    {
      name: t('filter.all_users'),
      accountId: SELECT_ALL_VALUE
    }
  ],
  roles: [],
  platforms: []
})

const {
  START_PAGE,
  currentPage,
  itemsOnPage,
  total: totalUsers,
  totalPage
} = useGetPaginationData()

const tableUsersData = ref([])
watch(
  () => filtersValues.value,
  async () => {
    currentPage.value = START_PAGE
    setQueryFilter()
  },
  { deep: true }
)

const reloadTableData = async (clearSelection = true) => {
  if (clearSelection) {
    clearCheckboxes()
  }
  try {
    loaders.value.table = true

    const payload = cloneDeep(filtersValues.value)

    if (!isPlatformsCellNeed) {
      delete payload[PLATFORM_IDS]
    }

    const { users, total } = await useFetchPluginUsers(payload, {
      currentPage: currentPage.value,
      itemsOnPage: itemsOnPage.value
    })
    tableUsersData.value = users
    totalUsers.value = total
  } finally {
    loaders.value.table = false
  }
}
const { getInitialGroupsForFilter } = useInitialGroupsForFilter()
const getInitData = async () => {
  optionsData.value.roles = await useFetchRoles()
  optionsData.value.platforms = PLATFORM_FILTER_OPTIONS
  loaders.value.workspaces = true
  loaders.value.groups = true
  loaders.value.accounts = true
  loaders.value.table = true
  loaders.value.roles = true
  try {
    parseQueryFilter()
    const [workspacesData, groupsData, accountsData] = await Promise.all([
      useFetchWorkspaceFilter(null, filtersValues.value),
      getInitialGroupsForFilter({
        selectedGroups: filtersValues.value[GROUP_IDS]
      }),
      useFetchUserFilter(null, filtersValues.value)
    ])
    optionsData.value.workspaces = [...optionsData.value.workspaces, ...workspacesData]
    optionsData.value.groups = [...optionsData.value.groups, ...groupsData]
    optionsData.value.accounts = [...optionsData.value.accounts, ...accountsData]
    validateQueryParams()
  } finally {
    loaders.value.workspaces = false
    loaders.value.groups = false
    loaders.value.accounts = false
    loaders.value.roles = false
  }
}
onMounted(async () => {
  await getInitData()
  await reloadTableData()
})

const onSelectWorkspaceFilter = async payload => {
  changeSelectedValue(payload, WORKSPACE_IDS)
  filtersValues.value.groupIds = filtersValues.value.groupIds.filter(selectedGroupId => {
    return optionsData.value.groups.find(resolvedGroup => {
      return resolvedGroup.id === selectedGroupId
    })
  })
  if (isEmpty(filtersValues.value.groupIds)) {
    changeSelectedValue([SELECT_ALL_VALUE], GROUP_IDS)
  }
}

const tableHoverRow = ref(-1)
const {
  CHECKBOX,
  NAME,
  PRIVATE_WORKSPACES,
  PUBLIC_WORKSPACES,
  ROLES_IN_THE_WORKSPACES,
  SYNC,
  GROUPS,
  ACTION
} = TABLE_COLUMNS_KEYS

const { isPlatformsCellNeed, platformCell } = usePlatformHelpers()
const { OPTIONS: PLATFORM_FILTER_OPTIONS } = usePlatformFilter()
const { getWebAppAccessAction, onGrantRevokeWebAccess, platformCellLoadingIds } =
  useWebAppAccessActionsForUser()

const tableColumns = computed(() => {
  return [
    {
      key: CHECKBOX,
      width: 28
    },
    {
      title: t('users.table_header_name'),
      key: NAME,
      width: 'auto'
    },
    {
      key: SYNC,
      width: 32
    },
    {
      title: t('field.groups.title'),
      key: GROUPS,
      width: 160
    },
    {
      title: t('settings.private_workspaces'),
      key: PRIVATE_WORKSPACES,
      width: 140
    },
    {
      title: t('settings.public_workspaces'),
      key: PUBLIC_WORKSPACES,
      width: 140
    },
    platformCell,
    {
      title: t('settings.roles_in_the_workspaces'),
      key: ROLES_IN_THE_WORKSPACES,
      width: 146
    },
    {
      key: ACTION,
      width: 24
    }
  ].filter(Boolean)
})

const getFilteredWorkspaces = (workspaces, isPublic = true) => {
  return workspaces
    .filter(workspace => workspace.public === isPublic)
    .map(workspace => ({
      ...workspace,
      tooltipLabel: `[${workspace.key}] ${workspace.name}`
    }))
}

const { selectedUsers, selectAll, clearCheckboxes, onToggleAllUsers, onChangeCheckboxListItem } =
  useGetSelectedUsers(tableUsersData)

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const handleAddAction = action => {
  const [method] = [
    action === ACTIONS_KEYS.ADD_TO_GROUP && onAddUsersToGroupClick,
    action === ACTIONS_KEYS.ADD_TO_WORKSPACE && onAddUserToWorkspaceClick
  ].filter(Boolean)

  method()

  const [mode] = [
    action === ACTIONS_KEYS.ADD_TO_GROUP && MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
    action === ACTIONS_KEYS.ADD_TO_WORKSPACE && MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE
  ].filter(Boolean)

  trackOpenAddUserModalEvent({
    source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB,
    mode,
    tab: TAB_NAMES_FOR_TRACKING.SINGLE,
    role: userRoleForTracking.value
  })
}

const onAddUsersToGroupClick = () => {
  isShowAddUsersToGroupModal.value = true
}
const onAddUserToWorkspaceClick = () => {
  isShowAddUsersToWorkspaceModal.value = true
}

const userData = ref(null)
const onAddUserToGroupClick = payload => {
  userData.value = payload
  isShowAddUserToGroupModal.value = true
  trackOpenAddUserModalEvent({
    role: userRoleForTracking.value,
    source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB,
    mode: MODE_NAMES_FOR_TRACKING.ADD_TO_GROUP,
    tab: TAB_NAMES_FOR_TRACKING.SINGLE
  })
}

const snackbarActions = computed(() => {
  const canDeleteSelectedUsers = useCanDeleteBulkUsers(selectedUsers.value, tableUsersData.value)

  return [
    {
      name: ACTIONS_KEYS.DEFAULT,
      icon: 'letter-a',
      title: 'user_details.add_user'
    },
    {
      name: ACTIONS_KEYS.DELETE,
      icon: 'delete-next',
      title: 'action.delete',
      color: 'var(--grade-low-color-next)',
      disable: !canDeleteSelectedUsers,
      tooltipContent: !canDeleteSelectedUsers
        ? t('users.delete_user_from_plugin', {
            platform: t('app.platform.jira')
          })
        : ''
    }
  ]
})

const onSnackbarActionClick = name => {
  if (name === ACTIONS_KEYS.DEFAULT) {
    showAddUserToWsModal()
  }
  if (name === ACTIONS_KEYS.DELETE) {
    showUserBulkDeleteModal.value = true
  }
}

const bottomFixedItemsHandle = async (action, key) => {
  if (isClearSelectionAction(action)) {
    clearSelection(filtersValues.value, key)
    await reloadTableData()
  }
}

const showUserBulkDeleteModal = ref(false)

const closeUserBulkDeleteModal = async () => {
  showUserBulkDeleteModal.value = false
  clearCheckboxes()
  closeUserDeleteModal()
  await reloadTableData()
}
const isLastPage = computed(() => currentPage.value === totalPage.value)

const onDeleteUserBulk = async accountIds => {
  try {
    await useDeleteUserFromPluginBulk(accountIds)
    showNotify({ title: t('notifications.user_deleted', accountIds.length) })

    trackRemoveUserEvent({
      mode: MODE_NAMES_FOR_TRACKING.PLUGIN,
      source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB
    })

    if (isLastPage.value) {
      currentPage.value = START_PAGE
    }
    await closeUserBulkDeleteModal()
  } catch (error) {
    handleError({ error })
  }
}

const isShowAddUserToWorkspaceModal = ref(false)
const showAddUserToWsModal = () => {
  isShowAddUserToWorkspaceModal.value = true
  trackOpenAddUserModalEvent({
    source: EVENT_SOURCES.GLOBAL_GROUPS_ALL_USERS_TAB,
    mode: MODE_NAMES_FOR_TRACKING.BULK_ADD_TO_WORKSPACE,
    tab: TAB_NAMES_FOR_TRACKING.SINGLE,
    role: userRoleForTracking.value
  })
}

const changeCurrentPage = page => {
  currentPage.value = page
  reloadTableData()
}
const changeItemsOnPage = value => {
  itemsOnPage.value = value
  currentPage.value = START_PAGE
  reloadTableData()
}

const menuItemActions = item => {
  const result = [
    {
      name: ACTIONS_KEYS.EDIT,
      title: 'action.edit_on_user_page',
      icon: 'edit-next',
      group: MENU_ITEMS_GROUPS.EDITING
    }
  ]

  result.push({
    name: ACTIONS_KEYS.REMOVE,
    title: 'action.delete_user',
    icon: 'delete-next',
    color: 'var(--grade-low-color-next)',
    group: MENU_ITEMS_GROUPS.REMOVING,
    disabled: !item.canBeDeleted,
    tooltipContent: item.canBeDeleted
      ? null
      : t('user_details.delete_user_from_synced_group', {
          platform: t('app.platform.jira')
        })
  })
  // if (isEmpty(item.groups)) {
  //
  // } else {
  //   result.push({
  //     name: ACTIONS_KEYS.REMOVE_FROM_GROUP,
  //     title: 'action.remove_from_group',
  //     icon: 'delete-next',
  //     color: 'var(--grade-low-color-next)',
  //     group: MENU_ITEMS_GROUPS.REMOVING
  //   })
  // }

  const grantRevokeWebAccessAction = getWebAppAccessAction({
    user: item
  })

  if (grantRevokeWebAccessAction) {
    result.unshift(grantRevokeWebAccessAction)
  }

  return result
}

const onMenuActionsClick = (name, item) => {
  if (name === ACTIONS_KEYS.REMOVE) {
    deleteUserConfig.value = item
    isShowUserDeleteModal.value = true
  }

  if (name === ACTIONS_KEYS.EDIT) {
    router.push({
      name: ROUTE_NAMES.ORGANIZATION_USER_DETAILS,
      params: { accountId: item.accountId }
    })
  }

  if (name === ACTIONS_KEYS.REMOVE_FROM_GROUP) {
    deleteUserConfig.value = item
    isShowUserRemoveFromGroupModal.value = true
  }

  if (name === ACTIONS_KEYS.GRANT_ACCESS || name === ACTIONS_KEYS.REVOKE_ACCESS) {
    onGrantRevokeWebAccess({
      user: item,
      action: name,
      tableData: tableUsersData.value
    })
  }
}

const isShowUserRemoveFromGroupModal = ref(false)
const isShowUserDeleteModal = ref(false)
const deleteUserConfig = ref({})
const closeUserDeleteModal = () => {
  isShowUserDeleteModal.value = false
  deleteUserConfig.value = {}
}

const isShowAddUsersToWorkspaceModal = ref(false)
const isShowAddUsersToGroupModal = ref(false)
const isShowAddUserToGroupModal = ref(false)

const hasAccessToGlobalGroupsPage = computed(
  () => store.state.system.userData.hasAccessToGlobalGroupsPage
)

const { goToEditGroup } = useGoToEditGroup()

const onGroupClick = groupId => {
  goToEditGroup({ groupId })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
@import '@/assets/styles/_mixins';

.us-Filters {
  display: flex;
  align-items: center;
  gap: 8px;
}
.us-Filters_FilterSelect {
  max-width: 158px;
}

.us-Description {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  font-family: $system-ui;
  margin-bottom: 15px;
}
.us-Wrapper {
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  margin-top: 16px;
  padding-bottom: 16px;
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
.us-Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.us-SnackbarContainer {
  padding: 16px 32px;
  margin: 0 -32px;
  box-shadow: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 32px;
    width: calc(100% - 32px - 32px);
    height: 2px;
    background: $grey-2-next;
  }
}

.us-ActionsButton {
  &-active {
    background-color: $dark-2;
    color: $white;
  }
  @extend %app-table-hidden-items;
}

.us-BtnResetFilters {
  margin: auto;
}

.us-SyncCell {
  display: flex;
  align-items: center;
}

.us-GroupIcon {
  .tb-Row:hover &,
  .tb-RowWrapper-hovered & {
    background-color: #{getGlobalGroupHoverColor(var(--color))};
  }
}

.us-GroupsCell {
  display: flex;
  padding-right: 8px;
  gap: 4px;
}

.us-GroupsCell_AddGroup {
  color: $primary-color-next;
  &-withGroups {
    @extend %app-table-hidden-items;
  }
}
</style>
