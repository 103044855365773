<template>
  <FormFieldNext :label="$t('group.select_workspace')">
    <div class="ggws-WorkspaceSelectWrapper">
      <AppSelect
        ref="workspaceSelectReference"
        :is-error="isError"
        :loading="isLoading"
        :max-height="isAllWorkspacesOptionSelected ? 220 : 229"
        :model-value="selectedWorkspaces"
        :offset="[0, '-100%']"
        :options="workspacesOptions"
        :search-function="getWorkspacesList"
        :split-first-option="splitFirstOption"
        append-to="parent"
        data-auto-testid="workspaces-select"
        data-testid="workspaces-select"
        dropdown-search
        hide-selected-items-in-dropdown
        item-label="name"
        item-value="id"
        multi
        n-selected-label="group.select_workspace"
        show-all-selected
        show-selected-options-inside
        skeleton-loader
        skeleton-loader-height="100%"
        skeleton-loader-width="100%"
        theme="no-shadow-next light"
        type="default-next"
        @open="$emit('open')"
        @update:model-value="onSelectWorkspaces"
        @update:options="allWorkspaces = $event"
      >
        <template #button-content="{ options }">
          <span v-if="isEmpty(options)" class="ggws-WorkspaceSelectEmptyLabel">
            {{ $t('group.select_workspace') }}
          </span>
          <WorkspaceSelectOption
            v-if="isAllWorkspacesOptionSelected"
            :workspace="ALL_WORKSPACES_OPTION"
          >
            <template #icon>
              <AppIcon
                :icon-name="ALL_WORKSPACES_OPTION.icon"
                class="ggws-AllWorkspacesOption_Icon"
                height="24"
                width="24"
              />
            </template>
          </WorkspaceSelectOption>
        </template>
        <template #option-label="{ option }">
          <WorkspaceSelectOption v-if="option" :workspace="option">
            <template v-if="option.id === SELECT_ALL_VALUE" #icon>
              <AppIcon
                :icon-name="ALL_WORKSPACES_OPTION.icon"
                class="ggws-AllWorkspacesOption_Icon"
                height="24"
                width="24"
              />
            </template>
          </WorkspaceSelectOption>
        </template>
      </AppSelect>
    </div>
    <AppFieldError v-if="isError" :data-testid="errorMessageTestId" :show="isError">
      {{ $t('field.required') }}
    </AppFieldError>
  </FormFieldNext>
</template>

<script setup>
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { handleError } from '@/utils/error-handling'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { stringOrNullProp } from '@/utils/prop-validators'
import { handleSelectInputWithSelectAll, selectAllIsSelected } from '@/utils/select'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'

defineOptions({
  name: 'GlobalGroupsWorkspaceSelect'
})

const props = defineProps({
  selectedWorkspaces: {
    type: Array,
    required: true
  },

  hideAllWorkspacesOption: {
    type: Boolean
  },

  isError: {
    type: Boolean
  },

  errorMessageTestId: {
    validator: v => stringOrNullProp(v),
    default: null
  }
})

const workspaceSelectReference = ref(null)

const store = useStore()
const { t } = useI18n()

const userData = computed(() => store.state.system.userData)

const hasAccessToGlobalGroupsPage = computed(() => {
  return userData.value?.hasAccessToGlobalGroupsPage
})

const ALL_WORKSPACES_OPTION = {
  icon: 'workspace',
  name: t('users.all_workspaces'),
  id: SELECT_ALL_VALUE,
  key: null
}

const allWorkspaces = ref([])
const isLoading = ref(false)

const getWorkspacesList = async (searchString = null) => {
  const api = new GlobalGroupsApiHandler()

  isLoading.value = !searchString

  try {
    const requiredWorkspaceIds = searchString ? [] : props.selectedWorkspaces
    return await api.getWorkspacesForManageGlobalGroup({
      searchString,
      requiredWorkspaceIds
    })
  } catch (error) {
    handleError({ error })
    return []
  } finally {
    isLoading.value = false
  }
}

const workspacesOptions = computed(() => {
  if (hasAccessToGlobalGroupsPage.value && !props.hideAllWorkspacesOption) {
    return [
      {
        ...ALL_WORKSPACES_OPTION
      },
      ...allWorkspaces.value
    ]
  }
  return [...allWorkspaces.value]
})

const splitFirstOption = computed(() => {
  if (hasAccessToGlobalGroupsPage.value && !props.hideAllWorkspacesOption) {
    return !isAllWorkspacesOptionSelected.value
  }

  return false
})

const isAllWorkspacesOptionSelected = computed(() => {
  return selectAllIsSelected(props.selectedWorkspaces)
})

const emit = defineEmits(['update:selectedWorkspaces', 'open'])
const onSelectWorkspaces = newValue => {
  if (isEmpty(newValue)) {
    emit('update:selectedWorkspaces', [])
    return
  }
  const oldValue = cloneDeep(props.selectedWorkspaces)
  let { valueChanged, result } = handleSelectInputWithSelectAll(newValue, oldValue)
  valueChanged = valueChanged || !isEqual(oldValue, result)

  if (valueChanged) {
    if (isEqual(result, DEFAULT_VALUE_FOR_FILTER)) {
      workspaceSelectReference.value.hideDropdown()
    }
    emit('update:selectedWorkspaces', result)
  }
}

onMounted(async () => {
  allWorkspaces.value = await getWorkspacesList()
})
</script>

<style lang="scss" scoped>
.ggws-AllWorkspacesOption_Icon {
  color: $dark-2;
}

.ggws-WorkspaceSelectEmptyLabel {
  padding-left: 2px;
}

.ggws-WorkspaceSelectWrapper {
  position: relative;

  --select-skeleton-top: 0;
  --select-skeleton-left: 0;

  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }

  :deep(.o-checkbox-label-text) {
    overflow: visible;
  }
}
</style>
