<template>
  <AppSelect
    v-model="localModelValue"
    :item-label="CUSTOM_FIELD_ENTITY_KEYS.NAME"
    :item-value="CUSTOM_FIELD_ENTITY_KEYS.ID"
    :max-height="getMaxDropdownHeight()"
    :offset="[0, -40]"
    :options="allOptions"
    :search-function="value => localSearch({ value, options })"
    boundary="scrollParent"
    dropdown-search
    skeleton-loader
    skeleton-loader-height="100%"
    skeleton-loader-width="100%"
    split-first-option
    theme="no-shadow-next light"
    type="default-next"
  />
</template>

<script setup>
import { computed } from 'vue'

import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { CUSTOM_FIELD_ENTITY_KEYS, OKR_BULK_UPDATE_ENTITY_KEYS } from '@/utils/entity-keys'
import { localSearch } from '@/utils/objectives'
import {
  BULK_FIELD_OPERATION_TYPE_IDS,
  createKeepAsIsOption,
  getMaxDropdownHeight,
  getOkrBulkFieldModelValueGetter,
  getOkrBulkFiledModelValueEmitPayload
} from '@/utils/okr-elements-table-bulk-actions'

import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'SingleSelectField',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  }
})

const { options } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const allOptions = computed(() => {
  return [
    {
      ...createKeepAsIsOption({
        [OKR_BULK_UPDATE_ENTITY_KEYS.VALUE_KEY]: CUSTOM_FIELD_ENTITY_KEYS.ID,
        [OKR_BULK_UPDATE_ENTITY_KEYS.LABEL_KEY]: CUSTOM_FIELD_ENTITY_KEYS.NAME
      })
    },
    ...options.value.map(option => {
      const { isNotSetOption } = option
      return {
        ...option,
        [OKR_BULK_UPDATE_ENTITY_KEYS.OPERATION]: isNotSetOption
          ? BULK_FIELD_OPERATION_TYPE_IDS.CLEAR
          : BULK_FIELD_OPERATION_TYPE_IDS.REPLACE
      }
    })
  ]
})

const emit = defineEmits({
  'update:model-value': null
})

const localModelValue = computed({
  get: () => {
    const { operation, value } = props.modelValue
    if (operation === BULK_FIELD_OPERATION_TYPE_IDS.CLEAR) {
      return value
    }
    return getOkrBulkFieldModelValueGetter({
      modelValue: props.modelValue
    })
  },
  set: newValue => {
    const payload = getOkrBulkFiledModelValueEmitPayload({
      newValue,
      options: allOptions.value,
      valueKey: CUSTOM_FIELD_ENTITY_KEYS.ID
    })

    emit('update:model-value', payload)
  }
})
</script>

<style lang="scss" scoped></style>
